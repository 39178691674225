import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../../assets/styles/base";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmationDialog";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BannersComponent from "./Banners";
import AddBanner from "./AddBanner";
import PromoteDialog from "../promoteDialog";

const userStyles = makeStyles((theme) => ({
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    float: "right",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },
  bannerImage: {
    minWidth: 300,
    minHeight: 300,
    maxHeight: 600,
    maxWidth: 600,
    objectFit: "cover",
    objectPosition: "center",
    [theme.breakpoints.down("xs")]: {
      minWidth: 250,
      minHeight: 250,
      maxHeight: 250,
      maxWidth: 250,
    },
  },
}));

export default ({
  campaignBanners,
  onBannerUpload,
  bannerUploadState,
  onCreateOrEditBanner,
  campaignId,
  onConfirmBannerAction,
  onPromote,
  bannersSections,
}) => {
  const user = JSON.parse(localStorage.getItem("au"));
  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };
  const classes = userStyles();

  const [banner, setBanner] = useState({
    file: null,
    path: null,
    url: null,
    desktop: true,
    static: true,
  });

  const [state, setState] = useState({
    promoteModel: false,
    selectedBanner: {},
    bannerModel: false,
    confirmModel: false,
    showSecondComponent: false,
    showBackForward: false,
  });

  const toggleModel = (model, item, action) => {
    setState({
      ...state,
      [model]: !state[model],
      selectedBanner: item,
      action,
      showSecondComponent: false,
      showBackForward: false,
    });

    if (action === "create") {
      setBanner({
        file: null,
        path: null,
        url: null,
        action,
      });
      setState({ ...state, showSecondComponent: true, showBackForward: true });
    }
    if (action === "edit") {
      setBanner({
        ...banner,
        ...item,
        url: item.url,
        caption: item.caption,
        link: item.link,
        action,
      });
      setState({ ...state, showSecondComponent: true, showBackForward: true });
    }
    if (action === "pause" || action === "delete") {
      setState({
        ...state,
        [model]: !state[model],
        selectedBanner: item,
        action,
        confirmMessage: `Confirm ${action} will make this banner ${
          action === "pause"
            ? "pause display on target platform, you can resume by promoting it again"
            : "disappear completely from the campaign"
        }`,
        confirmTitle: `Confirm ${action} banner`,
        confirmText: action,
      });
    }
    if (action === "promote") {
      setState({
        ...state,
        [model]: !state[model],
        selectedBanner: item,
        action,
        confirmMessage: `Confirm promote, this will make this banner display on target platform.`,
        confirmTitle: `Confirm ${action} banner`,
        confirmText: action,
      });
    }
  };

  const onPromoteCancel = () => {
    toggleModel("promoteModel");
  };

  const onPromoteConfirm = (data) => {
    onPromote({ ...data, ...state.selectedBanner }, "banner");
    toggleModel("promoteModel");
  };

  const onConfirmModelOk = () => {
    onConfirmBannerAction(state.selectedBanner, state.action);
    toggleModel("confirmModel");
  };

  const handleForwardArrow = () =>
    setState({
      ...state,
      showSecondComponent: true,
    });
  const handleBackArrow = () =>
    setState({
      ...state,
      showSecondComponent: false,
    });

  return (
    <div>
      <Grid container justify="space-between" direction="row">
        <div>
          {state.showBackForward && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleBackArrow}
                className={classes.menuButton}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleForwardArrow}
                className={classes.menuButton}
              >
                <ChevronRightIcon />
              </IconButton>
            </>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          onClick={() => toggleModel("bannerModel", {}, "create")}
        >
          Create banner
        </Button>
      </Grid>

      {state.showSecondComponent ? (
        <AddBanner
          toggleModel={toggleModel}
          onCreateOrEditBanner={onCreateOrEditBanner}
          onBannerUpload={onBannerUpload}
          setBanner={setBanner}
          bannerUploadState={bannerUploadState}
          state={state}
          banner={banner}
          bannersSections={bannersSections}
        />
      ) : (
        <BannersComponent
          campaignBanners={campaignBanners.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )}
          toggleModel={toggleModel}
          campaignId={campaignId}
          userInfo={userInfo}
        />
      )}
      <Box>
        <ConfirmDialog
          open={state.confirmModel}
          title={state.confirmTitle}
          message={state.confirmMessage}
          confirmText={state.confirmText}
          onCancel={() => toggleModel("confirmModel")}
          onConfirm={onConfirmModelOk}
        />
        <PromoteDialog
          title={"Select target platforms"}
          open={state.promoteModel}
          onPromote={onPromoteConfirm}
          onPromoteCancel={onPromoteCancel}
        />
      </Box>
    </div>
  );
};
