import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/isLoading";
import reduxAction from "../../../redux/action";
import { FETCH_SINGLE_AD } from "../../../redux/ads/types";
import moment from "moment";
import MediaCreativeComponent from "../../components/mediaCreative/MediaCreativeComponent";
import { Typography } from "@material-ui/core";
import { Markup } from "interweave";
import Tag from "../../../components/tag";
import Payment from "../payment/Payment";
import { formatDate } from "../../../helpers/helperMethods";

const mediaCreative = {
  type: "advertorial",
  id: "4Cn9YrIxQvfHTbQ6Nx6K",
  categoryName: "Kwamamaza",
  campaignId: "RwandaBestEvento1640293872219",
  clientId: "inyarwanda1623310420951",
  categoryId: 57,
  summary: "cool summary",
  photoUrl:
    "https://inyarwanda.com/app/webroot/img/202201/images/inyarda61f29a4b2afd6F3235928-BB1B-45DF-9C48-6CFBE7B13220.jpeg",
  content: "<p>this is cool content</p>",
  title: "test title",
  wallPhoto:
    "202201/images/inyarda61f29a4b2afd6F3235928-BB1B-45DF-9C48-6CFBE7B13220.jpeg",
  action: "createAdvertorial",
  createdAt: "2022-01-27T13:13:05.605Z",
  status: "pending",
};

export default ({ creativeId }) => {
  const singleAdvertStore = useSelector((state) => state.singleAdvert);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};
  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
    organization: user.claims.userOrg,
  };

  //   useEffect(() => {
  //     dispatch(
  //       reduxAction({
  //         method: "GET",
  //         path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/ads/${advertId}`,
  //         actionType: FETCH_SINGLE_AD,
  //       })
  //     );
  //   }, [state]);

  const mediaCreativeInformation = mediaCreative
    ? {
        ...mediaCreative,
        id: mediaCreative.id,
        campaignId: mediaCreative.campaignId,
        imgUrl: mediaCreative.url || mediaCreative.photoUrl,
        name: mediaCreative.title,
        status: mediaCreative.status,
        type: mediaCreative.type,
        adContent: (
          <>
            <Typography variant="subtitle1">
              <strong>Category</strong>: {mediaCreative.categoryName}
            </Typography>
            <br />
            <Typography variant="subtitle1">
              <strong>Created At</strong>: {formatDate(mediaCreative.createdAt)}
            </Typography>
            <br />
            <Typography variant="subtitle1">
              <strong>Summary</strong>: {mediaCreative.summary || "No Summary"}
            </Typography>
          </>
        ),
      }
    : {};

  return (
    <div>
      {false ? (
        <Loading />
      ) : (
        <div>
          <MediaCreativeComponent content={mediaCreativeInformation} />
          <br />
          {mediaCreative.type === "advertorial" && (
            <Markup content={mediaCreative.content} />
          )}
        </div>
      )}
    </div>
  );
};
