import React from "react";
import Loading from "../../../../components/isLoading";
import NoContent from "../../../../components/NoContent";
import {
  smallTableDataFormat,
  formatChartData,
} from "../../../../helpers/formatChartData";
import Table from "../../../../components/Table/Table";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import MultiRecordsChartComponent from "../../../components/stats/MultiRecordsChart";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../../assets/styles/components/chartStyle";

const useStyles = makeStyles(styles);

export default (props) => {
  const { data, isUpdating, width } = props;

  const classes = useStyles();

  const topBanners = data
    ? smallTableDataFormat(
        data,
        "top-banners",
        data.bannersSummary ? data.bannersSummary.length : 0
      )
    : [];

  return isUpdating ? (
    <Loading />
  ) : (
    <>
      {data ? (
        <Card>
          <CardBody>
            <div className={classes.headContainer}>
              <Typography className={classes.title}>
                Top Campaign Banners
              </Typography>
            </div>
            <br />
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={5}>
                <Table tableData={topBanners} />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <MultiRecordsChartComponent
                  data={formatChartData(data, `top-banners`)}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      ) : (
        <NoContent title={"No Content ."} />
      )}
    </>
  );
};
