/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
// react plugin for creating vector maps

import { VectorMap } from "react-jvectormap";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import styles from "../../../assets/styles/components/chartStyle";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import Loading from "../../../components/isLoading";
import { Typography } from "@material-ui/core";

const VectorMaps = (props) => {
  const {
    classes,
    title,
    dimensions,
    valueOfDimension,
    onDimensionChange,
    secondaryDimensions,
    onSecondaryDimensionChange,
    valueOfSecondaryDimension,
    isUpdating,
    mapData,
  } = props;

  const [state, setState] = useState({
    value: 0,
    mapData: mapData ? mapData : [],
  });

  useEffect(() => {
    setState({ ...state, mapData: mapData });
  }, [mapData]);

  const getData = (key) => {
    const countryData = [];
    state.mapData.forEach((obj) => {
      countryData[obj.code] = obj.value;
    });
    return countryData[key];
  };

  const getAllData = () => {
    const countryData = [];
    state.mapData.forEach(function (obj) {
      countryData[obj.code] = obj.value;
    });
    return countryData;
  };

  const handleshow2 = (e, el, code) => {
    const countryData = getData(code);
    el.html(el.html() + ` <br> Impressions: ${getData(code) || 0}`);
  };

  return (
    <div>
      <div className={classes.headContainer}>
        <Typography className={classes.title}> {title} </Typography>
        <span className={classes.dimensionSelector}>
          {dimensions && (
            <HeaderSelect
              hasSecondary
              onChangeMenu={(selected) => onDimensionChange(selected)}
              value={valueOfDimension}
              headerMenus={dimensions}
              onChangeSecondaryMenu={(selected) =>
                onSecondaryDimensionChange(selected)
              }
              secondaryValue={valueOfSecondaryDimension}
              secondaryHeaderMenus={secondaryDimensions}
            />
          )}
        </span>
      </div>
      <br />
      {isUpdating ? (
        <Loading />
      ) : (
        <GridContainer>
          <GridItem xs={12}>
            <Card plain>
              <CardBody plain>
                <VectorMap
                  map={"world_mill"}
                  backgroundColor="transparent"
                  zoomOnScroll={false}
                  containerStyle={{
                    width: "100%",
                    height: "420px",
                  }}
                  containerClassName="map"
                  regionStyle={{
                    initial: {
                      fill: "#e4e4e4",
                      "fill-opacity": 0.9,
                      stroke: "none",
                      "stroke-width": 0,
                      "stroke-opacity": 0,
                    },
                  }}
                  series={{
                    regions: [
                      {
                        values: getAllData(),
                        scale: ["#AAAAAA", "#444444"],
                        normalizeFunction: "polynomial",
                      },
                    ],
                  }}
                  onRegionTipShow={handleshow2}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};

export default withStyles(styles)(VectorMaps);
