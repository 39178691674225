import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import style from "../../../assets/styles/components/campaignFormbodyStyle";
import AddBasicsComponent from "./addBasicsComponent";
import AddBannerComponent from "./addBanner";
import AddAdvertComponent from "./addAdvertorial";
import CreateAdContainer from "../../containers/advertisement/createAd";
import { useWindowDimensions } from "../../../helpers/helperMethods";

const useStyles = makeStyles((theme) => style(theme));

export default ({
  campaign,
  onBasicInforChange,
  onBannerUpload,
  bannerUploadState,
  onCreateOrEditBanner,
  onAdvertPhotoUpload,
  advertUploadState,
  onCreateOrEditAdvert,
  onAdvertPauseOrDelete,
  onConfirmBannerAction,
  campaignId,
  onPromote,
  bannersSections,
}) => {
  const classes = useStyles();

  const [state, setState] = useState({
    title: "Basic Information",
    openMenu: false,
    isMobile: false,
    componentToDisplay: (
      <AddBasicsComponent
        campaign={campaign}
        onBasicInforChange={onBasicInforChange}
      />
    ),
    menuItems: [
      {
        text: "Basic Information",
      },
      {
        text: "Ads-Banners",
      },
      {
        text: "Advertorials",
      },
      {
        text: "Create Ad",
      },
    ],
  });

  const handleDrawerOpen = () => setState({ ...state, openMenu: true });

  const handleDrawerClose = () => setState({ ...state, openMenu: false });

  const handleMenuItemChange = (item) =>
    setState({
      ...state,
      title: item.text,
      openMenu: false,
    });

  const dimensions = useWindowDimensions();

  if (dimensions.width <= 500 && !state.isMobile) {
    setState({ ...state, isMobile: true });
  }

  if (dimensions.width > 500 && state.isMobile) {
    setState({ ...state, isMobile: false });
  }

  return (
    <div>
      <br />
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar>
            <Grid container>
              {state.isMobile && (
                <>
                  {state.openMenu ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerClose}
                      edge="start"
                      className={clsx(classes.menuButton)}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton)}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </>
              )}
              <Typography variant="h6" noWrap className={classes.title}>
                {campaign.name}: {state.title}
              </Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={state.isMobile ? state.openMenu : true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader} />
          <List>
            {state.menuItems.map((elt) => (
              <div key={elt.text}>
                <ListItem className={classes.menuItem}>
                  <ListItemText
                    primary={elt.text}
                    onClick={() => handleMenuItemChange(elt)}
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Drawer>

        <main
          className={state.isMobile ? classes.mobileContent : classes.content}
        >
          <div className={classes.drawerHeader} />
          {state.title === "Basic Information" && (
            <AddBasicsComponent
              campaign={campaign}
              onBasicInforChange={onBasicInforChange}
            />
          )}
          {state.title === "Ads-Banners" && (
            <AddBannerComponent
              campaignBanners={campaign.adBanners || []}
              onBannerUpload={onBannerUpload}
              bannerUploadState={bannerUploadState}
              onCreateOrEditBanner={onCreateOrEditBanner}
              campaignId={campaignId}
              onConfirmBannerAction={onConfirmBannerAction}
              onPromote={onPromote}
              bannersSections={bannersSections}
            />
          )}
          {state.title === "Advertorials" && (
            <AddAdvertComponent
              campaignAdverts={campaign.advertorials}
              onAdvertPhotoUpload={onAdvertPhotoUpload}
              advertUploadState={advertUploadState}
              onCreateOrEditAdvert={onCreateOrEditAdvert}
              campaignId={campaignId}
              onAdvertPauseOrDelete={onAdvertPauseOrDelete}
              onPromote={onPromote}
            />
          )}
          {state.title === "Create Ad" && <CreateAdContainer initialStep={2} />}
        </main>
      </div>
    </div>
  );
};
