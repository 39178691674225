import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import CreateEventContainer from "../../containers/events/CreateEventContainer";

export class CreateCampaign extends Component {
  render() {
    return (
      <AppLayout>
        <CreateEventContainer />
      </AppLayout>
    );
  }
}

export default CreateCampaign;
