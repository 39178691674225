import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default ({ defaultValue, onChange }) => {
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(defaultValue)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date.toISOString());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={4}>
        <Grid item>
          <KeyboardDatePicker
            variant="inline"
            format="yyy-MM-dd"
            label="Date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid item>
          <KeyboardTimePicker
            label="Time"
            variant="inline"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
