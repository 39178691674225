import React, { Component, useState, useEffect } from "react";
import RecordsComponent from "../../../../components/dashboard/RecordsComponent";

const makeSum = (dataArr, prop) => {
  let sum = 0;
  dataArr.forEach((el) => {
    sum += el[prop];
  });
  return sum;
};

export default ({ isUpdating, role, recordsToDisplay, eventCategory }) => {
  const [recordEventData, setRecordEventData] = useState({
    totalTickets:0,
    totalSoldTickets:0,
    totalRemainingTickets:0,
    RWFRecord: {
      revenue: 0,
      tickets: 0,
    },
    USDRecord: {
      revenue: 0,
      tickets: 0,
    },
    EURRecord: {
      revenue: 0,
      tickets: 0,
    },
    USSDRecord: {
      revenue: 0,
      tickets: 0,
    },
  })
  const remaining = recordsToDisplay && recordsToDisplay.totalSeats - recordsToDisplay.totalTicketsSold;

  useEffect(() => {
    if (recordsToDisplay && recordsToDisplay.totalSeats) {
      const newState = {
        ...recordEventData,
        totalTickets: recordsToDisplay.totalSeats,
        totalSoldTickets: recordsToDisplay.totalTicketsSold,
        totalRemainingTickets: remaining
      };
      const RWFBucket =
        recordsToDisplay.currencyBuckets.filter(
          (elt) => elt.currency === "RWF"
        );
      if (RWFBucket.length > 0) {
        newState.RWFRecord = {
          revenue: makeSum(RWFBucket, "totalTicketSales"),
          tickets: makeSum(RWFBucket, "totalTicketsSold"),
        };
      }
      const USDBucket =
        recordsToDisplay.currencyBuckets.filter(
          (elt) => elt.currency === "USD"
        );
      if (USDBucket.length > 0) {
        newState.USDRecord = {
          revenue: makeSum(USDBucket, "totalTicketSales"),
          tickets: makeSum(USDBucket, "totalTicketsSold"),
        };
      }
      const EURBucket =
        recordsToDisplay.currencyBuckets.filter(
          (elt) => elt.currency === "EUR"
        );
      if (EURBucket.length > 0) {
        newState.EURRecord = {
          revenue: makeSum(EURBucket, "totalTicketSales"),
          tickets: makeSum(EURBucket, "totalTicketsSold"),
        };
      }
      // const USSDBucket =
      //   recordsToDisplay.LatestOverallVotes?.BucketSummary.filter(
      //     (elt) => elt.BucketName === "USSD_RWF"
      //   );
      // if (USSDBucket.length > 0) {
      //   newState.USSDRecord = {
      //     revenue: makeSum(USSDBucket, "Revenue"),
      //     votes: makeSum(USSDBucket, "Votes"),
      //   };
      // }

      setRecordEventData(newState);
    }
  }, [recordsToDisplay]);
  const records = [
    {
      title: "Total Tickets",
      color: "warning",
      iconName: "how_to_vote",
      value: recordEventData.totalTickets,
      sold:recordEventData.totalSoldTickets,
      remaining:recordEventData.totalRemainingTickets
    },
    {
      title: "Revenue (RWF)",
      color: "warning",
      iconName: "payments",
      value: recordEventData.RWFRecord.revenue,
      votes: recordEventData.RWFRecord.tickets,
    },
    {
      title: "Revenue (USD)",
      color: "warning",
      iconName: "attach_money",
      value: recordEventData.USDRecord.revenue,
      votes: recordEventData.USDRecord.tickets,
    },
    {
      title: "Revenue (EUR)",
      color: "warning",
      iconName: "euro_symbol",
      value: recordEventData.EURRecord.revenue,
      votes: recordEventData.EURRecord.tickets ,
    },
    {
      title: "Revenue (USSD)",
      color: "warning",
      iconName: "dialpad",
      value: recordEventData.USSDRecord.revenue,
      votes: recordEventData.USSDRecord.tickets,
    },
  ];
  return (
    <>
      <RecordsComponent eventCategory={eventCategory} role={role} TierTickets={recordsToDisplay.tierSummary} records={records} isUpdating={isUpdating} />
    </>
  );
};
