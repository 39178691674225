import React from "react";
import { createData } from "../../../../helpers/helperMethods";
import DataTableComponent from "../../../components/data/DataTableComponent";

export default (props) => {
  const { isUpdating, data, notActive, noContentCustomElements } = props;

  const headCells = [
    {
      id: "name",
      rightAlign: false,
      label: "Name",
    },
    {
      id: "launch-date",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Launch date",
    },
    {
      id: "total-impressions",
      rightAlign: true,
      label: "Total Impressions",
    },
    {
      id: "reach",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Reach",
    },
    {
      id: "engagement",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Engagement",
    },
    {
      id: "goals",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Goals",
    },
  ];

  const rows = data
    ? data.campaigns.map((elt) =>
        createData(
          elt.id, //id
          elt.name, //name
          elt.launchDate || "Not Yet", //launchDate
          elt.totalImpressions || 0, //impressions
          elt.totalReaches || 0, //total banners
          elt.totalEngagements || 0, //nbr of advertorials
          elt.goals ? elt.goals[0].type : "Unset" //goals
        )
      )
    : [];

  return (
    <DataTableComponent
      isClickable
      notActive={notActive}
      hasLongTitles
      isUpdating={isUpdating}
      rows={rows}
      headCells={headCells}
      title="Campaigns"
      noContentCustomElements={noContentCustomElements}
    />
  );
};
