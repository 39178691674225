import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import priceListStyle from "../../../../assets/styles/components/priceListStyle";
import PriceCard from "./PriceCard";
import CustomButton from "../../../../components/Buttons/customButton";
import { Slider, TextField, Typography } from "@material-ui/core";
import { dangerColor } from "../../../../assets/styles/base";

const styles = makeStyles((theme) => priceListStyle(theme));

export default ({ content, choosePaymentPlan }) => {
  const classes = styles();

  const [state, setState] = useState({
    selectedPlan: {},
    customPlan: {
      price: 100,
      amount: 0,
      planType: "Custom Plan",
      features: "Custom Plan Features",
    },
  });

  const handleSliderChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      selectedPlan: {},
      customPlan: {
        price: newValue * 10,
        amount: newValue,
        planType: "Custom Plan",
        features: "Custom Plan Features",
      },
    }));
  };

  const handleInputChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      selectedPlan: {},
      customPlan: {
        price: event.target.value === "" ? "" : Number(event.target.value),
        amount:
          event.target.value === "" ? "" : Number(event.target.value) / 10,
        planType: "Custom Plan",
        features: "Custom Plan Features",
      },
    }));
  };

  const handleOnPlanSelect = (data) =>
    setState({ ...state, selectedPlan: data });

  const handleSubmitPlan = () => {
    choosePaymentPlan(
      state.selectedPlan.price ? state.selectedPlan : state.customPlan
    );
  };

  return (
    <div className={classes.payment}>
      <div>
        {content.map((plan) => (
          <div key={plan.price}>
            <PriceCard
              plan={plan}
              onPlanChoose={handleOnPlanSelect}
              selectedPlan={state.selectedPlan}
            />
          </div>
        ))}
      </div>

      <Typography variant="h5" className={classes.customAmountTitle}>
        Or Choose Custom Plan
      </Typography>
      <div className={classes.customAmount}>
        <div className={classes.customAmountInput}>
          <Typography className={classes.currency}>Rwf:</Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={state.customPlan.price}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <Typography
            variant="subtitle2"
            style={{
              color: state.customPlan.price < 100 ? dangerColor[0] : "inherit",
              margin: "10px 0",
            }}
          >
            Please make sure The Amount entered is not under 100
          </Typography>

          <Slider
            value={
              typeof state.customPlan.amount === "number"
                ? state.customPlan.amount
                : 0
            }
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            className={classes.slider}
          />
        </div>
      </div>

      <div className={classes.action}>
        <CustomButton color="primary" onClick={handleSubmitPlan}>
          Submit Chosen Plan
        </CustomButton>
      </div>
    </div>
  );
};
