import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import reduxAction from "../../../../redux/action";
// core-components
import Toast from "../../../../components/MessageToast";
import Step1 from "../../../components/ads/createAd/steps/Step1";
import Step2 from "./Step2";
import { FETCH_ALL_CAMPAIGNS_INFO } from "../../../../redux/campaign/types";
import Loading from "../../../../components/isLoading";
import Step3 from "./Step3";
import apiService from "../../../../services/apiService";
import { Card, Container } from "@material-ui/core";

export default ({ initialStep }) => {
  const user = JSON.parse(localStorage.getItem("au"));
  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const allCampaignsStore = useSelector((store) => store.allCampaigns);

  const [state, setState] = useState({
    selectedCampaign: {},
    addContent: {},
    loading: false,
    currentStep: initialStep ? initialStep : 1,
  });

  useEffect(() => {
    if (!allCampaignsStore.data.results) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${userInfo.organization.orgId}/campaigns`,
          actionType: FETCH_ALL_CAMPAIGNS_INFO,
        })
      );
    }
  }, [state.selectedCampaign]);

  const handleSave = async (data) => {
    setState({
      ...state,
      selectedCampaign: data,
      currentStep: 2,
    });
  };

  const goToNextStep = (data) =>
    setState((prevState) => ({
      ...state,
      currentStep: prevState.currentStep + 1,
      addContent: data,
    }));

  if (state.openToast) {
    Toast({
      message: "Ad Created",
      type: "success",
      duration: 4000,
      onClose: () => {
        setState({
          ...state,
          loading: false,
          openToast: false,
        });
        history.push("/");
      },
    });
  }

  return allCampaignsStore.loading ? (
    <Loading />
  ) : state.currentStep === 3 ? (
    <Step3 advertData={state.addContent} />
  ) : (
    <Container justify="center" maxWidth="md">
      <Card
        style={{
          padding: "5%",
        }}
      >
        <div>
          {state.currentStep === 1 && (
            <Step1
              handleSave={handleSave}
              loading={state.loading}
              campaigns={allCampaignsStore.data.results?.campaigns || []}
            />
          )}
          {state.currentStep === 2 && (
            <Step2
              selectedCampaign={state.selectedCampaign}
              userInfo={userInfo}
              goToNextStep={goToNextStep}
            />
          )}
        </div>
      </Card>
    </Container>
  );
};
