import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import ListItemMenu from "../../../components/Menu/ListItemMenu";
import { grayColor } from "../../../assets/styles/base";
import { useWindowDimensions } from "../../../helpers/helperMethods";
import Image from "../../../components/Image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  items: {
    display: "flex",
    flexDirection: "row",
  },
  item: {
    padding: "10px",
    "&:hover": {
      backgroundColor: grayColor[5],
      cursor: "pointer",
      opacity: 0.98,
      borderRadius: "6px",
    },
  },
  listTextContent: {
    cursor: "pointer",
    width: "80%",
    marginLeft: "3%",
    padding: "0px 10px",

    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      marginLeft: "2%",
    },
  },
  itemImg: {
    borderRadius: "6px",
    width: "200px",
    height: "150px",
    objectFit: "cover",

    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "70px",
    },
  },
  smallImg: {
    width: "110px",
    height: "70px",
    borderRadius: "3px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "60px",
    },
  },
  title: {
    fontWeight: "500",
  },
  itemTitle: {
    textAlign: "center",
  },
  icon: {
    color: "#656565",
    padding: 10,
  },
}));

export default ({ title, content, onClick, isSmall }) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const onContentClick = (data) => onClick(data);
  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant={width < 600 || isSmall ? "h5" : "h4"}
      >
        {title}
      </Typography>
      <br />
      <div className={classes.items}>
        {content.map((elt) => (
          <div
            onClick={() => onContentClick(elt)}
            className={classes.item}
            key={elt.name}
          >
            <div className={classes.icon}>
              <div>
                {elt.icon}
                <br />
                <Typography variant="h6" className={classes.itemTitle}>
                  {elt.name}
                </Typography>
              </div>
            </div>
            <div className={classes.listTextContent}></div>
          </div>
        ))}
      </div>
    </div>
  );
};
