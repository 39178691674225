import React, {
    Component
} from 'react';
import AppLayout from '../../../layouts/AppLayout';
import EditOrganizationContainer from '../../containers/profile/EditOrganizationContainer';

export class EditOrganizationPage extends Component {
    render() {
        
        return (
                <AppLayout>
                    <EditOrganizationContainer organizationId = {this.props.match.params.id} />
                </AppLayout> 
        );
    }
}

export default EditOrganizationPage;
