import React, {
    Component
} from 'react';
import AppLayout from '../../../layouts/AppLayout';
import Profile from '../../containers/profile/ProfileContainer';

export class ProfilePage extends Component {
    render() {
        return(
            <AppLayout>
              <Profile />
            </AppLayout>
          )
    }
}

export default ProfilePage;
