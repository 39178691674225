import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/isLoading";
import RecordsComponent from "../../../components/dashboard/RecordsComponent";
import TimePicker from "../../../components/date/TimePickerComponent";
import moment from "moment";
import reduxAction from "../../../../redux/action";
import {
  FETCH_ALL_EVENTS,
  FETCH_PENDING_EVENTS,
} from "../../../../redux/events/types";
import { useHistory } from "react-router";
import DataContainer from "./dataContainer";
import { CreateButton, handleDates } from "../../../../helpers/helperMethods";
import apiService from "../../../../services/apiService";
import Toast from "../../../../components/MessageToast";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isPending = history.location.pathname.includes("pending");

  const eventsStore = useSelector((store) =>
    isPending ? store.pendingEvents : store.allEvents
  );

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const user = JSON.parse(localStorage.getItem("au")) || {};

  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };

  const { startDate, endDate } = handleDates(state);

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  useEffect(() => {
    if (!eventsStore.data.results) {
      dispatch(
        reduxAction({
          method: "GET",
          path: isPending ? `/events?status=pending` : `/events`,
          actionType: isPending ? FETCH_PENDING_EVENTS : FETCH_ALL_EVENTS,
        })
      );
    }
  }, [state, isPending]);

  const recordsToDisplay = [
    {
      title: "Events",
      color: "warning",
      iconName: "today",
      value: eventsStore.data.results?.length || 0,
    },
  ];

  const handleClickCreateEvent = () => history.push("/events/new");

  const onDeleteEvent = async (eventId) => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "DELETE",
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events?status=pending`,
            actionType: FETCH_PENDING_EVENTS,
          })
        );
      },
    });
  };

  const onLaunchEvent = async (eventId) => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: {
        status: "published",
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        history.push("/events");
      },
    });
  };

  const onActivateOrStopEvent = async (eventId, isActive) => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: {
        isActive: !isActive,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        history.push("/events");
      },
    });
  };

  return (
    <div>
      {(eventsStore.loading && !eventsStore.data.results) ||
      (isPending && eventsStore.loading && eventsStore.data.results) ? (
        <Loading />
      ) : (
        <>
          <TimePicker
            startDate={startDate}
            endDate={endDate}
            onRangeChange={onRangeChange}
            onDateChange={onDateChange}
            lastSyncTime={
              moment(
                eventsStore.data.results &&
                  eventsStore.data.results.lastSyncTime
              ).format("LLL") || "00:00 AM"
            }
          />
          <CreateButton
            text="Create new Events"
            handleOnClick={handleClickCreateEvent}
            styles={{ float: "right", marginBottom:"15px" }}
          />

          {!isPending && (
            <RecordsComponent
              isUpdating={eventsStore.loading && eventsStore.data.results}
              records={recordsToDisplay}
            />
          )}
          <DataContainer
            isUpdating={eventsStore.loading && eventsStore.data.results}
            data={eventsStore.data.results}
            notActive={isPending}
            generalUrl="/events"
            onLaunchEvent={onLaunchEvent}
            onDeleteEvent={onDeleteEvent}
            onActivateOrStopEvent={onActivateOrStopEvent}
          />
        </>
      )}
    </div>
  );
};
