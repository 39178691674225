import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../assets/styles/base';
import CustomDialog from '../../../components/Dialogs/CustomDialog';

const userStyles = makeStyles((theme) => ({
  checkBox: {
    '&.Mui-checked': {
      color: primaryColor[0],
    },
  },
}));

export default ({ open, title, onPromote, onPromoteCancel }) => {
  const classes = userStyles();
  const [state, setState] = useState({ platforms: [] });
  const onPlatformChange = (e) => {
    const {
      target: { value },
    } = e;
    setState({
      ...state,
      platforms: typeof value === 'string' ? value.split(',') : value,
    });
  };
  const onConfirm = () => {
    onPromote({ platforms: state.platforms });
  };

  return (
    <div>
      <CustomDialog
        title={title}
        okText='Promote'
        open={open}
        onCancel={onPromoteCancel}
        onConfirm={onConfirm}
        fullWidth
        maxWidth='sm'
        content={
          <Grid container direction='column'>
            <FormControl variant='standard' fullWidth>
              <InputLabel id='Platforms'>Target Platform</InputLabel>
              <Select
                labelId='Platforms'
                id='platform'
                onChange={onPlatformChange}
                label='Target Platform'
                multiple
                value={state.platforms}
                renderValue={(selected) => selected.join(', ')}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value='Website'>
                  <Checkbox
                    className={classes.checkBox}
                    checked={state.platforms.indexOf('Website') > -1}
                  />
                  <ListItemText primary={'Website'} />
                </MenuItem>
                <MenuItem value='Facebook'>
                  <Checkbox
                    className={classes.checkBox}
                    checked={state.platforms.indexOf('Facebook') > -1}
                  />
                  <ListItemText primary={'Facebook'} />
                </MenuItem>
                <MenuItem value='Instagram'>
                  <Checkbox
                    className={classes.checkBox}
                    checked={state.platforms.indexOf('Instagram') > -1}
                  />
                  <ListItemText primary={'Instagram'} />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        }
      />
    </div>
  );
};
