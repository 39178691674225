import reducers from './reducers';
import clientsAction from './actions';

const clientsReducers = {
  allClients: reducers.getAllClients,
  clientInfo: reducers.getClientInfo,
  clientPendingsInvites: reducers.getClientPendingInvite,
  publishers: reducers.getPublishers,
};

export { clientsAction, clientsReducers };
