import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import CustomInput from "../../../../components/CustomInput/TextField";
import CustomSelect from "../../../../components/CustomSelect/StandardSelect";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import { capitalizeFirstLetter } from "../../../../helpers/helperMethods";
import Button from "../../../../components/Buttons/customButton";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vh 5%",
    backgroundColor: "#fff",
  },
}));

export default ({
  toggleModel,
  onCreateOrEditBanner,
  onBannerUpload,
  setBanner,
  banner,
  hideSubmitBtn,
  title,
  disabled,
  bannersSections,
}) => {
  const classes = useStyles();

  const uploadCampaignImgStore = useSelector(
    (store) => store.uploadCampaignImg
  );

  const onInputChange = (e) => {
    setBanner({
      ...banner,
      [e.target.name]: e.target.value,
    });
  };

  const onUploadImage = (data) => {
    onBannerUpload(data);
  };

  const onBannerConfirm = () => {
    onCreateOrEditBanner(banner.action, banner, uploadCampaignImgStore.data);
    toggleModel("bannerModel");
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Typography variant="h6">
        {title
          ? title
          : banner.action === "create"
          ? "Create a Banner for the campaign"
          : `${capitalizeFirstLetter(banner.action)} Banner`}
      </Typography>

      <br />
      <Box>
        <Grid container direction="column" style={{ padding: "0 25px" }}>
          <Grid item md={6}>
            <CustomInput
              label="Caption"
              multiline
              value={banner.caption || ""}
              id="bannerCaption"
              name="caption"
              fullWidth
              onChange={onInputChange}
              helperText="Text associated with the banner"
              disabled={disabled}
            />
          </Grid>
          <Grid item md={6}>
            <CustomInput
              label="External link"
              value={banner.externalLink || ""}
              id="externalLink"
              name="externalLink"
              fullWidth
              onChange={onInputChange}
              helperText="The user is redirected to this LINK upon clicking on the banner"
              disabled={disabled}
            />
          </Grid>
          <br />
          <Grid item md={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Target Size</FormLabel>
              <RadioGroup
                value={banner.mobile ? "mobile" : "desktop"}
                onChange={(e) => {
                  setBanner({
                    ...banner,
                    desktop: e.target.value === "desktop",
                    mobile: e.target.value === "mobile",
                  });
                }}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="desktop"
                  control={<Radio />}
                  label="Desktop"
                />
                <FormControlLabel
                  value="mobile"
                  control={<Radio />}
                  label="Mobile"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <br />
          <Grid item md={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Static/Dynamic</FormLabel>
              <RadioGroup
                value={banner.dynamic ? "dynamic" : "static"}
                onChange={(e) => {
                  setBanner({
                    ...banner,
                    static: e.target.value === "static",
                    dynamic: e.target.value === "dynamic",
                  });
                }}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="static"
                  control={<Radio />}
                  label="Static"
                />
                <FormControlLabel
                  value="dynamic"
                  control={<Radio />}
                  label="dynamic"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <br />

          <Grid item md={6}>
            <CustomSelect
              label="Target Page"
              error=""
              value={banner.page}
              menuArr={bannersSections.map((el) => ({
                value: el.id,
                name: el.label || el.id,
              }))}
              onChange={(e) => {
                setBanner({
                  ...banner,
                  page: e.target.value,
                });
              }}
            />
          </Grid>
          <br />
          <Grid item md={6}>
            <CustomSelect
              label="Target Section"
              error=""
              value={banner.section}
              menuArr={
                banner.page
                  ? banner.dynamic
                    ? bannersSections
                        .find((item) => item.id === banner.page)
                        .dynamicSections.map((s) => {
                          const label = `${s.label} (${
                            banner.mobile
                              ? `${s.mobile.width}x${s.mobile.height}`
                              : `${s.desktop.width}x${s.mobile.desktop.height}`
                          })`;
                          return {
                            value: s.section,
                            name: label,
                          };
                        })
                    : bannersSections
                        .find((item) => item.id === banner.page)
                        .staticSections.map((s) => {
                          const label = `${s.label} (${
                            banner.mobile
                              ? `${s.mobile.width}x${s.mobile.height}`
                              : `${s.desktop.width}x${s.mobile.desktop.height}`
                          })`;
                          return {
                            value: s.section,
                            name: label,
                          };
                        })
                  : []
              }
              onChange={(e) => {
                setBanner({
                  ...banner,
                  section: e.target.value,
                });
              }}
            />
          </Grid>

          <br />
          {banner.page && banner.section && (
            <>
              <ImageUpload
                hasMoreInfo
                defaultImage={banner.url || ""}
                imageSubmit={onUploadImage}
                imageTypes={
                  "image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico"
                }
                addButtonProps={{
                  color: "primary",
                }}
                changeButtonProps={{
                  color: "primary",
                }}
                removeButtonProps={{
                  color: "danger",
                }}
                isUploading={uploadCampaignImgStore.loading}
                disabled={disabled}
                dimension={{
                  width: 350,
                  height: 130,
                }}
              />
              <Typography color="textSecondary">
                {banner.imageError ? banner.imageError : ""}
              </Typography>
            </>
          )}
          <br />
          {!hideSubmitBtn && (
            <Grid item md={6} container justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                className={classes.createButton}
                onClick={onBannerConfirm}
              >
                Submit
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};
