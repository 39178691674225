import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import AdvertisementsContainer from "../../containers/advertisement/allAdverts";
import AdvertisementContainer from "../../containers/advertisement/advert";

export class Advertisements extends Component {
  render() {
    const advertId = this.props.match.params.advertId;
    const campaignId = this.props.match.params.campaignId;

    return advertId ? (
      <AppLayout hasBack>
        <AdvertisementContainer advertId={advertId} campaignId={campaignId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <AdvertisementsContainer />
      </AppLayout>
    );
  }
}

export default Advertisements;
