import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ProfileComponent from '../../components/profile/ProfileComponent';

const userString = localStorage.getItem('au');
const user = JSON.parse(userString) || {};

export class SingleProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { confirmModel:false };
  }

  openConfirmModel = (props) => {
    this.setState({ confirmModel: true, ...props });
  };

  onConfirm = async () => {
    this.setState({ confirmLoading: true });
    this.props.history.push(`/organization/edit/${user.claims.userOrg.orgId}`);
  };

  onConfirmModelCancel = () => {
    this.setState({
      confirmModel: false,
      confirmMessage: '',
      confirmTitle: '',
      danger: false,
      id: null,
      action: null,
      confirmLoading: false,
    });
  };

  onEdit = () => {
    this.setState({
      confirmModel: true,
      confirmTitle: 'Edit Confirmation',
      confirmText: 'Edit',
      danger: false,
      confirmMessage: `Do you wish to edit ${user.claims.userOrg.orgName||'organization name'}`,
      action: 'edit',
    });
};

  render() {
    
    const userData = {
      name: user.displayName,
      email: user.email,
      userOrganization: {
        id: user.claims.userOrg.orgId, 
        name:user.claims.userOrg.orgName||'organization name', 
        image: user.claims.userOrg.orgPhoto || user.photo,
      }
    }

    const options = {
      actions: {
        onEdit: this.onEdit,
      },
      confirmModelOptions: {
        open: this.state.confirmModel,
        title: this.state.confirmTitle,
        confirmText: this.state.confirmText,
        message: this.state.confirmMessage,
        danger: this.state.danger,
        loading: this.state.apiCall,
        onCancel: this.onCancel,
        onConfirm: this.onConfirm,
      },
    };



    return (
      <ProfileComponent
        content={ userData }
        options={options}
        isLoading={false}
      />
    );
  }
}

export default (withRouter(SingleProfileComponent));
