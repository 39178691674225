import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
// core-components
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CustomButton from "../../../../../components/Buttons/customButton";
import { useHistory } from "react-router";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { grayColor, primaryColor } from "../../../../../assets/styles/base";

const defaultCampElt = {
  padding: "16px 24px",
  border: "1px solid #dadce0",
  margin: "16px 0",
  borderRadius: "8px",
  fontSize: "18px",
  letterSpacing: ".2px",
  color: "#3c4043",
  cursor: "pointer",
};
const useStyles = makeStyles((theme) => ({
  content: {},
  campaignElt: {
    ...defaultCampElt,
    "&:hover": {
      border: `1px solid ${primaryColor[0]}`,
      color: primaryColor[0],
    },
  },
  selectedCampaignElt: {
    ...defaultCampElt,
    border: `2px solid ${primaryColor[0]}`,
    color: primaryColor[0],
  },
  text: {
    color: grayColor[1],
    fontSize: 14,
  },
}));

export default ({ handleSave, loading, campaigns }) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    selectedCampaign: {},
    error: "",
  });

  const handleSelectCampaign = (data) => {
    setState({
      ...state,
      selectedCampaign: data || {},
      error: "",
    });
  };

  const onSave = () => {
    if (!state.selectedCampaign.id) {
      return setState({ ...state, error: "Please Choose a Campaign" });
    }
    handleSave(state.selectedCampaign);
  };

  const selectData = campaigns.map((elt) => ({
    value: elt.name,
    name: elt.name,
  }));
  const handleClickCreateCampaign = () => history.push("/campaigns/new");

  return (
    <div>
      <CardHeader title="Choose Campaign"></CardHeader>
      <CardContent>
        {selectData.length > 0 ? (
          <div>
            {campaigns.map((campaignElt) => (
              <div
                key={campaignElt.id}
                className={
                  campaignElt.id === state.selectedCampaign.id
                    ? classes.selectedCampaignElt
                    : classes.campaignElt
                }
                onClick={() => handleSelectCampaign(campaignElt)}
              >
                <span>{campaignElt.name}</span>
                <br />
                <span className={classes.text}>
                  Launched: {campaignElt.launchDate || "- Unknown -"}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <Typography variant="subtitle1" align="center">
              It seems you do not have a campaign Please First create a campaign
            </Typography>
          </div>
        )}
      </CardContent>

      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        {selectData.length > 0 ? (
          <CustomButton color="primary" onClick={onSave}>
            {loading ? (
              <PulseLoader
                size={5}
                margin={3}
                color={"#fff"}
                loading={loading}
              />
            ) : (
              "Save and continue"
            )}
          </CustomButton>
        ) : (
          <CustomButton color="primary" onClick={handleClickCreateCampaign}>
            Create new Campaign
          </CustomButton>
        )}
      </CardActions>
    </div>
  );
};
