import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import CustomInput from "../../../components/CustomInput/TextField";
import { primaryColor } from "../../../assets/styles/base";
import "../../../assets/styles/plugins/calendar.css";

const userStyles = makeStyles((theme) => ({
  card: {
    padding: "3vh 5%",
  },
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },

  checkBox: {
    "&.Mui-checked": {
      color: primaryColor[0],
    },
  },
}));

export default ({ campaign, onBasicInforChange }) => {
  const classes = userStyles();
  const [campaignState, setCampaignState] = useState({
    campaignInfo: campaign || {},
  });
  useEffect(() => {
    setCampaignState({
      campaignInfo: campaign,
    });
  }, [campaign]);

  const handleDateRangeClick = (date) => {
    setCampaignState((prevState) => ({
      ...campaignState,
      [date + "Open"]: !prevState[date + "Open"],
    }));
  };

  const handleDateSelection = (selectedDate, date) => {
    const displayDate = new Date(
      `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`
    )
      .toISOString()
      .substring(0, 10);
    setCampaignState({
      campaignInfo: {
        ...campaignState.campaignInfo,
        [date]: displayDate,
      },
      [date]: selectedDate,
      changed: true,
      [date + "Open"]: false,
    });
  };

  const onCategoryChange = (e) => {
    setCampaignState({
      campaignInfo: { ...campaignState.campaignInfo, category: e.target.value },
      changed: true,
    });
  };
  const onPlatformChange = (e) => {
    const {
      target: { value },
    } = e;
    setCampaignState({
      campaignInfo: {
        ...campaignState.campaignInfo,
        targetPlatforms: typeof value === "string" ? value.split(",") : value,
      },
      changed: true,
    });
  };
  const onObjectivesChange = (e) => {
    const {
      target: { value },
    } = e;
    setCampaignState({
      campaignInfo: {
        ...campaignState.campaignInfo,
        objectives: typeof value === "string" ? value.split(",") : value,
      },
      changed: true,
    });
  };

  const onNameAndSpendChange = (e) => {
    setCampaignState({
      campaignInfo: {
        ...campaignState.campaignInfo,
        [e.target.name]: e.target.value,
      },
      changed: true,
    });
  };

  const onSaveChanges = () => {
    onBasicInforChange(campaignState.campaignInfo);
  };

  return (
    <Card className={classes.card}>
      <Typography variant="h6" align="center">
        Basic Information
      </Typography>
      <List>
        <ListItem>
          <CustomInput
            label="Campaign Name"
            value={campaignState.campaignInfo.name || ""}
            id="campaignName"
            name="name"
            disabled={campaign.status === "active"}
            fullWidth
            onChange={onNameAndSpendChange}
          />
        </ListItem>
        <ListItem>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="Category">Campaign Category</InputLabel>
            <Select
              labelId="Category"
              id="category"
              value={campaignState.campaignInfo.category || ""}
              onChange={onCategoryChange}
              label="Camapaign Category"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem
                value="Business"
                checked={
                  campaignState.campaignInfo.category &&
                  campaignState.campaignInfo.category === "Business"
                }
              >
                Business
              </MenuItem>
              <MenuItem
                value="Entertainment"
                checked={
                  campaignState.campaignInfo.category &&
                  campaignState.campaignInfo.category === "Entertainment"
                }
              >
                Entertainment
              </MenuItem>
              <MenuItem
                value="Sport"
                checked={
                  campaignState.campaignInfo.category &&
                  campaignState.campaignInfo.category === "Sport"
                }
              >
                Sport
              </MenuItem>
              <MenuItem
                value="Social"
                checked={
                  campaignState.campaignInfo.category &&
                  campaignState.campaignInfo.category === "Social"
                }
              >
                Social
              </MenuItem>
              <MenuItem
                value="Political"
                checked={
                  campaignState.campaignInfo.category &&
                  campaignState.campaignInfo.category === "Political"
                }
              >
                Political
              </MenuItem>
            </Select>
          </FormControl>
        </ListItem>

        <ListItem>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="Platforms">Target Platform</InputLabel>
            <Select
              labelId="Platforms"
              id="platform"
              onChange={onPlatformChange}
              label="Target Platform"
              multiple
              value={campaignState.campaignInfo.targetPlatforms || []}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Website">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.targetPlatforms &&
                    campaignState.campaignInfo.targetPlatforms.indexOf(
                      "Website"
                    ) > -1
                  }
                />
                <ListItemText primary={"Website"} />
              </MenuItem>
              <MenuItem value="Facebook">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.targetPlatforms &&
                    campaignState.campaignInfo.targetPlatforms.indexOf(
                      "Facebook"
                    ) > -1
                  }
                />
                <ListItemText primary={"Facebook"} />
              </MenuItem>
              <MenuItem value="Instagram">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.targetPlatforms &&
                    campaignState.campaignInfo.targetPlatforms.indexOf(
                      "Instagram"
                    ) > -1
                  }
                />
                <ListItemText primary={"Instagram"} />
              </MenuItem>
            </Select>
          </FormControl>
        </ListItem>

        <ListItem>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="targets">Target Objectives</InputLabel>
            <Select
              labelId="targets"
              id="targets"
              onChange={onObjectivesChange}
              label="Target Objectives"
              multiple
              value={campaignState.campaignInfo.objectives || []}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Brand Awareness">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "Brand Awareness"
                    ) > -1
                  }
                />
                <ListItemText primary={"Brand Awareness"} />
              </MenuItem>
              <MenuItem value="Reach">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf("Reach") > -1
                  }
                />
                <ListItemText primary={"Reach"} />
              </MenuItem>
              <MenuItem value="Traffic">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf("Traffic") >
                      -1
                  }
                />
                <ListItemText primary={"Traffic"} />
              </MenuItem>
              <MenuItem value="Engagement">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "Engagement"
                    ) > -1
                  }
                />
                <ListItemText primary={"Engagement"} />
              </MenuItem>
              <MenuItem value="App Installs">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "App Installs"
                    ) > -1
                  }
                />
                <ListItemText primary={"App Installs"} />
              </MenuItem>
              <MenuItem value="Lead Generation">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "Lead Generation"
                    ) > -1
                  }
                />
                <ListItemText primary={"Lead Generation"} />
              </MenuItem>
              <MenuItem value="Messages">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf("Messages") >
                      -1
                  }
                />
                <ListItemText primary={"Messages"} />
              </MenuItem>
              <MenuItem value="Conversions">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "Conversions"
                    ) > -1
                  }
                />
                <ListItemText primary={"Conversions"} />
              </MenuItem>
              <MenuItem value="Catalogue Sales">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "Catalogue Sales"
                    ) > -1
                  }
                />
                <ListItemText primary={"Catalogue Sales"} />
              </MenuItem>
              <MenuItem value="Store Traffic">
                <Checkbox
                  className={classes.checkBox}
                  checked={
                    campaignState.campaignInfo.objectives &&
                    campaignState.campaignInfo.objectives.indexOf(
                      "Store Traffic"
                    ) > -1
                  }
                />
                <ListItemText primary={"Store Traffic"} />
              </MenuItem>
            </Select>
          </FormControl>
        </ListItem>

        <ListItem>
          <CustomInput
            label="Spending limit"
            value={campaignState.campaignInfo.spendingLimit || ""}
            id="spendingLimit"
            name="spendingLimit"
            onChange={onNameAndSpendChange}
            disabled={campaign.status === "active"}
            StartAdornment={() => <Typography>$</Typography>}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary="Start Date"
            secondary={
              <React.Fragment>
                <Typography>{campaignState.campaignInfo.startDate}</Typography>
                {campaignState.startDateOpen && (
                  <Calendar
                    value={campaignState.startDate}
                    onChange={(date) => handleDateSelection(date, "startDate")}
                    shouldHighlightWeekends
                    colorPrimary={primaryColor[0]}
                    colorPrimaryLight="#F8F8F8"
                    minimumDate={utils().getToday()}
                    disabledDays={[utils().getToday()]}
                    calendarClassName="custom-calendar" // and this
                    calendarTodayClassName="custom-today-day" // also this
                  />
                )}
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-haspopup="true"
              onClick={() => handleDateRangeClick("startDate")}
              aria-controls="main-menu"
            >
              <Tooltip title="Pick the starting date" arrow>
                <DateRangeIcon />
              </Tooltip>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary="End Date"
            secondary={
              <React.Fragment>
                <Typography>{campaignState.campaignInfo.endDate}</Typography>
                {campaignState.endDateOpen && (
                  <Calendar
                    value={campaignState.endDate}
                    onChange={(date) => handleDateSelection(date, "endDate")}
                    shouldHighlightWeekends
                    colorPrimary={primaryColor[0]}
                    colorPrimaryLight="#F8F8F8"
                    minimumDate={utils().getToday()}
                    disabledDays={[utils().getToday()]}
                    calendarClassName="custom-calendar" // and this
                    calendarTodayClassName="custom-today-day" // also this
                  />
                )}
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-haspopup="true"
              onClick={() => handleDateRangeClick("endDate")}
              aria-controls="main-menu"
            >
              <Tooltip title="Pick the ending date" arrow>
                <DateRangeIcon />
              </Tooltip>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem style={{ marginTop: 10 }}>
          <ListItemSecondaryAction>
            <Button
              variant="contained"
              color="primary"
              className={classes.createButton}
              disabled={!campaignState.changed}
              onClick={onSaveChanges}
            >
              Save Changes
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Card>
  );
};
