import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PulseLoader from "react-spinners/PulseLoader";
// core components
import Button from "../Buttons/customButton";
import uploadStyle from "../../assets/styles/components/uploadStyle";
import uploadDefaultImage from "../../assets/images/file-upload.jpg";

class Invitations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imageError: "",
      imagePreviewUrl: this.props.defaultImage
        ? this.props.defaultImage
        : uploadDefaultImage,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultImage !== this.props.defaultImage) {
      this.setState({
        ...this.state,
        imagePreviewUrl: this.props.defaultImage
          ? this.props.defaultImage
          : uploadDefaultImage,
      });
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log("=== file type", file.type);
    if (file.type && this.props.imageTypes.includes(file.type)) {
      reader.onloadend = () => {
        this.setState({
          ...this.state,
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        ...this.state,
        imageError: "Invalid file provided",
      });
    }
  };
  handleSubmit = (e) => {
    this.props.imageSubmit(this.state.file);
  };
  handleGenerate = (e) => this.props.generateInvitations();

  handleClick = () => {
    this.refs.fileInput.click();
  };

  handleRemove = async () => {
    const error = await this.props.removeUploaded();
    if (!error) {
      this.setState({
        file: null,
        imagePreviewUrl: uploadDefaultImage,
      });
      this.refs.fileInput.value = null;
    }
  };
  render() {
    const {
      isForm,
      classes,
      addButtonProps,
      changeButtonProps,
      imageError,
      isUploading,
      imageTypes,
      isSmall,
      disabled,
      isFileUpload,
      uploaded,
      isGenerating
    } = this.props;

    console.log(isGenerating, "these are the data values");

    return (
      <div className={isSmall ? classes.smallRoot : classes.root}>
        <div className={classes.filePreview}>
          <input
            type="file"
            onChange={this.handleImageChange}
            ref="fileInput"
            accept={imageTypes}
            style={{
              display: "none",
            }}
          />

          {!isFileUpload && (
            <img
              className={
                isSmall ? classes.formImageScreen : classes.imageScreen
              }
              src={this.state.imagePreviewUrl}
              alt="..."
            />
          )}
        </div>

        {!disabled && (
          <div className={classes.fileInfo} style={{width:"fit-content"}}>
            <div className={classes.Info}>
              <Typography variant="subtitle2" className={classes.errorText}>
                {this.state.imageError || imageError}
              </Typography>

              <Typography
                variant="subtitle1"
                className={
                  this.state.file && this.state.file.name
                    ? classes.uploadedImageFileName
                    : classes.infoText
                }
              >
                {this.state.file && this.state.file.name
                  ? this.state.file.name.toLowerCase().split(/\s/).join("")
                  : this.state.imagePreviewUrl.includes("https")
                  ? this.state.imagePreviewUrl
                  : `Choose ${isFileUpload ? "File" : "Image"}`}
              </Typography>
              <Typography
                variant="caption"
                className={isSmall ? classes.doNotShow : classes.infoText}
              >
                Supported {isFileUpload ? "File" : "Image"} formats:{" "}
                <u>{`${imageTypes}`}</u>,
              </Typography>
            </div>

            <div className={classes.actionButtons}>
              {this.state.file === null &&
              !this.state.imagePreviewUrl.includes("https") ? (
                <Button {...addButtonProps} onClick={() => this.handleClick()}>
                  {`Select ${isFileUpload ? "File" : "Image"}`}
                </Button>
              ) : (
                <span style={{display:"flex", flexDirection:"column"}}>
                  <div>
                    <Button
                      {...changeButtonProps}
                      onClick={() => this.handleClick()}
                      disabled={isUploading || uploaded?.url }
                    >
                      Change
                    </Button>

                    <Button
                      {...changeButtonProps}
                      color="success"
                      onClick={() => this.handleSubmit()}
                      disabled={isUploading || uploaded?.url }
                    >
                      {isUploading ? (
                        <PulseLoader
                          size={5}
                          margin={3}
                          color={"#fff"}
                          loading={isUploading}
                        />
                      ) : (
                        "Upload"
                      )}
                    </Button>
                  </div>
                  {isFileUpload && <Button
                    {...changeButtonProps}
                    style={{backgroundColor:"#4d4df1 !important" }}
                    onClick={() => this.handleGenerate()}
                    disabled={isUploading || !uploaded?.url || !isGenerating.status === 201 }
                  >
                    {isUploading && uploaded?.url && !isGenerating.status ? (
                      <PulseLoader
                        size={5}
                        margin={3}
                        color={"#fff"}
                        loading={!isGenerating.status && uploaded?.url && isUploading}
                      />
                    ) : (
                      "Generate Invitations"
                    )}
                  </Button>}
                </span>
              )}
            </div>
          </div>
        )}
        <br />
      </div>
    );
  }
}

Invitations.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

export default withStyles(uploadStyle)(Invitations);
