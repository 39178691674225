import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import EditAdvertContainer from "../../containers/advertisement/EditAdvert";

export class EditAdvert extends Component {
  render() {
    const advertId = this.props.match.params.advertId;
    const campaignId = this.props.match.params.campaignId;
    return (
      <AppLayout>
        <EditAdvertContainer advertId={advertId} campaignId={campaignId} />
      </AppLayout>
    );
  }
}

export default EditAdvert;
