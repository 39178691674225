import apiService from '../../services/apiService';
import { SELECT_PUBLISHER } from './types';

export const selectPublisherAction = (organization) => {
  
  return async (dispatch) =>
    await dispatch({
      type: SELECT_PUBLISHER,
      payload: organization,
    });
};
