import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/isLoading";
import reduxAction from "../../../../redux/action";
import { FETCH_SINGLE_AD } from "../../../../redux/ads/types";
import moment from "moment";
import AdComponent from "../../../components/ads/AdComponent";
import { Typography } from "@material-ui/core";
import { Markup } from "interweave";
import Tag from "../../../../components/tag";
import Payment from "../../payment/Payment";

export default ({ advertId, campaignId }) => {
  const singleAdvertStore = useSelector((state) => state.singleAdvert);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};
  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
    organization: user.claims.userOrg,
  };

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/ads/${advertId}`,
        actionType: FETCH_SINGLE_AD,
      })
    );
  }, [state]);

  const advertData = singleAdvertStore.data.results
    ? {
        ...singleAdvertStore.data.results,
        id: singleAdvertStore.data.results.id,
        campaignId: campaignId,
        imgUrl:
          singleAdvertStore.data.results.content.url ||
          singleAdvertStore.data.results.content.photoUrl,
        name:
          singleAdvertStore.data.results.adType === "banner"
            ? "Banner"
            : singleAdvertStore.data.results?.adType === "advertorial"
            ? singleAdvertStore.data.results.content.title
            : "Social Media Post",
        status: singleAdvertStore.data.results.status,
        adType: singleAdvertStore.data.results.adType,
        adContent:
          singleAdvertStore.data.results.adType === "banner" ? (
            <>
              <Typography variant="subtitle1">
                <strong>Caption</strong>:{" "}
                {singleAdvertStore.data.results.content.caption}
              </Typography>
              <br />
              <Typography variant="subtitle1">
                <strong>Banner Link</strong>:{" "}
                {singleAdvertStore.data.results.content.link}
              </Typography>
            </>
          ) : singleAdvertStore.data.results.adType === "advertorial" ? (
            <>
              <Typography variant="subtitle1">
                <strong>Summary</strong>:{" "}
                {singleAdvertStore.data.results.content.summary || "No Summary"}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">
                <strong>Post Mentions:</strong>
                <br />
                {singleAdvertStore.data.results.content.mentions?.map(
                  (mention, index) => (
                    <span key={index}>
                      <Tag content={mention} />
                      {(index + 1) % 3 === 0 && <br />}
                    </span>
                  )
                ) || "No Mentions"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Post Tags:</strong>
                <br />
                {singleAdvertStore.data.results.content.tags?.map(
                  (tag, index) => (
                    <span key={index}>
                      <Tag content={tag} />
                      {(index + 1) % 3 === 0 && <br />}
                    </span>
                  )
                ) || "No Mentions"}
              </Typography>
              <br />
              <Typography variant="subtitle1">
                <strong>Post Content</strong>:
                <br />
                {singleAdvertStore.data.results.content.summary}
              </Typography>
            </>
          ),
      }
    : {};

  const handlePayment = (entityData) => {
    setState({ ...state, openPaymentModel: true, entityData });
  };
  const handleClosePayment = () => {
    setState({ ...state, openPaymentModel: false, entityData: {} });
  };

  return (
    <div>
      <Payment
        entityData={singleAdvertStore.data.results}
        isOpen={state.openPaymentModel}
        handleClosePayment={handleClosePayment}
      />
      {(singleAdvertStore.loading && !singleAdvertStore.data.results) ||
      (singleAdvertStore.loading &&
        singleAdvertStore.data.results.id !== advertId) ? (
        <Loading />
      ) : (
        <div>
          <AdComponent content={advertData} handlePayment={handlePayment} />
          <br />
          {singleAdvertStore.data.results?.adType === "advertorial" && (
            <Markup content={singleAdvertStore.data.results.content.content} />
          )}
        </div>
      )}
    </div>
  );
};
