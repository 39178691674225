import apiService from "../services/apiService";

export default async (userClaims) => {
  const appId = process.env.APP_ID;
  const { apps = [], organizations = [] } = userClaims || {};

  const currentApp = apps.find((app) => app.appId === appId) || {};
  const currentOrg =
    organizations.find(
      (or) => or.apps && or.apps.findIndex((a) => a.appId === appId) > -1
    ) || {};

  const orgApp = currentOrg.apps
    ? currentOrg.apps.find((a) => a.appId === appId) || {}
    : {};

  const userOrg = {
    orgId: currentOrg.orgId,
    role: orgApp.role || currentApp.role,
  };

  if (currentApp.role === "owner" || currentApp.role === "admin") {
    const current = JSON.parse(localStorage.getItem("au"));
    const currOrg = current ? current.claims.userOrg : {};
    userOrg.name = currOrg.name || "Inyarwanda Ltd";
    userOrg.orgId = currOrg.orgId || "inyarwanda1623310420951";
    userOrg.canViewStats = currOrg.canViewStats;
    userOrg.logo = currOrg.logoSrc;
  } else {
    if (userOrg.orgId) {
      const orgInfo = await apiService({
        path: `/clients/${userOrg.orgId}`,
        method: "GET",
      });
      if (orgInfo.results) {
        userOrg.name = orgInfo.results.clientName;
        userOrg.logo = orgInfo.results.logoSrc;
        userOrg.canViewStats = orgInfo.results.canViewStats;
      }
    }
  }

  return {
    userApp: currentApp,
    userOrg,
  };
};
