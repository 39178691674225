import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
// core components
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import ImageUpload from "../../../../views/containers/upload/imageUpload";
import customCheckboxRadioSwitch from "../../../../assets/styles/widgets/customCheckboxRadioSwitch";
import Toast from '../../../MessageToast';

const style = {
  ...customCheckboxRadioSwitch,
  root:{
    padding: "0 10%",
  },
  
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  
  inputHelperTextError: {
    color: '#f44336',
  },
  
  inputLabelHorizontalError: {
    ...customCheckboxRadioSwitch.labelHorizontal,
    color: '#f44336',
  },
  
  labelHorizontalError: {
    ...customCheckboxRadioSwitch.labelHorizontal,
    color: '#f44336',
    '@media (min-width: 992px)': {
      float: 'left',
    },
  },
  urls:{
      width:"100%",
      margin: "0 3%",
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile:this.props.defaultValues?this.props.defaultValues.profileStep1.imageFile&&
                this.props.defaultValues.profileStep1.imageFile:null,
    };
  }
  
  componentDidUpdate(prevProps,prevState){
    // if(prevProps.imageStore.data.path !== this.props.imageStore.data.path){
        // this.setState({ imageFile:this.props.imageStore.data.path });
        // Toast({
        //   message: this.props.imageStore.data.message,
        //   type: 'success',
        // });
    // }
  }
  
  sendState() {
    return this.state;
  }

  change(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  
  isValidated() {
    return true;
  }
  
  handleFileSubmit = file =>{
    this.setState({ ...this.state,imageFile:file});
  }
  
  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.root}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
                <GridContainer>
                <FormLabel className={classes.labelText}>
                  Profile Picture
                </FormLabel>
                <br />
                <br />
                
                <GridItem xs={12}  sm={12}>
                   <ImageUpload
                     defaultImage={this.state.imageFile}
                     imageSubmit={this.handleFileSubmit}
                   />
                </GridItem>
                </GridContainer>
               
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  searchResult: state.searchResults,
  imageStore: state.uploadedImageFile,
});

const mapDispatchToProps = (dispatch) => ({
  searchForArtist: (state, query, searchIndices) => dispatch(search(state, query, searchIndices)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step1));
