import React from "react";
import { whiteColor, darkGrey } from "../assets/styles/base";

export default (props) => {
  const { content } = props;

  return (
    <span
      style={{
        padding: "3px 10px",
        marginRight: 5,
        borderRadius: 30,
        backgroundColor: darkGrey,
        width: "100%",
        color: whiteColor,
        border: `1px solid ${whiteColor}`,
      }}
    >
      {content}
    </span>
  );
};
