import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
// import reducers
import { dashboardReducers } from "./dashboard";
import { clientsReducers } from "./clients";
import { settingsReducers } from "./settings";
import { organizationReducers } from "./publishers";
import { audienceReducers } from "./audience";
import { trafficReducers } from "./traffic";
import { uploadReducers } from "./upload";
import { campaignsReducers } from "./campaign";
import { notificationsReducers } from "./notifications";
import { helperReducers } from "./helperReducer";
import { eventsReducers } from "./events";
import { orgsReducers } from "./organizations";
import { currentUserReducers } from "./auth";
import { advertsReducers } from "./ads";
import { paymentReducers } from "./payment";

const reducers = combineReducers({
  ...notificationsReducers,
  ...dashboardReducers,
  ...clientsReducers,
  ...settingsReducers,
  ...organizationReducers,
  ...audienceReducers,
  ...trafficReducers,
  ...uploadReducers,
  ...campaignsReducers,
  ...eventsReducers,
  ...helperReducers,
  ...orgsReducers,
  ...currentUserReducers,
  ...advertsReducers,
  ...paymentReducers,
});

export default () => {
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunk, promise))
  );

  return store;
};
