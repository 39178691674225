import React, { useEffect, useState } from "react";
import moment from "moment";
import Loading from "../../../components/isLoading";
import MapComponent from "../../components/map/vectorMap";
import NoContent from "../../../components/NoContent";
import { useSelector, useDispatch } from "react-redux";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER_COUNTRY_INFO } from "../../../redux/publishers/types";
import { smallTableDataFormat } from "../../../helpers/formatChartData";
import RecordsComponent from "../../components/dashboard/RecordsComponent";
import TimePicker from "../../components/date/TimePickerComponent";
import { createData } from "../../../helpers/helperMethods";
import DataTableComponent from "../../components/data/DataTableComponent";

export default (props) => {
  const selectedPublishersStore = useSelector((state) => state.selectPublisher);
  const selectedPublisher = selectedPublishersStore.selectedPublisher;
  const publisherCountryInfoStore = useSelector(
    (store) => store.publisherCountryInfo
  );

  const dimensions = [
    { id: "facebook", name: "Facebook" },
    { id: "website", name: "Website" },
  ];

  const dispatch = useDispatch();

  const [state, setState] = useState({
    selectedDimension: { name: "facebook", identifier: "fb" },
    selectedSecondaryDimension: "page-impression",
    dataFor: "page-impression",
  });

  const [dateState, setDateState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  let startDate = moment(
    dateState.date && dateState.date.startDate
      ? dateState.date.startDate
      : dateState.date
  )
    .subtract(6, "d")
    .format("YYYY-MM-DD");
  let endDate = moment(
    dateState.date && dateState.date.endDate
      ? dateState.date.endDate
      : dateState.date
  ).format("YYYY-MM-DD");

  if (state.daily) {
    startDate = moment(dateState.date).subtract(1, "d").format("YYYY-MM-DD");
    endDate = moment(dateState.date).subtract(1, "d").format("YYYY-MM-DD");
  } else if (state.weekly) {
    startDate = moment(dateState.date).subtract(7, "d").format("YYYY-MM-DD");
  } else if (state.monthly) {
    startDate = moment(dateState.date).subtract(1, "M").format("YYYY-MM-DD");
  } else if (state.yearly) {
    startDate = moment(dateState.date).subtract(1, "y").format("YYYY-MM-DD");
  }

  const onRangeChange = (range) => setDateState({ [range]: !state[range] });
  const onDateChange = (date) => setDateState({ ...date });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=countries&startDate=${startDate}&endDate=${endDate}`,
        actionType: FETCH_PUBLISHER_COUNTRY_INFO,
      })
    );
  }, [selectedPublisher, state.selectedDimension.identifier]);

  useEffect(() => {
    if (
      !publisherCountryInfoStore.data.results ||
      (dateState.date && dateState.date.startDate) ||
      dateState.date !== moment().format("YYYY-MM-DD") ||
      dateState.daily ||
      dateState.weekly ||
      dateState.monthly ||
      dateState.yearly
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=countries&startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_PUBLISHER_COUNTRY_INFO,
        })
      );
    }
  }, [dateState]);

  const handleDimensionChange = (selected) =>
    setState((prevState) => ({
      ...state,
      selectedDimension: {
        name: selected,
        identifier:
          prevState.selectedDimension.identifier === "fb" ? "web" : "fb",
      },
      selectedSecondaryDimension:
        selected === "facebook" ? "page-impression" : "page-views-articles",
    }));

  const secondaryDimensions =
    state.selectedDimension.name === "facebook"
      ? [{ id: "page-impression", name: "Page Impressions" }]
      : [
          { id: "page-views-articles", name: "Page Views & Articles" },
          { id: "time-on-site", name: "Time On Site (min)" },
        ];

  const handleSecondaryDimensionChange = (selected) =>
    setState({
      ...state,
      selectedSecondaryDimension: selected,
      dataFor: selected,
    });

  const recordsToDisplay = [
    {
      title: "Number of Countries",
      color: "warning",
      iconName: "flag", // source: https://fonts.google.com/icons?icon.query=country
      value: publisherCountryInfoStore.data.results
        ? publisherCountryInfoStore.data.results.countriesSummary.length
        : 0,
    },
  ];

  const topCountries = publisherCountryInfoStore.data.results
    ? smallTableDataFormat(
        publisherCountryInfoStore.data,
        state.selectedSecondaryDimension,
        publisherCountryInfoStore.data.results.countriesSummary.length
      )
    : [];

  const headCells = [
    {
      id: "countries",
      rightAlign: false,
      label: "Countries",
    },
    {
      id: topCountries[0] ? topCountries[0][2].toLowerCase() : "impressions",
      rightAlign: true,
      label: topCountries[0] ? topCountries[0][2].toLowerCase() : "Impressions",
    },
  ];

  const rows = topCountries
    .filter((elt) => elt[1] !== "Country")
    .map((elt) =>
      createData(
        elt[1] && elt[1].toLowerCase(),
        { content1: elt[0], content2: elt[1] },
        elt[2]
      )
    );

  return publisherCountryInfoStore.loading &&
    !publisherCountryInfoStore.data.results ? (
    <Loading />
  ) : (
    <>
      <TimePicker
        startDate={startDate}
        endDate={endDate}
        onRangeChange={onRangeChange}
        onDateChange={onDateChange}
        lastSyncTime={
          moment(
            publisherCountryInfoStore.data.results &&
              publisherCountryInfoStore.data.results.lastSyncTime
          ).format("LLL") || "00:00 AM"
        }
      />
      <RecordsComponent
        isUpdating={
          publisherCountryInfoStore.loading &&
          publisherCountryInfoStore.data.results
        }
        records={recordsToDisplay}
      />
      {publisherCountryInfoStore.data.results ? (
        <MapComponent
          title="Global Performance"
          mapData={smallTableDataFormat(
            publisherCountryInfoStore.data.results
              ? publisherCountryInfoStore.data
              : [],
            state.selectedSecondaryDimension
          )}
          dimensions={dimensions}
          valueOfDimension={state.selectedDimension.name}
          onDimensionChange={handleDimensionChange}
          secondaryDimensions={secondaryDimensions}
          valueOfSecondaryDimension={state.selectedSecondaryDimension}
          onSecondaryDimensionChange={handleSecondaryDimensionChange}
          isUpdating={
            publisherCountryInfoStore.loading &&
            publisherCountryInfoStore.data.results
          }
        />
      ) : (
        <NoContent title={"No Content ."} />
      )}
      <DataTableComponent
        noSearch
        isUpdating={
          publisherCountryInfoStore.loading &&
          publisherCountryInfoStore.data.results
        }
        rows={rows}
        headCells={headCells}
      />
    </>
  );
};
