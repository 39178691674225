import React, { useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
  },
}));

export default (props) => {
  const classes = useStyles();
  const { menuData, showChosenData, selectedOption } = props;

  const options = menuData.data.labels.map((elt, index) => ({
    label: elt,
    value: index + 1,
    data:
      menuData.data.datasets[0].label === "Votes"
        ? {
          votes: menuData.data.datasets[0].data[index],
          revenues: menuData.revenues.datasets[0].data[index],
        }
        : {
          views: menuData.data.datasets[0].data[index],
          articles: menuData.data.datasets[1].data[index],
          revenues: menuData.revenues.datasets[0].data[index],
        },
  }));
  return (
    <div className={classes.root}>
      <ReactMultiSelectCheckboxes
        options={options}
        rightAligned
        value={selectedOption || []}
        onChange={(target) => showChosenData(target)}
      />
    </div>
  );
};
