import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import PaymentContent from "./PaymentContent";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default ({
  isOpen,
  handleClose,
  content,
  onPaymentConfirm,
  paymentConfirmationStore,
  onOtpSubmit,
  paymentOptions,
}) => {
  const classes = useStyles();

  const onClosingModal = () => {
    handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClosingModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <PaymentContent
        isOpen={isOpen}
        handleClose={handleClose}
        content={content}
        onPaymentConfirm={onPaymentConfirm}
        paymentConfirmationStore={paymentConfirmationStore}
        onOtpSubmit={onOtpSubmit}
        paymentOptions={paymentOptions}
      />
    </Modal>
  );
};
