import { uploadToStorageService } from '../services/uploadService';

export const uploadImage = async (file, destination, outside) => {
  const data = new FormData();
  data.append('image', file);
  data.append('destination', destination);
  if (outside) data.append('outside', true);
  const response = await uploadToStorageService('/images/upload', data);
  return {
    message: response.error ? response.errorMessage : response.response.message,
    error: response.error ? true : false,
    data: response.response,
  };
};
