import { primaryColor } from "../base";
export default (theme) => ({
  payment: {
    textAlign: "center",
  },
  customAmountTitle: {
    padding: "20px",
  },
  customAmount: {
    padding: "20px",
    color: "#3c4043",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    border: "1px solid #dadce0",
    borderRadius: "8px",
    margin: "10px 0",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  customAmountInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  currency: {
    padding: "0 10px",
    fontWeight: 500,
  },
  slider: {
    width: "200px",
    color: primaryColor[0],
  },
});
