import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/isLoading";
import reduxAction from "../../../../redux/action";
import {
  FETCH_ALL_EVENTS,
  FETCH_PENDING_EVENTS,
} from "../../../../redux/events/types";
import { useHistory } from "react-router";
import AllBanners from "./AllBanners";
import AllAdvertorials from "./AllAdvertorials";
import { advertorials, banners } from "../dataGenerator";
import HorizontalIcons from "../../../components/List/HorizontalIcons";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import DescriptionIcon from "@material-ui/icons/Description";
import { IconButton, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const eventsStore = useSelector((store) => store.allEvents);

  const [state, setState] = useState({
    selectedMedia: "",
  });

  const user = JSON.parse(localStorage.getItem("au")) || {};

  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };

  // useEffect(() => {
  //   if (!eventsStore.data.results) {
  //     dispatch(
  //       reduxAction({
  //         method: "GET",
  //         path: isPending ? `/events?status=pending` : `/events`,
  //         actionType: isPending ? FETCH_PENDING_EVENTS : FETCH_ALL_EVENTS,
  //       })
  //     );
  //   }
  // }, [state, isPending]);

  const handleSelection = (selected) =>
    setState({ ...state, selectedMedia: selected.name });

  const itemsToShow = [
    {
      name: "Banners",
      icon: <PhotoSizeSelectActualIcon style={{ fontSize: 100 }} />,
    },
    {
      name: "Advertorials",
      icon: <DescriptionIcon style={{ fontSize: 100 }} />,
    },
  ];

  const handleBackArrow = () => setState({ ...state, selectedMedia: "" });
  return (
    <div>
      {eventsStore.loading && !eventsStore.data.results ? (
        <Loading />
      ) : (
        <div>
          {state.selectedMedia && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleBackArrow}
            >
              <ChevronLeft />
            </IconButton>
          )}
          {state.selectedMedia === "" ? (
            <div>
              <HorizontalIcons
                title="Media Creatives"
                onClick={handleSelection}
                content={itemsToShow}
              />
            </div>
          ) : state.selectedMedia === "Banners" ? (
            <AllBanners
              isUpdating={eventsStore.loading && eventsStore.data.results}
              userInfo={userInfo}
              content={banners}
            />
          ) : (
            state.selectedMedia === "Advertorials" && (
              <AllAdvertorials
                isUpdating={eventsStore.loading && eventsStore.data.results}
                userInfo={userInfo}
                content={advertorials}
              />
            )
          )}

          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};
