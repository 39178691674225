import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// core-components
import Invitations from "./ImageUpload";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Toast from "../MessageToast";
import { fullUrlAction } from "../../redux/action";
import { GENERATE_INVITATIONS, UPLOAD_INVITATIONS } from "../../redux/events/types";
import { resetFileStore } from "../../redux/action";
import fullUrlApiService from "../../services/fullUrlService";

export default (props) => {
  const imageStore = useSelector((store) => store.uplodInvitationsFile);
  const generatedInvitations = useSelector(store => store.generateInvitations);
  const dispatch = useDispatch();

  const [imageState, setImageState] = useState({
    imageError: "",
    updateComponent: false,
  });
  const [state, setState] = useState({
    loading: false,
  });
  const fileUrl = !imageStore.loading && imageStore.data.results

  useEffect(() => {
    dispatch(resetFileStore());
  }, []);
  const [dataResponse, setDataResponse] = useState({});

  const handleImageSubmit = async (imageData) => {
    const file = new FormData();
    file.append("files", imageData);
    file.append("bucketName", "test-concert-invitations");
    Toast({
      message:
        "Uploading FIle ...",
      type: "warning",
      duration: 10000,
    });
     dispatch(
      fullUrlAction({
        method:"POST",
        path:`https://us-central1-nonehoidentity.cloudfunctions.net/NonehoGCPStorageAPI/api/upload`,
        path: process.env.GCP_FILE_UPLOAD_URL,
        data: file,
        actionType:UPLOAD_INVITATIONS
      })
    );
    Toast({
      message: "Successfully Uploaded Invitations File",
      type: "success",
      duration: 30000,
      onClose: () => {},
    });

    setImageState({ ...imageState, updateComponent: true });
  };
  

  const handleGenerateInvitations = async()=>{
    setDataResponse(true);
    const dataValues = {
      eventInfo: {
        eventName: props.event.name,
        eventId: props.event.id,
        eventPhoto: props.event.coverPhoto,
        eventTime: new Date(props.event.startDate).toLocaleTimeString(),
        eventDate: props.event.startDate,
        organizer: props.event.organizer,
        eventDescription: props.event.description,
        eventVenue: props.event.venue,
      },
      invitationFileUrl:fileUrl.url,
    };
    const response = await fullUrlApiService({
      method:"POST",
      path:`${process.env.API_BASE_URL}/events/generate_invitations`,
      data: dataValues,
    });
    setDataResponse(response);
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          // reset field
        }
      },
    });

    
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={10} sm={10}>
          <Invitations
            isSmall={!!props.isSmall}
            isFileUpload={props.isFileUpload}
            defaultImage={props.defaultImg}
            imageSubmit={handleImageSubmit}
            removeUploaded={null}
            isGenerating={dataResponse}
            generatedStatus={!generatedInvitations.loading && generatedInvitations.status}
            generateInvitations={handleGenerateInvitations}
            imageTypes={
              props.isFileUpload
                ? "text/csv"
                : "image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico"
            }
            addButtonProps={{
              color: "primary",
            }}
            changeButtonProps={{
              color: "primary",
            }}
            removeButtonProps={{
              color: "danger",
            }}
            removing={imageState.removing}
            imageError={imageState.imageError}
            isUploading={imageStore.loading}
            uploaded={fileUrl}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};