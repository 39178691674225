import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../assets/images/placeholder.png";
import itemInfoStyles from "../../../assets/styles/components/itemInfoStyles";
import SingleItemMenu from "../../../components/Menu/SingleItemMenu";
import { Edit } from "@material-ui/icons";
import { dangerColor, successColor } from "../../../assets/styles/base";
import Chip from "@material-ui/core/Chip";
import { formatDate, formatNumber } from "../../../helpers/helperMethods";
import Button from "../../../components/Buttons/customButton";

const styles = makeStyles((theme) => itemInfoStyles(theme));

export default ({ content, handlePayment }) => {
  const history = useHistory();
  const classes = styles();

  const actions = [
    {
      onClick: () =>
        history.push(`/campaign/${content.campaignId}/ads/${content.id}/edit`),
      text: "Edit Advert",
      icon: <Edit fontSize="small" />,
    },
  ];

  return (
    <div>
      <Grid container className={classes.topSection}>
        <Grid item xs={12} container justify="flex-start">
          <div>
            <ProgressiveImage src={content.imgUrl} placeholder={defaultImg}>
              {(src, loading) => (
                <img
                  style={{
                    opacity: loading ? 0.7 : 1,
                    objectFit: "contain",
                    borderRadius: "6px",
                    maxHeight: "50vh",
                    marginRight: "10px",
                  }}
                  src={src}
                  alt="No cover"
                  className={classes.image}
                />
              )}
            </ProgressiveImage>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          spacing={2}
          className={classes.infoSection}
        >
          <Grid item className={classes.textInfo}>
            <Typography variant="h4" className={classes.texts}>
              {content.name}
            </Typography>

            <Grid item className={classes.actions}>
              <Grid container spacing={2} justify="flex-end">
                <SingleItemMenu horiz background menu={actions} />
              </Grid>
            </Grid>

            <Tooltip title="Advertisement Type" placement="top-start" arrow>
              <Typography variant="h6" className={classes.name}>
                {content.adType || "Unknown"}
              </Typography>
            </Tooltip>

            <Typography className={classes.texts}>
              <Chip
                label={
                  <Typography variant="subtitle1">
                    {`Payment Plan: ${
                      content.isPaid
                        ? formatNumber(content.plan.amount)
                        : "Unpaid"
                    }`}
                  </Typography>
                }
                style={{
                  backgroundColor: `${
                    content.isPaid ? successColor[0] : dangerColor[0]
                  }`,
                  color: "#fff",
                  size: "100px",
                }}
              />
              {!content.isPaid && (
                <span
                  onClick={handlePayment}
                  style={{
                    padding: "0 10px",
                    cursor: "pointer",
                    color: "#000",
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                >
                  Pay Ad
                </span>
              )}
            </Typography>
            <br />
            {content.adContent}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
