import * as React from "react";
import { primaryColor } from "../../assets/styles/base";

export const TabSelector = ({ isActive, children, onClick, key }) => {
  const defaultStyles = {
    cursor: "pointer",
    border: "none",
    borderBottom: "none",
    padding: "10px",
    color: "gray",
    background: "inherit",
  };
  const activeStyles = {
    ...defaultStyles,
    color: primaryColor[0],
    borderBottom: `2px solid ${primaryColor[0]}`,
  };
  return (
    <button
      key={key || ""}
      onClick={onClick}
      style={isActive ? activeStyles : defaultStyles}
    >
      {children}
    </button>
  );
};
