import {
  FETCH_OVERVIEW_DATA,
  FETCH_PAGE_VIEWS_DATA,
  FETCH_SESSIONS_DATA,
  FETCH_TIME_ON_SITE_DATA,
  FETCH_UNIQUE_VISITS_DATA,
} from './types';

const initialState = {
  loading: false,
  data: {},
};

export default class TrafficReducers {
  static overviewDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_OVERVIEW_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_OVERVIEW_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_OVERVIEW_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }

  static pageViewsDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_PAGE_VIEWS_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_PAGE_VIEWS_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_PAGE_VIEWS_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }

  static sessionsDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_SESSIONS_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_SESSIONS_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_SESSIONS_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }
  
  static timeOnSiteDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_TIME_ON_SITE_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_TIME_ON_SITE_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_TIME_ON_SITE_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }

  static uniqueVisitsDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_UNIQUE_VISITS_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_UNIQUE_VISITS_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_UNIQUE_VISITS_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }
}
