import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/isLoading";
import RecordsComponent from "../../../components/dashboard/RecordsComponent";
import TimePicker from "../../../components/date/TimePickerComponent";
import moment from "moment";
import reduxAction from "../../../../redux/action";
import { useHistory } from "react-router";
import DataContainer from "./dataContainer";
import { CreateButton, handleDates } from "../../../../helpers/helperMethods";
import apiService from "../../../../services/apiService";
import Toast from "../../../../components/MessageToast";
import {
  FETCH_ALL_ADS,
  FETCH_PENDING_ALL_ADS,
} from "../../../../redux/ads/types";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isPending = history.location.pathname.includes("pending");

  const advertsStore = useSelector((store) =>
    isPending ? store.pendingAdverts : store.allAdverts
  );

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const user = JSON.parse(localStorage.getItem("au")) || {};

  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };

  const { startDate, endDate } = handleDates(state);

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  useEffect(() => {
    if (!advertsStore.data.results) {
      dispatch(
        reduxAction({
          method: "GET",
          path: isPending
            ? `/clients/${userInfo.organization.orgId}/ads`
            : `/clients/${userInfo.organization.orgId}/ads`,
          actionType: isPending ? FETCH_PENDING_ALL_ADS : FETCH_ALL_ADS,
        })
      );
    }
  }, [state, isPending]);

  const recordsToDisplay = [
    {
      title: "Advertisements",
      color: "warning",
      iconName: "today",
      value: advertsStore.data.results?.length || 0,
    },
  ];

  const handleClickCreateAd = () => history.push("/ads/new");

  const onDeleteEvent = async (eventId) => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "DELETE",
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events?status=pending`,
            actionType: FETCH_PENDING_ALL_ADS,
          })
        );
      },
    });
  };

  const onLaunchEvent = async (eventId) => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: {
        status: "active",
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        history.push("/events");
      },
    });
  };

  const adsData =
    advertsStore.data.results?.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }) || [];

  return (
    <div>
      {(advertsStore.loading && !advertsStore.data.results) ||
      (isPending && advertsStore.loading && advertsStore.data.results) ? (
        <Loading />
      ) : (
        <>
          <TimePicker
            startDate={startDate}
            endDate={endDate}
            onRangeChange={onRangeChange}
            onDateChange={onDateChange}
            lastSyncTime={
              moment(
                advertsStore.data.results &&
                  advertsStore.data.results.lastSyncTime
              ).format("LLL") || "00:00 AM"
            }
          />
          <CreateButton
            text="Create new Ad"
            handleOnClick={handleClickCreateAd}
            styles={{ float: "right" }}
          />

          {!isPending && (
            <RecordsComponent
              isUpdating={advertsStore.loading && advertsStore.data.results}
              records={recordsToDisplay}
            />
          )}
          <DataContainer
            isUpdating={advertsStore.loading && advertsStore.data.results}
            data={adsData}
            notActive={isPending}
            onLaunchEvent={onLaunchEvent}
            onDeleteEvent={onDeleteEvent}
          />
        </>
      )}
    </div>
  );
};
