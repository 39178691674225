import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import style from '../../../assets/styles/components/campaignFormbodyStyle';
import AddBasicsComponent from './addBasicsComponent';
import AddBannerComponent from './AddEventCover';
import EventContestants from './contestants/index';
import { useWindowDimensions } from '../../../helpers/helperMethods';
import { useHistory } from 'react-router';
import OfflineTicketsComponent from './offlineTicketsComponent';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TicketBatches from './BatchTickets';
import GenerateInvitations from './GenerateInvitations';
import Invitations from './Invitations';
import IndividualsInvitation from './IndividualsInvitation';

const useStyles = makeStyles((theme) => style(theme));

export default ({
  event,
  onBasicInforChange,
  onBannerUpload,
  bannerUploadState,
  onAddContestant,
  apiCall,
  onRemoveContestant,
  editContestant,
  onLaunchEvent,
  onActivateOrStopEvent,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    title: 'Basic Information',
    openMenu: false,
    isMobile: false,
    componentToDisplay: (
      <AddBasicsComponent
        event={event}
        onBasicInforChange={onBasicInforChange}
        apiCall={apiCall}
        onLaunchEvent={onLaunchEvent}
      />
    ),
    menuItems: [
      {
        text: 'Basic Information',
      },
      {
        text: 'Event Promotions',
      },
      {
        text: 'Event Cover',
      },
    ],
  });
  const [selectedIndex, setSelectedIndex] = useState(true);

  useEffect(() => {
    if (event.category === 'Voting' && state.menuItems.length == 3) {
      const newMenuItems = state.menuItems;
      newMenuItems.push({
        text: 'Contestants',
      });
      setState({
        ...state,
        menuItems: newMenuItems,
      });
    }
    if (event.category === 'Concert' && state.menuItems.length == 3) {
      const newMenuItems = state.menuItems;
      newMenuItems.push({
        text: 'Offline Tickets',
        collapse: true,
        views: [
          {
            text: "Generate Offline Tickets"
          },
          {
            text: "Offline Ticket Batches"
          }
        ]
      },
      {
        text: 'Invitations',
        collapse: true,
        views: [
          {
            text: "Generate Invitations"
          },
          {
            text: "Individual Invitation"
          },
          {
            text: "Download Invitations"
          }
        ]
      }
      );
      setState({
        ...state,
        menuItems: newMenuItems,
      });
    }
  }, [event]);

  const handleDrawerOpen = () => setState({ ...state, openMenu: true });

  const handleDrawerClose = () => setState({ ...state, openMenu: false });

  const handleMenuItemChange = (item) => {
    if (item.text === 'Event Promotions') {
      history.push(`/campaigns/${event.id}/edit`);
    }
    setState({
      ...state,
      title: item.text,
      openMenu: false,
    });
  };

  const handleCollapse = (index) => {
    if(index === selectedIndex){
      setSelectedIndex("")
      setCollapse(!collapse);
    }else{
      setSelectedIndex(index)
      setCollapse(!collapse);
    }
  };

  const dimensions = useWindowDimensions();

  if (dimensions.width <= 500 && !state.isMobile) {
    setState({ ...state, isMobile: true });
  }

  if (dimensions.width > 500 && state.isMobile) {
    setState({ ...state, isMobile: false });
  }
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <br />
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='relative' className={classes.appBar}>
          <Toolbar>
            <Grid container>
              {state.isMobile && (
                <>
                  {state.openMenu ? (
                    <IconButton
                      color='inherit'
                      aria-label='open drawer'
                      onClick={handleDrawerClose}
                      edge='start'
                      className={clsx(classes.menuButton)}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color='inherit'
                      aria-label='open drawer'
                      onClick={handleDrawerOpen}
                      edge='start'
                      className={clsx(classes.menuButton)}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </>
              )}
              <Typography variant='h6' noWrap className={classes.title}>
                {event.name}: {state.title}
              </Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={state.isMobile ? state.openMenu : true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader} />
          <List>
            {state.menuItems.map((elt, index) => {
              return(
              <div key={elt.text}>
                <ListItem button onClick={()=>elt.collapse ? handleCollapse(index) : handleMenuItemChange(elt)} className={classes.menuItem}>
                  <ListItemText
                    primary={elt.text}
                  />
                  {elt.collapse && (index === selectedIndex ? <ExpandMore style={{cursor:"pointer"}} /> : <ExpandLess style={{cursor:"pointer"}}/>)}
                </ListItem>
                <Divider />
                {elt.collapse && <Collapse timeout="auto" unmountOnExit in={index === selectedIndex}>
                  {elt.collapse && elt.views.map((text, index) =><List key={index} component="div" disablePadding>
                    <ListItem button style={{paddingLeft:"30px"}} onClick={() => handleMenuItemChange(text)}>
                      <ListItemText primary={text.text}/>
                    </ListItem>
                    <Divider />
                  </List>)}
                </Collapse>}
                {index === state.menuItems.length - 1 ? null : <Divider />}
              </div>
            )})}
          </List>
        </Drawer>

        <main
          className={state.isMobile ? classes.mobileContent : classes.content}
        >
          <div className={classes.drawerHeader} />
          <div className={classes.drawerContent}>
            {state.title === 'Basic Information' && (
              <AddBasicsComponent
                event={event}
                onBasicInforChange={onBasicInforChange}
                apiCall={apiCall}
                onLaunchEvent={onLaunchEvent}
                onActivateOrStopEvent={onActivateOrStopEvent}
              />
            )}
            {state.title === 'Event Cover' && (
              <AddBannerComponent
                eventCover={event.coverPhoto}
                onBannerUpload={onBannerUpload}
                bannerUploadState={bannerUploadState}
                apiCall={apiCall}
              />
            )}
            {state.title === 'Contestants' && (
              <EventContestants
                event={event}
                onAddContestant={onAddContestant}
                apiCall={apiCall}
                onRemoveContestant={onRemoveContestant}
                editContestant={editContestant}
              />
            )}
            {state.title === 'Generate Offline Tickets' && (
              <OfflineTicketsComponent
                event={event}
                onBasicInforChange={onBasicInforChange}
                apiCall={apiCall}
                onLaunchEvent={onLaunchEvent}
                onActivateOrStopEvent={onActivateOrStopEvent}
              />
            )}
            {state.title === 'Generate Invitations' && (
              <GenerateInvitations
                event={event}
                onBasicInforChange={onBasicInforChange}
                apiCall={apiCall}
                onLaunchEvent={onLaunchEvent}
                onActivateOrStopEvent={onActivateOrStopEvent}
              />
            )}
            {state.title === 'Individual Invitation' && (
              <IndividualsInvitation
                event={event}
                onBasicInforChange={onBasicInforChange}
                apiCall={apiCall}
                onLaunchEvent={onLaunchEvent}
                onActivateOrStopEvent={onActivateOrStopEvent}
              />
            )}
            {state.title === "Offline Ticket Batches" && (
              <TicketBatches event={event}/>
            )}
            {state.title === "Download Invitations" && (
              <Invitations event={event}/>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};
