import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
// core-components
import NoContent from "../../../components/NoContent";
import CreationForm from "../../components/Wizard/Wizard";
import Step1 from "../../../components/Wizard/WizardSteps/profileWizardSteps/Step1";
import Loading from "../../../components/isLoading";
import reduxAction from "../../../redux/action";
import { GET_CLIENT_INFO } from "../../../redux/clients/types";

export default (props) => {
  const { organizationId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};

  const clientInfo = useSelector((store) => store.clientInfo);

  // const [formState, setFormState] = useState({
  //   openToast:false,
  // });

  useEffect(() => {
    if (
      !clientInfo.data.results ||
      clientInfo.data.results.id !== organizationId
    )
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${organizationId}`,
          actionType: GET_CLIENT_INFO,
        })
      );
  }, []);

  const defaultData = {
    profileStep1: {
      imageFile: clientInfo.data.results
        ? clientInfo.data.results.logoSrc
        : null,
    },
  };

  const handleFormSubmit = async (formData) => {
    // const { imageFile }=formData.profileStep1
    // const submitData =  {
    //   profileStep1:{ imageFile },
    // }
    // if(JSON.stringify(submitData)===JSON.stringify(defaultData)){
    //   return Toast({
    //     message: 'Nothing was changed',
    //     type: 'warning',
    //     onClose: () => history.push(`/profile`),
    //   });
    // }
    // const profileData = { imageFile };
    //   if (user.claims.artistId) {
    //     dispatch(updateProfile(`/artists/profile/${user.claims.artistId}`, profileData));
    //   } else if (user.claims.producerId) {
    //     dispatch(updateProfile(`/producers/profile/${user.claims.producerId}`, profileData));
    //   }
    // setFormState({ ...formState, openToast:true});
  };

  return (
    <div>
      {clientInfo.loading ? (
        <Loading />
      ) : (
        <div>
          {clientInfo.data.results ? (
            <CreationForm
              isEdit
              onSubmit={handleFormSubmit}
              title="Edit Orginaization"
              steps={[
                {
                  stepName: "Edit Organization Profile Information",
                  stepComponent: Step1,
                  stepId: "profileStep1",
                },
              ]}
              defaultData={defaultData}
              isLoading={false}
            />
          ) : (
            <NoContent
              title="Can Not Find Organization"
              path="/profile"
              pathName="Profile"
            />
          )}
        </div>
      )}
    </div>
  );
};
