import React, { useState } from "react";
import Loading from "../../../components/isLoading";
import MultiRecordsChartComponent from "../../components/stats/MultiRecordsChart";
import NoContent from "../../../components/NoContent";
import { formatChartData } from "../../../helpers/formatChartData";
import { whiteColor } from "../../../assets/styles/base";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import ViewMore from "../../../components/ViewMore";

export default (props) => {
  const {
    chartData,
    isUpdating,
    dimensions,
    dataTitle,
    showViewMore,
    viewMoreUrl,
  } = props;

  const [state, setState] = useState({
    selectedDimension: "inyarwanda.com",
    selectedSecondaryDimension: "page-views-articles",
    dataFor: "page-views-articles",
  });

  const handleDimensionChange = (selected) =>
    setState({
      ...state,
      dataFor:
        selected === "facebook"
          ? "page-impression"
          : selected === "instagram"
          ? "posts-likes-comments"
          : "page-views-articles",

      selectedDimension: selected,

      selectedSecondaryDimension:
        selected === "facebook"
          ? "page-impression"
          : selected === "instagram"
          ? "posts-likes-comments"
          : "page-views-articles",
    });

  const secondaryDimensions =
    state.selectedDimension === "facebook"
      ? [{ id: "page-impression", name: "Page Impressions" }]
      : state.selectedDimension === "instagram"
      ? [
          { id: "posts-likes-comments", name: "Post Likes & Comments" },
          {
            id: "posts-impressions-reaches",
            name: "Posts Impressions & Reach",
          },
        ]
      : [
          { id: "page-views-articles", name: "Page Views & Articles" },
          { id: "time-on-site", name: "Time On Site (min)" },
        ];

  const handleSecondaryDimensionChange = (selected) =>
    setState({
      ...state,
      selectedSecondaryDimension: selected,
      dataFor: selected,
    });

  return isUpdating ? (
    <Loading />
  ) : (
    <>
      {chartData ? (
        <div
          style={{
            background: whiteColor,
            padding: 20,
            margin: "10px 0",
          }}
        >
          <Grid container justify="flex-start">
            <MultiRecordsChartComponent
              data={formatChartData(chartData, state.dataFor)}
              width={"100%"}
              title={dataTitle}
              dimensions={dimensions}
              onDimensionChange={handleDimensionChange}
              valueOfDimension={state.selectedDimension}
              secondaryDimensions={secondaryDimensions}
              valueOfSecondaryDimension={state.selectedSecondaryDimension}
              onSecondaryDimensionChange={handleSecondaryDimensionChange}
            />
          </Grid>
          {showViewMore && chartData.website && (
            <ViewMore url={`${viewMoreUrl}`} />
          )}
        </div>
      ) : (
        <NoContent title={"No Content ."} />
      )}
    </>
  );
};
