export const advertorials = [
  {
    id: "4Cn9YrIxQvfHTbQ6Nx6K",
    categoryName: "Kwamamaza",
    campaignId: "RwandaBestEvento1640293872219",
    clientId: "inyarwanda1623310420951",
    categoryId: 57,
    summary: "cool summary",
    photoUrl:
      "https://inyarwanda.com/app/webroot/img/202201/images/inyarda61f29a4b2afd6F3235928-BB1B-45DF-9C48-6CFBE7B13220.jpeg",
    content: "<p>this is cool content</p>",
    title: "test title",
    wallPhoto:
      "202201/images/inyarda61f29a4b2afd6F3235928-BB1B-45DF-9C48-6CFBE7B13220.jpeg",
    action: "createAdvertorial",
    createdAt: "2022-01-27T13:13:05.605Z",
    status: "pending",
  },
  {
    id: "advertorial-1643534703619",
    createdAt: "2022-01-30T09:25:03.619Z",
    categoryId: 57,
    wallPhoto:
      "202201/images/inyarda61f65954a333a323757E8-169C-446F-8038-8BD8B5D7E89D.jpeg",
    advertorialRefId: "advertorial-1643534703619",
    createdBy: "firaduk@gmail.com",
    status: "pending",
    summary: "summary content",
    action: "createAdvertorial",
    campaignId: "RwandaBestEvento1640293872219",
    clientId: "inyarwanda1623310420951",
    categoryName: "Kwamamaza",
    updatedAt: "2022-01-30T10:02:07.756Z",
    photoUrl:
      "https://inyarwanda.com/app/webroot/img/202201/images/inyarda61f65954a333a323757E8-169C-446F-8038-8BD8B5D7E89D.jpeg",
    category: "advertorial",
    content:
      "<p>lorem ipsum lsdnfladgdkjfgdsbkdjffgbfdljhdkkhbfhkgfbdgjkfdhgbfhdjkbfbkjfdfsbdlgdbkjfdnvkdffvkjlvjndfvlkdsnvdkvnjldvnjdkvlndfsvlkjdfvndjvndv</p><p>lorem ipsum lsdnfladgdkjfgdsbkdjffgbfdljhdkkhbfhkgfbdgjkfdhgbfhdjkbfbkjfdfsbdlgdbkjfdnvkdffvkjlvjndfvlkdsnvdkvnjldvnjdkvlndfsvlkjdfvndjvndv</p><p>lorem ipsum lsdnfladgdkjfgdsbkdjffgbfdljhdkkhbfhkgfbdgjkfdhgbfhdjkbfbkjfdfsbdlgdbkjfdnvkdffvkjlvjndfvlkdsnvdkvnjldvnjdkvlndfsvlkjdfvndjvndv<br></p>",
    title: "test title",
  },
  {
    id: "socialMediaPost-1643548446922",
    mentions: ["@testing", "@test", "@cool"],
    category: "socialMediaPost",
    wallPhoto:
      "202201/images/inyarda61f68f196561f32CEA149-37AA-4936-A34E-71A14523A8A5.jpeg",
    advertorialRefId: "socialMediaPost-1643548446922",
    tags: ["#devs", "#react", "#javascript"],
    status: "pending",
    summary: "All is in my mind",
    action: "createSocialMediaPost",
    photoUrl:
      "https://inyarwanda.com/app/webroot/img/202201/images/inyarda61f68f196561f32CEA149-37AA-4936-A34E-71A14523A8A5.jpeg",
    campaignId: "RwandaBestEvento1640293872219",
    createdAt: "2022-01-30T13:14:06.922Z",
    clientId: "inyarwanda1623310420951",
    createdBy: "firaduk@gmail.com",
  },
  {
    id: "socialMediaPost-1643548575620",
    category: "socialMediaPost",
    tags: ["#kldjdj", "#uuuu"],
    action: "createSocialMediaPost",
    mentions: ["@sdjddjd", "@klkl"],
    campaignId: "RwandaBestEvento1640293872219",
    photoUrl:
      "https://inyarwanda.com/app/webroot/img/202201/images/inyarda61f68f9b5c4563296EC7C-DC86-45E8-9B90-B0B42B17C466.jpeg",
    createdBy: "firaduk@gmail.com",
    clientId: "inyarwanda1623310420951",
    advertorialRefId: "socialMediaPost-1643548575620",
    summary: "jkfanlgkdsansd",
    createdAt: "2022-01-30T13:16:15.620Z",
    status: "pending",
    wallPhoto:
      "202201/images/inyarda61f68f9b5c4563296EC7C-DC86-45E8-9B90-B0B42B17C466.jpeg",
  },
  {
    id: "zqKxprmVcg6m3lHwspaK",
    title: "Rwanda Best Event of 2021  - Advertorial",
    summary:
      "This is a default advertorial generated, you can start by edittin it",
    categoryId: 57,
    createdAt: "2021-12-23T21:11:12.832Z",
    categoryName: "Kwamamaza",
  },
];

export const banners = [
  {
    id: "9yM2LfPfpL4S7rKFRl9R",
    status: "pending",
    link: "dsldksdlsdksld",
    updatedAt: "2022-01-27T19:25:00.621Z",
    action: "edit",
    caption: "slkdsldksl",
    createdBy: "firaduk@gmail.com",
    clientId: "inyarwanda1623310420951",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f184dd64eF28A66B1-9E5A-4E70-90B8-9FFDB87AFEA1.jpeg",
    createdAt: "2022-01-27T17:20:57.037Z",
  },
  {
    id: "CNLAAvoWDJhZmkomTh3L",
    updatedAt: "2022-01-27T19:25:55.567Z",
    link: "meme.org",
    clientId: "inyarwanda1623310420951",
    caption: "meme banner",
    status: "pending",
    createdBy: "firaduk@gmail.com",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f1bbd94e8F66682A5-6FFD-4EC4-B2FD-90A520AF9753.jpeg",
    action: "edit",
    createdAt: "2022-01-27T13:10:59.383Z",
  },
  {
    id: "banner-1643537125732",
    caption: "super human 3point shooter",
    file: null,
    action: "createBanner",
    link: "curry.com",
    campaignId: "RwandaBestEvento1640293872219",
    clientId: "inyarwanda1623310420951",
    bannerRefId: "banner-1643537125732",
    category: "banner",
    createdBy: "firaduk@gmail.com",
    path: null,
    createdAt: "2022-01-30T10:05:25.732Z",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f662db78ac236A5DA18-F00C-4030-A41C-6FBA29C7DFF5.jpeg",
    status: "pending",
    updatedAt: "2022-02-05T13:30:13.031Z",
  },
  {
    id: "banner-1643564052279",
    link: "memes.africa.com",
    updatedAt: "2022-01-30T17:36:16.778Z",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f6cbfd1ad293DC02BAC-568B-404B-BB30-04C498417492.jpeg",
    category: "banner",
    file: null,
    action: "createBanner",
    clientId: "inyarwanda1623310420951",
    createdBy: "firaduk@gmail.com",
    campaignId: "RwandaBestEvento1640293872219",
    createdAt: "2022-01-30T17:34:12.279Z",
    bannerRefId: "banner-1643564052279",
    caption: "african cool meme",
    path: null,
    status: "pending",
  },
  {
    id: "9yM2LfPfpL4S7rKFRl9R",
    caption: "slkdsldksl",
    clientId: "inyarwanda1623310420951",
    campaignId: "RwandaBestEvento1640293872219",
    status: "pending",
    link: "dsldksdlsdksld",
    bannerRefId: "banner-1644066439110",
    action: "edit",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f184dd64eF28A66B1-9E5A-4E70-90B8-9FFDB87AFEA1.jpeg",
    createdBy: "firaduk@gmail.com",
    updatedAt: "2022-01-27T19:25:00.621Z",
    createdAt: "2022-02-05T13:07:19.110Z",
  },
  {
    id: "9yM2LfPfpL4S7rKFRl9R",
    bannerRefId: "banner-1644066475571",
    link: "dsldksdlsdksld",
    action: "edit",
    campaignId: "RwandaBestEvento1640293872219",
    clientId: "inyarwanda1623310420951",
    createdAt: "2022-02-05T13:07:55.571Z",
    caption: "slkdsldksl",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f184dd64eF28A66B1-9E5A-4E70-90B8-9FFDB87AFEA1.jpeg",
    updatedAt: "2022-01-27T19:25:00.621Z",
    createdBy: "firaduk@gmail.com",
    status: "pending",
  },
  {
    id: "banner-1644066579190",
    status: "pending",
    createdAt: "2022-02-05T13:09:39.190Z",
    clientId: "inyarwanda1623310420951",
    category: "banner",
    updatedAt: "2022-02-05T13:30:23.068Z",
    caption: "slkdsldksl",
    action: "edit",
    createdBy: "firaduk@gmail.com",
    link: "dsldksdlsdksld",
    path: null,
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f184dd64eF28A66B1-9E5A-4E70-90B8-9FFDB87AFEA1.jpeg",
    bannerRefId: "banner-1644066579190",
    campaignId: "RwandaBestEvento1640293872219",
    file: null,
  },
  {
    id: "9yM2LfPfpL4S7rKFRl9R",
    createdBy: "firaduk@gmail.com",
    action: "edit",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f184dd64eF28A66B1-9E5A-4E70-90B8-9FFDB87AFEA1.jpeg",
    status: "pending",
    link: "dsldksdlsdksld",
    campaignId: "RwandaBestEvento1640293872219",
    clientId: "inyarwanda1623310420951",
    createdAt: "2022-02-06T13:15:30.815Z",
    bannerRefId: "banner-1644153330815",
    updatedAt: "2022-01-27T19:25:00.621Z",
    caption: "slkdsldksl",
  },
  {
    id: "9yM2LfPfpL4S7rKFRl9R",
    createdBy: "firaduk@gmail.com",
    action: "edit",
    status: "pending",
    caption: "slkdsldksl",
    updatedAt: "2022-01-27T19:25:00.621Z",
    bannerRefId: "banner-1644153399746",
    url: "https://storage.inyarwanda.com/storage/images/Rwanda/banners/202201/inyarda61f2f184dd64eF28A66B1-9E5A-4E70-90B8-9FFDB87AFEA1.jpeg",
    campaignId: "RwandaBestEvento1640293872219",
    createdAt: "2022-02-06T13:16:39.746Z",
    clientId: "inyarwanda1623310420951",
    link: "dsldksdlsdksld",
  },
];
