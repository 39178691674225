import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Table from "../../../components/Table/Table";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import styles from "../../../assets/styles/components/chartStyle";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import Loading from "../../../components/isLoading";
import NoContent from "../../../components/NoContent";
class MapsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      mapData: this.props.mapData,
    };
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.mapData !== prevProps.mapData) {
      this.setState({ ...this.state, mapData: this.props.mapData });
    }
  }

  getData = (key) => {
    const countryData = [];
    this.state.mapData.forEach(function (obj) {
      countryData[obj.code] = obj.value;
    });
    return countryData[key];
  };

  getAllData = () => {
    const countryData = [];
    this.state.mapData.forEach(function (obj) {
      countryData[obj.code] = obj.value;
    });
    return countryData;
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleshow2 = (e, el, code) => {
    const countryData = this.getData(code);
    el.html(el.html() + ` <br> Impressions: ${this.getData(code) || 0}`);
  };

  render() {
    const {
      classes,
      topCountries,
      title,
      dimensions,
      valueOfDimension,
      onDimensionChange,
      secondaryDimensions,
      onSecondaryDimensionChange,
      valueOfSecondaryDimension,
      isUpdating,
    } = this.props;

    return (
      <div>
        <GridContainer style={{ paddingLeft: 10 }}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <div className={classes.headContainer}>
                  <Typography className={classes.title}> {title} </Typography>
                  <span className={classes.dimensionSelector}>
                    {dimensions && (
                      <HeaderSelect
                        hasSecondary
                        onChangeMenu={(selected) => onDimensionChange(selected)}
                        value={valueOfDimension}
                        headerMenus={dimensions}
                        onChangeSecondaryMenu={(selected) =>
                          onSecondaryDimensionChange(selected)
                        }
                        secondaryValue={valueOfSecondaryDimension}
                        secondaryHeaderMenus={secondaryDimensions}
                      />
                    )}
                  </span>
                </div>
                <br />
                {isUpdating ? (
                  <Loading />
                ) : (
                  <GridContainer justify="space-between">
                    {this.state.mapData ? (
                      <>
                        <GridItem xs={12} sm={12} md={5}>
                          <Table tableData={topCountries} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <VectorMap
                            map={"world_mill"}
                            backgroundColor="transparent"
                            zoomOnScroll={false}
                            containerStyle={{
                              width: "100%",
                              height: "280px",
                            }}
                            containerClassName="map"
                            regionStyle={{
                              initial: {
                                fill: "#e4e4e4",
                                "fill-opacity": 0.9,
                                stroke: "none",
                                "stroke-width": 0,
                                "stroke-opacity": 0,
                              },
                            }}
                            series={{
                              regions: [
                                {
                                  values: this.getAllData(),
                                  scale: ["#AAAAAA", "#444444"],
                                  normalizeFunction: "polynomial",
                                },
                              ],
                            }}
                            onRegionTipShow={this.handleshow2}
                          />
                        </GridItem>
                      </>
                    ) : (
                      <NoContent title={"No Content"} />
                    )}
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

MapsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapsContainer);
