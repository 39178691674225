import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";
import CheckBox from "@material-ui/core/CheckBox";
import Typography from "@material-ui/core/Typography";
import DownArrow from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialogs/CustomDialog";
import { primaryColor } from "../../../assets/styles/base";
import CustomInput from "../../../components/CustomInput/TextField";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	downArrow: {
		"&.MuiSvgIcon-root": {
			marginBottom: "-7px",
		},
	},
	display: {
		padding: 10,
		"&:hover": {
			cursor: "pointer",
		},
	},
	checkBox: {
		"&.Mui-checked": {
			color: primaryColor[0],
		},
	},
	listItem: {
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "rgba(0,0,0,0.02)",
		},
	},
}));

export default ({ organizations, onOrgChange }) => {
	const classes = useStyles();
	const { userOrg } = JSON.parse(localStorage.getItem("au")).claims;
	const [state, setstate] = useState({
		open: false,
		displayOrgs: organizations,
		selectedOrg: { ...userOrg, id: userOrg.orgId },
	});

	useEffect(() => {
		setstate((prev) => ({ ...prev, displayOrgs: organizations }));
	}, [organizations]);

	const onSelectClick = () => {
		setstate({
			...state,
			open: !state.open,
		});
	};
	const onCancel = () => {
		setstate({
			...state,
			open: !state.open,
			displayOrgs: organizations,
		});
	};
	const onConfirm = () => {
		setstate({
			...state,
			open: !state.open,
		});
		onOrgChange(state.selectedOrg);
	};

	const onSelectionChange = (selected) => {
		setstate({
			...state,
			selectedOrg: selected,
		});
	};

	const onSearch = ({ target: { value } }) => {
		const searchResult = organizations.filter((item) =>
			item.name.toLowerCase().includes(value.toLowerCase())
		);
		setstate((prev) => ({ ...prev, displayOrgs: searchResult }));
	};

	return (
		<div className={classes.display}>
			<Typography
				onClick={onSelectClick}
				noWrap
				style={{ borderBottom: "2px solid black" }}
			>
				{state.selectedOrg.name}
				<span style={{ marginTop: 10, marginLeft: 10 }}>
					<DownArrow alignmentBaseline="bottom" className={classes.downArrow} />
				</span>
			</Typography>
			<Dialog
				open={state.open}
				title={"Select default organization"}
				fullWidth
				onCancel={onCancel}
				onConfirm={onConfirm}
				okText={"Change"}
				content={
					<List>
						<ListItem>
							<Box style={{ width: "100%" }}>
								<CustomInput
									fullWidth
									label="Type to search"
									onChange={onSearch}
								/>
							</Box>
						</ListItem>
						{state.displayOrgs.map((org) => (
							<ListItem
								key={org.id}
								className={classes.listItem}
								onClick={() => onSelectionChange(org)}
								disabled={state.selectedOrg.id !== org.id}
							>
								<ListItemIcon>
									<CheckBox
										className={classes.checkBox}
										checked={state.selectedOrg.id === org.id}
									/>
								</ListItemIcon>
								<ListItemText primary={org.name} />
							</ListItem>
						))}
					</List>
				}
				maxWidth="md"
			/>
		</div>
	);
};
