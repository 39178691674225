import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import "date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { primaryColor } from "../../../assets/styles/base";
import "../../../assets/styles/plugins/calendar.css";
import { Grid, List, ListItem, ListItemText, ListItemSecondaryAction, Tooltip } from "@material-ui/core";
import Toast from "../../../components/MessageToast";
import fullUrlApiService from "../../../services/fullUrlService";
import TextField from "@material-ui/core/TextField";
import PulseLoader from "react-spinners/PulseLoader";
import { GENERATE_INVITATIONS } from "../../../redux/events/types";
import Remove from "@material-ui/icons/RemoveCircle";

const userStyles = makeStyles((theme) => ({
  card: {
    padding: 10,
    width: "100%",
  },
  invitationContainer: {
    width: "100%",
    '@media (max-width:600px)': {
      flexDirection: "column",
      width: "100%"
    }
  },
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },
  formContainer: {
    width: '50%',
    '@media (max-width: 1025px)': {
      width: '94%',
      margin: "10px 10px"
    },
    '@media (max-width: 600px)': {
      width: '89%',

    }
  },
  formList: {
    width: '50%',
    '@media (max-width: 1025px)': {
      width: '100%',
      margin: "10px 10px"
    },
    '@media (max-width: 600px)': {
      width: '100%',

    }
  },
  formField: {
    width: "45%",
    margin: "10px 10px",
    '@media (max-width: 1025px)': {
      width: '94%',
      margin: "10px 10px"
    },
    '@media (max-width: 600px)': {
      width: "94%",
      margin: "10px 0px",
    }
  },
  checkBox: {
    "&.Mui-checked": {
      color: primaryColor[0],
    },
  },
  TicketsInput: {
    display: "flex",
    width: "100%",
    "@media(max-width:1300px)": {
      flexDirection: "column",
      margin: "10px 0px 10px 0px",
      width: "100%",
    },
  },
  listItem: {
    paddingRight: "0px",
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  childList: {
    paddingRight: "0px",
    paddingLeft: "0px",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  Itemcontainer: {
    maxWidth: "85%",
    "@media(max-width: 600px)": {
      maxWidth: "100%",
    },
  },
  batchesList: {
    width: "500px",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  actions: {
    display: "block",
    "@media(max-width:600px)": {
      display: "none",
    },
  },
}));

export default ({ event, apiCall }) => {
  const classes = userStyles();
  const [state, setState] = useState({
    loading: false,
  });
  const [formData, setFormData] = useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    setFormData([...formData, data]);
    reset();
  }
  const handleGenerate = async () => {
    const dataValues = {
      eventInfo: {
        eventName: event.name,
        eventId: event.id,
        eventPhoto: event.coverPhoto,
        eventTime: new Date(event.startDate).toLocaleTimeString(),
        eventDate: event.startDate,
        organizer: event.organizer,
        eventDescription: event.description,
        eventVenue: event.venue,
      },
      invitees: formData
    };
    setState(prev => ({ ...prev, loading: true }))
    const response = await fullUrlApiService({
      method: "POST",
      path: `${process.env.API_BASE_URL}/events/generate_invitations`,
      data: dataValues,
      actionType: GENERATE_INVITATIONS
    });

    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        setFormData([]);
      },
    });
  }
  const [open, setOpen] = React.useState(false);


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function removeInvitee(index) {
    setFormData(formData.filter((_, i) => i !== index));
  }

  return (
    <Card className={classes.card}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "10px" }}
      >
        <Grid item>
          <Typography variant="h6" align="start">
            Generate an Individual Invitation
          </Typography>
        </Grid>
      </Grid>
      <br></br>
      <Grid container style={{ width: "100%" }} className={classes.invitationContainer}>
        <Box className={classes.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box style={{ width: "100%", margin: "10px 10px" }}  >
              <TextField
                {...register(`firstName`, { required: true })}
                error={!!errors[`firstName`]}
                helperText={errors[`firstName`] && "FIrst Name is required"}
                variant="outlined"
                className={classes.formField}
                label="First Name *"
              />
              <TextField
                {...register(`lastName`, { required: true })}
                error={!!errors[`lasName`]}
                helperText={errors[`lastName`] && "Last Name is required"}
                variant="outlined"
                className={classes.formField}
                label="Last Name *"
              />
              <TextField
                {...register(`email`, { required: false })}
                error={!!errors[`email`]}
                helperText={errors[`email`] && "Email is required"}
                variant="outlined"
                className={classes.formField}
                label="email"
              />
              <TextField
                {...register(`phone`, { required: true, pattern: /^[0-9]{10}$/ })}
                error={!!errors[`phone`]}
                helperText={errors[`phone`] && "Please enter a valid 10-digit phone number"}
                variant="outlined"
                className={classes.formField}
                label="Phone Number *"
              />
              <TextField
                {...register(`organization`, { required: false })}
                error={!!errors[`organization`]}
                helperText={errors[`organization`] && "Organization is required"}
                variant="outlined"
                style={{ width: "94%", margin: "10px 0px" }}
                label="Organization"
              />
            </Box>
            <Grid item style={{ margin: "10px 20px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.createButton}
                disabled={state.loading || apiCall.loading}
              >
                Add
              </Button>
            </Grid>
          </form>
        </Box>
        <Box className={classes.formList}>
          {formData.map((data, index) => (
            <List key={index}>
              <ListItem style={{ display: "flex", alignItems: "start" }}>
                <Typography style={{ marginTop: "12px", marginRight: "7px", fontSize: "15px" }}>{index + 1}.</Typography>
                <ListItemText primary={<Typography variant="h6">{data.firstName} {data.lastName}
                </Typography>} secondary={<React.Fragment>
                  <Typography variant="body2">phone: {data.phone}</Typography>
                  <Typography >organization: {data.organization ? data.organization : " Individual"}</Typography>
                </React.Fragment>} />
                <ListItemSecondaryAction
                  onClick={() => removeInvitee(index)}
                >
                  <Tooltip title={`Remove invitee`} arrow>
                    <Remove fontSize="small" color="action" />
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
          {formData.length > 0 && <Grid item style={{ margin: "10px 20px", textAlign: "end" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleGenerate}
              className={classes.createButton}
              disabled={state.loading || apiCall.loading}
            >

              {state.loading ? (
                <PulseLoader
                  size={10}
                  margin={3}
                  color={"#fff"}
                  loading={state.loading}
                />
              ) : (
                "Generate Invitation(s)"
              )}
            </Button>
          </Grid>}
        </Box>
      </Grid>
    </Card>
  );
};
