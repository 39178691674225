import React, { useEffect, useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { PulseLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";
import { Box, IconButton, styled, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MobilePaymentForm from "./MobileForm";
import Toast from "../../../components/MessageToast";
import VisaForm from "./VisaForm";
import PaymentMethods from "./PaymentMethods";
import apiService from "../../../services/apiService";
import { useWindowDimensions } from "../../../helpers/helperMethods";
import fullUrlService from "../../../services/fullUrlService";
import { primaryColor } from "../../../assets/styles/base";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    padding: "5vh 5%",
    height: "fit-content",
    backgroundColor: "#fff",
    borderRadius: "6px",
    outline: "none",
    "@media (min-width: 1000px)": {
      width: "55%",
    },
    "@media (max-width: 600px)": {
      width: "80%",
      height: "fit-content",
      padding: "10vh 5%",
    },
  },
}));

const submitBtnStyle = {
  padding: "15px 0",
  width: "50%",
  backgroundColor: "#FF9800",
  color: "#fff",
  display: "inline",
  "&:hover": {
    background: "#FF9800",
    cursor: "pointer",
  },

  "@media (max-width: 600px)": {
    padding: "10px 0",
    width: "100%",
  },
};

const normalDivStyle = {
  padding: "10px 0",
  width: "100%",
  textAlign: "center",
};

export default ({
  handleClose,
  onPaymentConfirm,
  formError,
  paymentConfirmationStore,
  onOtpSubmit,
  paymentOptions,
  content,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    phone: "",
    amount: 0,
    formError: "",
    otp: "",
    isCardForm: false,
    choosePaymentMethod: true,
    paymentMethod: null,
    currency: "RWF",
    email: "",
    isChecking: false,
    verificationInfo: {},
    loading: false,
    visaPaymentComplete: false,
    tx_ref: "",
    paymentInitialized: false,
  });

  const showOtp =
    paymentConfirmationStore.payment_data.results &&
    paymentConfirmationStore.payment_data.results.charge.status === "success";

  useEffect(() => {
    if (paymentOptions.price) {
      setState((prevState) => ({ ...prevState, amount: paymentOptions.price }));
    }
    if (paymentConfirmationStore.payment_data.results && !state.tx_ref) {
      setState({
        ...state,
        tx_ref:
          paymentConfirmationStore.payment_data.results.transaction.tx_ref,
      });
    }
  }, [paymentOptions, paymentConfirmationStore.payment_data]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, formError: formError || "" }));
  }, [formError]);

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;

    if (name) {
      return setState({ ...state, [name]: value, formError: "" });
    }

    setState({ ...state, [id]: value, formError: "" });
  };

  const handlePhoneChange = (phone) =>
    setState({ ...state, phone, formError: "" });

  const onClosingModal = () => {
    setState({
      ...state,
      phone: "",
      amount: 0,
      formError: "",
      otp: "",
      isCardForm: false,
      choosePaymentMethod: true,
      paymentMethod: null,
      currency: "RWF",
      email: "",
      tx_ref: "",
      visaPaymentComplete: false,
      paymentInitialized: false,
    });
    handleClose();
  };

  const initializePayment = async (paymentMethod) => {
    const { amount, currency } = state;
    const payload = {
      clientId: content.clientId,
      campaignId: content.campaignId,
      adId: content.id || content.adId,
      adType: content.adType,
    };

    const paymentData = {
      type: "ads",
      amount: amount,
      method: paymentMethod,
      currency,
      id: content.id || content.adId,
      payload,
    };

    const response = await fullUrlService({
      path: `${process.env.PAYMENT_API}/transactions`,
      method: "POST",
      data: paymentData,
    });

    if (!response.results) {
      return "";
    }
    return response.results.transaction.tx_ref;
  };

  if (
    state.paymentMethod === "visa" &&
    !state.tx_ref &&
    !state.paymentInitialized
  ) {
    setState({ ...state, paymentInitialized: true });
    Promise.resolve(initializePayment("card")).then((elt) => {
      if (!state.tx_ref) {
        setState({ ...state, tx_ref: elt });
      }
    });
  }

  const config = {
    public_key: "FLWPUBK-baf926e6ddfa3283a6d724a10598627b-X",
    tx_ref: state.tx_ref,
    amount: state.amount,
    currency: state.currency,
    payment_options: "card",
    country: "RW",
    customer: {
      email: state.email,
      phonenumber: "",
      name: `PaymentId: ${Math.round(Math.random() * 10000000)}`,
    },
    customizations: {
      title: "Noneho.com | Payment For Ad",
      description: "Choose the payment method",
      logo: "https://demand.noneho.com/assets/img/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const onFormSubmit = async () => {
    const { phone, amount, otp, email, paymentMethod } = state;

    if (showOtp && phone) {
      return onOtpSubmit(otp);
    }

    if (
      (paymentMethod === "mobile" && (amount === 0 || phone === "")) ||
      (paymentMethod === "visa" && (amount === 0 || email === ""))
    ) {
      return setState({
        ...state,
        formError: `Please Make Sure ${
          paymentMethod === "mobile" && !phone ? "Phone Number" : "Email"
        } is filled`,
      });
    }

    if (phone) {
      if (phone.length < 12) {
        return setState({
          ...state,
          formError: `Please Make Sure all The phone number written correctly`,
        });
      }
      const data = { amount, phone: `+${phone}`, currency: state.currency };
      return onPaymentConfirm(data);
    }
    if (email && (!email.includes("@") || !email.includes("."))) {
      return setState({
        ...state,
        formError: `Please Make Sure all The email is written correctly`,
      });
    }

    if (!state.tx_ref) {
      return Toast({
        message:
          "Unable to get Payment Reference please restart the payment process",
        type: "error",
        onClose: () => window.location.reload(),
      });
    }

    handleFlutterPayment({
      callback: () => {
        closePaymentModal();
        setState({ ...state, visaPaymentComplete: true });
      },
      onClose: () => {
        closePaymentModal();
        onClosingModal();
      },
    });
  };

  const onChoosePaymentMethod = (method) => {
    setState({ ...state, paymentMethod: method });
  };

  const { width } = useWindowDimensions();

  const checkPayment = async () =>
    await fullUrlService({
      method: "GET",
      path: `${process.env.PAYMENT_API}/transactions/${state.tx_ref}`,
    });

  if (
    (paymentConfirmationStore.otp_data.status === 200 ||
      state.visaPaymentComplete) &&
    !state.isChecking &&
    !state.verificationInfo.status
  ) {
    setState({ ...state, isChecking: true });

    let checking = {};

    const myInterval = setInterval(async () => {
      checking = await checkPayment();
      const status = (checking.results && checking.results.status) || "";

      if (status === "successful") {
        clearInterval(myInterval);
        setState({
          ...state,
          isChecking: false,
          verificationInfo: checking.results,
          visaPaymentComplete: false,
        });
        onClosingModal();

        Toast({
          message: checking.message,
          type: checking.error ? "error" : "success",
          onClose: () => {
            if (!checking.error) {
              history.push("/ads");
            }
          },
        });
      }

      if (status.includes("fail")) {
        clearInterval(myInterval);
        setState({
          ...state,
          isChecking: false,
          verificationInfo: checking.results,
          visaPaymentComplete: false,
        });
        onClosingModal();
        Toast({
          message: checking.message,
          type: "error",
          onClose: () => {
            window.location.reload();
          },
        });
      }
    }, 5000);
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="Close"
        onClick={onClosingModal}
        style={{
          float: "right",
          margin: width > 600 ? "-3vh -3%" : "-8vh -3%",
        }}
      >
        <CloseIcon />
      </IconButton>
      <div>
        {!state.paymentMethod ? (
          <PaymentMethods onChoosePaymentMethod={onChoosePaymentMethod} />
        ) : (
          <div>
            <Typography variant={width > 600 ? "h4" : "h5"}>
              Ad Payment
            </Typography>
            <br />
            {state.isChecking ? (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Typography variant="subtitle1">
                  Please check your phone and follow the next instructions, Your
                  payment is being processed, we will notify you once done!
                </Typography>
                <CircularProgress
                  style={{
                    display: "inline-block",
                    padding: 30,
                    color: primaryColor[0],
                  }}
                />
              </div>
            ) : state.paymentMethod === "mobile" ? (
              <MobilePaymentForm
                onInputChange={handleInputChange}
                onPhoneChange={handlePhoneChange}
                content={state}
                formError={state.formError}
                disabled={
                  paymentConfirmationStore.loading ||
                  !!paymentConfirmationStore.payment_data.results
                }
                showOtp={showOtp}
                otpData={paymentConfirmationStore.otp_data}
                paymentOptions={paymentOptions.momoOptions || paymentOptions}
              />
            ) : state.paymentMethod === "visa" && !state.tx_ref ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  style={{
                    display: "inline-block",
                    padding: 30,
                    color: primaryColor[0],
                  }}
                />
              </div>
            ) : (
              <VisaForm
                onInputChange={handleInputChange}
                onPhoneChange={handlePhoneChange}
                content={state}
                formError={state.formError}
                disabled={
                  paymentConfirmationStore.loading ||
                  !!paymentConfirmationStore.payment_data.result
                }
                paymentOptions={paymentOptions.cardOptions || paymentOptions}
              />
            )}
            <div style={normalDivStyle}>
              {!state.isChecking && (
                <Button
                  variant="contained"
                  onClick={onFormSubmit}
                  style={submitBtnStyle}
                  disabled={
                    paymentConfirmationStore.loading ||
                    state.isChecking ||
                    (state.paymentMethod === "visa" && !state.tx_ref)
                  }
                >
                  {paymentConfirmationStore.loading || state.loading ? (
                    <PulseLoader
                      size={5}
                      margin={3}
                      color={"#fff"}
                      loading={paymentConfirmationStore.loading}
                    />
                  ) : showOtp && state.phone ? (
                    "Submit OTP"
                  ) : (
                    "Proceed With Payment"
                  )}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
