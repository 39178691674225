import React, { useEffect, useState } from "react";
import Loading from "../../../components/isLoading";
import MapComponent from "../../components/map/map";
import NoContent from "../../../components/NoContent";
import { useSelector, useDispatch } from "react-redux";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER_COUNTRY_INFO } from "../../../redux/publishers/types";
import { smallTableDataFormat } from "../../../helpers/formatChartData";
import ViewMore from "../../../components/ViewMore";

/* Resources I used: 
  https://28arpit2000sharma.medium.com/react-india-map-react-any-country-map-c6047ff71876
  https://jvectormap.com/documentation/
  https://codesandbox.io/examples/package/react-jvectormap
*/

export default (props) => {
  const { selectedPublisher, startDate, endDate, showViewMore, viewMoreUrl } =
    props;
  const dimensions = [
    { id: "inyarwanda.com", name: "Inyarwanda.com" },
    { id: "facebook", name: "Facebook" },
  ];

  const dispatch = useDispatch();

  const publisherCountryInfoStore = useSelector(
    (store) => store.publisherCountryInfo
  );

  const [state, setState] = useState({
    selectedDimension: { name: "inyarwanda.com", identifier: "web" },
    selectedSecondaryDimension: "page-views-articles",
    dataFor: "page-views-articles",
  });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=countries&&startDate=${startDate}&endDate=${endDate}`,
        actionType: FETCH_PUBLISHER_COUNTRY_INFO,
      })
    );
  }, [selectedPublisher, state.selectedDimension.identifier, startDate]);

  const topCountries = publisherCountryInfoStore.data.results
    ? smallTableDataFormat(
        publisherCountryInfoStore.data,
        state.selectedSecondaryDimension,
        5
      )
    : [];

  const handleDimensionChange = (selected) =>
    setState((prevState) => ({
      ...state,
      selectedDimension: {
        name: selected,
        identifier:
          prevState.selectedDimension.identifier === "fb" ? "web" : "fb",
      },
      selectedSecondaryDimension:
        selected === "facebook" ? "page-impression" : "page-views-articles",
    }));

  const secondaryDimensions =
    state.selectedDimension.name === "facebook"
      ? [{ id: "page-impression", name: "Page Impressions" }]
      : [
          { id: "page-views-articles", name: "Page Views & Articles" },
          { id: "time-on-site", name: "Time On Site (min)" },
        ];

  const handleSecondaryDimensionChange = (selected) =>
    setState({
      ...state,
      selectedSecondaryDimension: selected,
      dataFor: selected,
    });

  return publisherCountryInfoStore.loading &&
    !publisherCountryInfoStore.data.results ? (
    <Loading />
  ) : (
    <>
      <MapComponent
        mapData={smallTableDataFormat(
          publisherCountryInfoStore.data.results
            ? publisherCountryInfoStore.data
            : [],
          state.selectedSecondaryDimension
        )}
        topCountries={topCountries}
        title={" Global Performance "}
        dimensions={dimensions}
        valueOfDimension={state.selectedDimension.name}
        onDimensionChange={handleDimensionChange}
        secondaryDimensions={secondaryDimensions}
        valueOfSecondaryDimension={state.selectedSecondaryDimension}
        onSecondaryDimensionChange={handleSecondaryDimensionChange}
        isUpdating={
          publisherCountryInfoStore.loading &&
          publisherCountryInfoStore.data.results
        }
      />
      {showViewMore && publisherCountryInfoStore.data.results && (
        <ViewMore url={`${viewMoreUrl}`} />
      )}
    </>
  );
};
