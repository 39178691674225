import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "../views/pages/Dashboard";
import componentAllowed from "../helpers/authorization/componentAllowed";
import NotAllowedLayout from "../layouts/NoAllowedFB";
import { ToastContainer } from "react-toastify";
import ClientsPage from "../views/pages/ClientsPage";
import SettingsPage from "../views/pages/SettingsPage";
import TrafficPage from "../views/pages/performance/Traffic";
import TechnologyPage from "../views/pages/performance/Technology";
import AudiencePage from "../views/pages/performance/Audience";
import CampaignsPage from "../views/pages/campaigns/Campaigns";
import ProfilePage from "../views/pages/profile/ProfilePage";
import EditOrganizationPage from "../views/pages/profile/editOrganizationPage";
import CreateCampaignPage from "../views/pages/campaigns/CreateCampaigns";
import EditCampaignPage from "../views/pages/campaigns/EditCampaignPage";
import EventsPage from "../views/pages/events/EventsPage";
import CreateEventPage from "../views/pages/events/CreateEventPage";
import EditEventPage from "../views/pages/events/EditEventPage";
import CreateAds from "../views/pages/ads/CreateAdsPage";
import Advertisements from "../views/pages/ads/Advertisements";
import EditAdvert from "../views/pages/ads/EditAdsPage";
import MediaCreatives from "../views/pages/MediaCreativesPage";

const ProtectedRoute = ({
  component: Component,
  roles,
  accessLevel,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        componentAllowed(roles, accessLevel) ? (
          <Component {...props} />
        ) : (
          <NotAllowedLayout />
        )
      }
    />
  );
};

const AppRoute = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <ToastContainer />
        <Switch>
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/"
            component={Dashboard}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin"]}
            exact
            path="/orgs"
            component={ClientsPage}
          />
          <ProtectedRoute
            roles={["owner", "admin"]}
            exact
            path="/orgs/:clientId"
            component={ClientsPage}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin"]}
            exact
            path="/settings"
            component={SettingsPage}
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/traffic"
            component={TrafficPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/technology"
            component={TechnologyPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/audience"
            component={AudiencePage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaigns"
            component={CampaignsPage}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaign/:campaignId"
            component={CampaignsPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaigns/new"
            component={CreateCampaignPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaigns/:campaignId/edit"
            component={EditCampaignPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaigns/pending"
            component={CampaignsPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events"
            component={EventsPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/new"
            component={CreateEventPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/pending"
            component={EventsPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/:eventId/edit"
            component={EditEventPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/:eventId"
            component={EventsPage}
            accessLevel="org"
          />

          <ProtectedRoute exact path="/profile" component={ProfilePage} />
          <ProtectedRoute
            exact
            path="/organization/edit/:id"
            component={EditOrganizationPage}
            accessLevel="org"
          />
          <ProtectedRoute
            exact
            path="/ads/new"
            component={CreateAds}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/ads"
            component={Advertisements}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaign/:campaignId/ads/:advertId"
            component={Advertisements}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/campaign/:campaignId/ads/:advertId/edit"
            component={EditAdvert}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/media-creatives"
            component={MediaCreatives}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/media-creatives/:creativeId"
            component={MediaCreatives}
            accessLevel="org"
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default AppRoute;
