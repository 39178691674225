import React, { useEffect, useState } from "react";
import Loading from "../../../components/isLoading";
import MultiRecordsDoughnutChartComponent from "../../components/stats/MultiRecordsDoughnutChart";
import NoContent from "../../../components/NoContent";
import { formatChartData } from "../../../helpers/formatChartData";
import { findPercentage } from "../../../helpers/helperMethods";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { whiteColor } from "../../../assets/styles/base";

import MultiRecordsChartComponent from "../../components/stats/MultiRecordsChart";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import GridContainer from "../../../components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/styles/components/chartStyle";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER_TECHNOLOGY_INFO } from "../../../redux/publishers/types";
// import ViewMore from "../../../components/ViewMore";

const useStyles = makeStyles(styles);

export default (props) => {
  const classes = useStyles();
  const {
    showViewMore,
    dataTitle,
    selectedPublisher,
    startDate,
    endDate,
    chartWidth,
    viewMoreUrl,
  } = props;
  const dimensions = [{ id: "inyarwanda.com", name: "Inyarwanda.com" }];

  const technologyInfoStore = useSelector((store) => store.technologyInfo);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    selectedDimension: { name: "inyarwanda.com", identifier: "web" },
    selectedSecondaryDimension: "page-views-articles",
    dataFor: "page-views-articles",
  });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=technology&&startDate=${startDate}&endDate=${endDate}`,
        actionType: FETCH_PUBLISHER_TECHNOLOGY_INFO,
      })
    );
  }, [selectedPublisher, state.selectedDimension.identifier, startDate]);

  const handleDimensionChange = (selected) => {
    setState((prevState) => ({
      ...state,
      selectedDimension: {
        name: selected,
        identifier:
          prevState.selectedDimension.identifier === "fb" ? "web" : "fb",
      },
    }));
  };

  const secondaryDimensions =
    state.selectedDimension.name === "facebook"
      ? [{ id: "page-impression", name: "Page Impressions" }]
      : [
          { id: "page-views-articles", name: "Page Views & Articles" },
          { id: "visits", name: "Visits" },
        ];

  const onSecondaryDimensionChange = (selected) =>
    setState({
      ...state,
      selectedSecondaryDimension: selected,
      dataFor: selected,
    });

  let data = {};
  if (technologyInfoStore.data.results) {
    if (
      state.selectedDimension.identifier === "web" &&
      technologyInfoStore.data.results.deviceCategorySummary &&
      technologyInfoStore.data.results.deviceCategorySummary[0]
    ) {
      if (state.selectedSecondaryDimension === "visits") {
        const desktopVisits =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "desktop"
          ).visits;
        const mobileVisits =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "mobile"
          ).visits;
        const tabletVisits =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "tablet"
          ).visits;

        const percentageDesktop = findPercentage(
          desktopVisits,
          desktopVisits,
          mobileVisits,
          tabletVisits
        );
        const percentageMobile = findPercentage(
          mobileVisits,
          desktopVisits,
          mobileVisits,
          tabletVisits
        );
        const percentageTablet = findPercentage(
          tabletVisits,
          desktopVisits,
          mobileVisits,
          tabletVisits
        );

        data = {
          labels: [
            `Desktop: ${percentageDesktop}%`,
            `Mobile: ${percentageMobile}%`,
            `Tablet: ${percentageTablet}%`,
          ],
          datasets: [
            {
              label: "# of Devices",
              data: [desktopVisits, mobileVisits, tabletVisits],
              backgroundColor: ["#1977D1", "#641D48", "#FF9800"],
              borderColor: ["#1977D1", "#641D48", "#FF9800"],
              borderWidth: 1,
            },
          ],
        };
      } else {
        const desktopViews =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "desktop"
          ).pageViews;
        const mobileViews =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "mobile"
          ).pageViews;
        const tabletViews =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "tablet"
          ).pageViews;

        const percentageDesktop = findPercentage(
          desktopViews,
          desktopViews,
          mobileViews,
          tabletViews
        );
        const percentageMobile = findPercentage(
          mobileViews,
          desktopViews,
          mobileViews,
          tabletViews
        );
        const percentageTablet = findPercentage(
          tabletViews,
          desktopViews,
          mobileViews,
          tabletViews
        );
        data = {
          labels: [
            `Desktop: ${percentageDesktop}%`,
            `Mobile: ${percentageMobile}%`,
            `Tablet: ${percentageTablet}%`,
          ],
          datasets: [
            {
              label: "# of Devices",
              data: [desktopViews, mobileViews, tabletViews],
              backgroundColor: ["#1977D1", "#641D48", "#FF9800"],
              borderColor: ["#1977D1", "#641D48", "#FF9800"],
              borderWidth: 1,
            },
          ],
        };
      }
    }
  }

  return (
    <Grid
      container
      style={{
        background: whiteColor,
        padding: 20,
      }}
    >
      <GridContainer>
        <div className={classes.headContainer}>
          <Typography className={classes.title}> {dataTitle} </Typography>

          <span className={classes.dimensionSelector}>
            {dimensions && (
              <HeaderSelect
                hasSecondary
                onChangeMenu={(selected) => handleDimensionChange(selected)}
                value={state.selectedDimension.name}
                headerMenus={dimensions}
                onChangeSecondaryMenu={(selected) =>
                  onSecondaryDimensionChange(selected)
                }
                secondaryValue={state.selectedSecondaryDimension}
                secondaryHeaderMenus={secondaryDimensions}
              />
            )}
          </span>
        </div>
      </GridContainer>

      {technologyInfoStore.loading ? (
        <Loading />
      ) : technologyInfoStore.data.results ? (
        <>
          <GridContainer>
            <MultiRecordsDoughnutChartComponent
              title="Devices Ratio"
              data={data}
              width={chartWidth > 500 ? "45%" : "100%"}
            />

            <MultiRecordsChartComponent
              data={formatChartData(
                technologyInfoStore.data.results
                  ? technologyInfoStore.data.results
                  : [],
                `${state.selectedDimension.identifier}-technology-devices`
              )}
              width={chartWidth > 500 ? "45%" : "100%"}
              title="Specific Devices"
            />
          </GridContainer>
        </>
      ) : (
        <NoContent title={"No Content"} />
      )}
    </Grid>
  );
};
