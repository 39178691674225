import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CustomInput from "../../../../components/CustomInput/TextField";
import ImagePlaceholder from "../../../../assets/images/placeholder.png";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import CreatableSelect from "../../../../components/CustomSelect/creatableSelect";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default ({
  title,
  setSocialMediaState,
  onPhotoUpload,
  socialMediaState,
}) => {
  const classes = useStyles();
  const uploadCampaignImgStore = useSelector(
    (store) => store.uploadCampaignImg
  );

  const onInputFieldsChange = (e) => {
    setSocialMediaState({
      ...socialMediaState,
      [e.target.name]: e.target.value,
    });
  };

  const onCreatableInputsChange = (isFor, data) => {
    if (isFor === "tags") {
      setSocialMediaState({ ...socialMediaState, tags: data });
    }
  };

  const onUploadImage = (file) => {
    onPhotoUpload({ file });
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Typography variant="h6">
        {title || "Create Social Media Post"}
      </Typography>
      <br />

      <br />
      <CustomInput
        label="What's on your mind"
        value={socialMediaState.summary || ""}
        id="socialMediaSummary"
        name="summary"
        fullWidth
        multiline
        rows={2}
        onChange={onInputFieldsChange}
        helperText="The text Associated with the post"
      />
      <br />
      <Box>
        <Typography variant="subtitle1">Post photo</Typography>
        <ImageUpload
          defaultImage={
            socialMediaState.photoUrl
              ? socialMediaState.photoUrl
              : ImagePlaceholder
          }
          imageSubmit={onUploadImage}
          imageTypes={
            "image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico"
          }
          addButtonProps={{ color: "primary" }}
          changeButtonProps={{ color: "primary" }}
          removeButtonProps={{ color: "danger" }}
          isUploading={uploadCampaignImgStore.loading}
        />

        <Typography color="textSecondary">
          {socialMediaState.imageError}
        </Typography>
      </Box>
      {socialMediaState.tags && (
        <div>
          <Typography variant="body1">Add Post Tags(Options) </Typography>
          <CreatableSelect
            placeholder="Add Post Tags, Ex: #inyarwandafans"
            defaultValues={socialMediaState.tags}
            options={[]}
            onChange={(data) => onCreatableInputsChange("tags", data)}
          />
        </div>
      )}
    </Grid>
  );
};
