import { FETCH_ALL_ADS, FETCH_SINGLE_AD, FETCH_PENDING_ALL_ADS,FETCH_ADSBANNERS_SECTIONS } from "./types";

const initialState = {
  loading: false,
  data: {},
};

export default class AdvertReducers {
  static allAdvertsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_ALL_ADS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_ALL_ADS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_ALL_ADS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static pendingEventsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_PENDING_ALL_ADS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PENDING_ALL_ADS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PENDING_ALL_ADS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static singleAdvertReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_SINGLE_AD}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_SINGLE_AD}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_SINGLE_AD}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static adsBannersSections(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_ADSBANNERS_SECTIONS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_ADSBANNERS_SECTIONS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_ADSBANNERS_SECTIONS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
