import React from "react";
import { Avatar, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowDimensions } from "../../../helpers/helperMethods";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
  },
  supportedTitle: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #888888",
    lineHeight: "0.1em",
    margin: "10px 0 20px",
  },
  supportedInline: {
    background: "#fff",
    padding: "0 10px",
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default ({ onChoosePaymentMethod }) => {
  const mtn_logo =
    "https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/mtn-logo.jpg";
  const airtel_logo =
    "https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/airtel-logo.jpg";
  const visa_logo =
    "https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/visa-logo.jpg";
  const mastercard_logo =
    "https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/mastercard-logo.jpg";

  const classes = useStyles();
  const handlePaymentMethod = (text) => onChoosePaymentMethod(text);
  const { width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Typography variant={width > 600 ? "h4" : "h5"} align="center">
        Choose Payment Method
      </Typography>
      <div className={classes.inputElt}>
        <Typography variant="body1" className={classes.supportedTitle}>
          <span className={classes.supportedInline}>
            Supported Payment Methods
          </span>
        </Typography>
        <br />
        <div className={classes.inputElt}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={10}
          >
            <Avatar
              alt="Mtn"
              src={mtn_logo}
              style={{
                width: width > 600 ? "15%" : 50,
                height: width > 600 ? "12vh" : 50,
                objectFit: "cover",
                margin: "10px",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Airtel"
              src={airtel_logo}
              style={{
                width: width > 600 ? "15%" : 50,
                height: width > 600 ? "12vh" : 50,
                objectFit: "cover",
                margin: "10px",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Visa"
              src={visa_logo}
              style={{
                width: width > 600 ? "20%" : 50,
                height: width > 600 ? "12vh" : 50,
                objectFit: "cover",
                margin: "10px",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("visa")}
            />
            <Avatar
              alt="MasterCard"
              src={mastercard_logo}
              style={{
                width: width > 600 ? "20%" : 50,
                height: width > 600 ? "12vh" : 50,
                objectFit: "cover",
                margin: "10px",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("visa")}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};
