import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "../../../components/CustomInput/TextField";
import { Grid } from "@material-ui/core";
import Remove from "@material-ui/icons/RemoveCircle";

export default ({ paymentConfig, classes, setPaymentConfig }) => {
  const [state, setState] = useState({});
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,.02)",
        padding: 10,
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <ListItem>
        <ListItemText primary="Tickets payment options" />
      </ListItem>

      <ListItem>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment Required?</FormLabel>
          <RadioGroup
            aria-label="payment options"
            defaultValue="required"
            name="payment"
            row
            onChange={() => {
              setPaymentConfig((prev) => ({
                ...prev,
                required: !paymentConfig.required,
                changed: true,
              }));
            }}
          >
            <FormControlLabel
              value="required"
              control={
                <Radio
                  className={classes.checkBox}
                  checked={paymentConfig.required}
                />
              }
              label="Required"
            />
            <FormControlLabel
              value="notrequired"
              control={
                <Radio
                  className={classes.checkBox}
                  checked={!paymentConfig.required}
                />
              }
              label="Not required"
            />
          </RadioGroup>
        </FormControl>
      </ListItem>
      {paymentConfig.required && (
        <React.Fragment>
          <ListItem>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="paymentCurrency">Payment currency</InputLabel>
              <Select
                labelId="paymentCurrency"
                id="paymentCurrency"
                name="paymentCurrency"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  const value2 =
                    typeof value === "string" ? value.split(",") : value;
                  if (
                    value2.length < paymentConfig.currency?.length &&
                    paymentConfig.ticketsOptions?.length > 0
                  ) {
                    setState((prev) => ({ ...prev, mismatch: true }));
                  } else {
                    setState((prev) => ({ ...prev, mismatch: false }));
                  }
                  setPaymentConfig((prev) => ({
                    ...prev,
                    currency: value2,
                    changed: true,
                  }));
                }}
                label="Payment currency"
                multiple
                value={paymentConfig.currency || []}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="RWF">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.currency &&
                      paymentConfig.currency.indexOf("RWF") > -1
                    }
                  />
                  <ListItemText primary={"RWF"} />
                </MenuItem>
                <MenuItem value="USD">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.currency &&
                      paymentConfig.currency.indexOf("USD") > -1
                    }
                  />
                  <ListItemText primary={"USD"} />
                </MenuItem>
                <MenuItem value="EUR">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.currency &&
                      paymentConfig.currency.indexOf("EUR") > -1
                    }
                  />
                  <ListItemText primary={"EUR"} />
                </MenuItem>
              </Select>
            </FormControl>
          </ListItem>

          <ListItem>
            <Grid
              container
              spacing={2}
              justify="space-around"
              style={{ marginTop: 10 }}
            >
              {paymentConfig.currency && paymentConfig.currency.length > 0 && (
                <Grid
                  item
                  sm
                  style={{
                    border: "1px solid black",
                    borderRadius: 5,
                    marginBottom: 5,
                    height: "fit-content",
                  }}
                >
                  Tickets Options
                  <List>
                    {paymentConfig.ticketsOptions &&
                      paymentConfig.ticketsOptions.map((el) => (
                        <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                          <ListItemText
                            primary={`> ${el.name} `}
                            secondary={
                              <React.Fragment>
                                <Typography variant="body2">
                                  {`${el.prices
                                    ?.map((p) => `${p.currency} ${p.amount}`)
                                    .join(", ")}`}
                                </Typography>
                                {`${el.totalPlaces || 0} Places`}
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction
                            onClick={() => {
                              const currepts =
                                paymentConfig.ticketsOptions.filter(
                                  (elem) => elem !== el
                                );
                              setPaymentConfig((prev) => ({
                                ...prev,
                                ticketsOptions: currepts,
                                changed: true,
                              }));
                            }}
                          >
                            <Tooltip title={`Remove ${el.name} option`} arrow>
                              <Remove fontSize="small" color="action" />
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    {state.mismatch && (
                      <ListItem>
                        <Typography color="secondary">
                          Tickets options mismatch with currency, please
                          reconfigure them
                        </Typography>
                      </ListItem>
                    )}
                    <ListItem>
                      <Grid
                        style={{
                          border: "1px solid black",
                          borderRadius: 5,
                          marginBottom: 5,
                          padding: "10px 5px",
                          height: "fit-content",
                        }}
                      >
                        <Typography>Add ticket option</Typography>
                        <Grid container spacing={1}>
                          <Grid item sm>
                            <CustomInput
                              label="Name"
                              value={
                                (paymentConfig.addTier &&
                                  paymentConfig.addTier.name) ||
                                ""
                              }
                              onChange={(e) => {
                                setPaymentConfig((prev) => ({
                                  ...prev,
                                  addTier: {
                                    ...prev.addTier,
                                    name: e.target.value,
                                  },
                                  changed: true,
                                }));
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item sm>
                            <CustomInput
                              label="Places"
                              value={
                                (paymentConfig.addTier &&
                                  paymentConfig.addTier.totalPlaces) ||
                                ""
                              }
                              onChange={(e) => {
                                setPaymentConfig((prev) => ({
                                  ...prev,
                                  addTier: {
                                    ...prev.addTier,
                                    totalPlaces: +e.target.value,
                                    remainingPlaces: +e.target.value,
                                  },
                                  changed: true,
                                }));
                              }}
                              fullWidth
                              type="number"
                            />
                          </Grid>
                          {paymentConfig.currency?.map((cur) => (
                            <Grid item sm>
                              <CustomInput
                                label={`${cur} amount`}
                                value={
                                  paymentConfig.addTier?.prices?.find(
                                    (pr) => pr.currency === cur
                                  )?.amount || ""
                                }
                                onChange={(e) => {
                                  const prices =
                                    paymentConfig.addTier?.prices || [];
                                  const priceIndex = prices.findIndex(
                                    (i) => i.currency === cur
                                  );
                                  if (priceIndex > -1) {
                                    prices[priceIndex].amount = +e.target.value;
                                  } else {
                                    prices.push({
                                      currency: cur,
                                      amount: +e.target.value,
                                    });
                                  }
                                  setPaymentConfig((prev) => ({
                                    ...prev,
                                    addTier: {
                                      ...prev.addTier,
                                      prices,
                                    },
                                    changed: true,
                                  }));
                                }}
                                fullWidth
                                type="number"
                              />
                            </Grid>
                          ))}

                          <Grid item sm style={{ marginTop: 10 }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                const currepts =
                                  paymentConfig.ticketsOptions || [];
                                currepts.push(paymentConfig.addTier);
                                setPaymentConfig((prev) => ({
                                  ...prev,
                                  ticketsOptions: currepts,
                                  changed: true,
                                  addTier: {},
                                }));
                              }}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
          </ListItem>
        </React.Fragment>
      )}
    </div>
  );
};
