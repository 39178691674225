import {
  uploadCampaignImgReducer,
  uploadImageFileReducer,
} from "./reducers/uploadFileReducer";
import {
  uploadImageFile,
  resetImageStore,
  uploadCampaignImg,
} from "./actions/uploadFile";

const uploadReducers = {
  uploadedImageFile: uploadImageFileReducer,
  uploadCampaignImg: uploadCampaignImgReducer,
};

export { uploadImageFile, resetImageStore, uploadCampaignImg, uploadReducers };
