import React, { useState, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
// core-components
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CustomButton from "../../../../../components/Buttons/customButton";
import AddBannerComponent from "../../../campaigns/addBanner/AddBanner";
import AddAdvertorialComponent from "../../../campaigns/addAdvertorial/AddAdvertorial";
import SocialMediaPostComponent from "../SocialMediaPost";
import { Avatar, Typography } from "@material-ui/core";
import { shortenText } from "../../../../../helpers/helperMethods";
import AdvertTypes from "./AdvertTypes";

const UseExisting = ({ onOpenMenu, menu, title, onChange }) => {
  const [openMenu, setOpenMenu] = useState({
    open: false,
    selectedMenuItem: {},
  });

  const styles = {
    root: {
      padding: "0 5%",
    },
    typo: {
      cursor: "pointer",
      textDecoration: "underline",
      textAlign: "center",
      padding: "10px 0",
    },
  };

  const handleOpenMenu = () => {
    onOpenMenu();
    setOpenMenu({ ...openMenu, open: true });
  };

  const handleChangeChoose = (id) => {
    const selectedMenuItem = menu.find((elt) => elt.id === id);
    onChange(selectedMenuItem);
  };

  return (
    <div style={styles.root}>
      <Typography
        variant="subtitle1"
        onClick={handleOpenMenu}
        style={styles.typo}
      >
        {title}
      </Typography>
      {openMenu.open && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {menu.map((elt) => (
            <div
              key={elt.id}
              style={{ padding: "5px 10px", cursor: "pointer" }}
              onClick={() => handleChangeChoose(elt.id)}
            >
              {elt.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ({
  handleSave,
  loading,
  content,
  onCreateOrEditBanner,
  onBannerUpload,
  onAdvertPhotoUpload,
  onCreateOrEditAdvert,
  onPostImgUpload,
  isEdit,
}) => {
  const [state, setState] = useState({
    selectedAdType: isEdit
      ? {
          name: content.adType,
          value: content.adType,
        }
      : {},
    error: "",
    useExistingMenu: [],
    usingExistingData: false,
  });

  const [banner, setBanner] = useState({
    file: null,
    path: null,
    url: null,
    action: "createBanner",
  });

  const [advertState, setAdvertState] = useState({
    action: "createAdvertorial",
  });

  const [socialMediaState, setSocialMediaState] = useState({
    tags: null,
    action: "createSocialMediaPost",
  });

  useEffect(() => {
    if (content.adType && content.adType.toLowerCase() === "banner") {
      setBanner({ ...banner, ...content.content });
    } else if (
      content.adType &&
      content.adType.toLowerCase() === "advertorial"
    ) {
      setAdvertState({ ...advertState, ...content.content });
    } else if (
      content.adType &&
      content.adType.toLowerCase() === "socialmediapost"
    ) {
      setSocialMediaState({
        ...socialMediaState,
        ...content.content,
        tags: content.content?.tags.map((elt) => ({
          value: elt,
          label: elt,
        })),
      });
    }
  }, [state]);

  useEffect(() => {
    if (!socialMediaState.tags) {
      setSocialMediaState((prevState) => ({
        ...prevState,
        tags: prevState.tags ? prevState.tags : [],
      }));
    }
  }, []);

  const handleSelectChange = (data) => {
    setState({
      ...state,
      error: "",
      useExistingMenu: [],
      usingExistingData: false,
      selectedAdType: data,
    });
    setSocialMediaState({
      tags: [],
      action: "createSocialMediaPost",
    });
  };

  const onSave = () => {
    if (!state.selectedAdType.value) {
      return setState({ ...state, error: "Please Choose a ad type" });
    }
    if (state.selectedAdType.value === "banner") {
      handleSave(
        { ...banner, category: state.selectedAdType.value },
        state.usingExistingData
      );
    } else if (state.selectedAdType.value === "advertorial") {
      handleSave(
        { ...advertState, category: state.selectedAdType.value },
        state.usingExistingData
      );
    } else {
      handleSave(
        { ...socialMediaState, platform: state.selectedAdType.platform },
        state.usingExistingData
      );
    }
  };

  const useExistingData = (isFor) => {
    let menu = [];
    if (isFor === "banners") {
      menu = content.adBanners.map((ban, index) => ({
        id: ban.id,
        name: (
          <>
            <Avatar
              alt={`Banner - ${index + 1}` || "Img"}
              src={ban.url}
              variant="rounded"
              style={{
                width: "65px",
                height: "65px",
              }}
            />
          </>
        ),
        value: `Banner-${index + 1}`,
      }));
    } else {
      menu = content.advertorials.map((advert, index) => ({
        id: advert.id,
        name: (
          <>
            <Avatar
              alt={advert.title}
              src={advert.photoUrl || ""}
              variant="rounded"
              style={{
                width: "65px",
                height: "65px",
              }}
            />
            <Typography variant="subtitle2">
              {shortenText(advert.title, 15)}
            </Typography>
          </>
        ),
        value: advert.title,
      }));
    }
    setState({ ...state, useExistingMenu: menu });
  };

  const onUseExistingDataFill = (data) => {
    let selectedAd;
    if (state.selectedAdType.value === "banner") {
      selectedAd = content.adBanners.find((elt) => elt.id === data.id);
      setBanner({ ...banner, ...selectedAd });
    } else if (state.selectedAdType.value === "advertorial") {
      selectedAd = content.advertorials.find((elt) => elt.id === data.id);
      setAdvertState({ ...advertState, ...selectedAd });
    }
    setState({ ...state, usingExistingData: true });
  };

  return (
    <div>
      <CardHeader title="Create Ad"></CardHeader>
      <CardContent>
        {!isEdit && (
          <div>
            <AdvertTypes
              selectedAdType={state.selectedAdType}
              onChange={handleSelectChange}
            />
          </div>
        )}

        {state.selectedAdType.value === "banner" ? (
          <div>
            {!isEdit && content.adBanners.length > 0 && (
              <UseExisting
                title="Use Existing Banners"
                onOpenMenu={() => useExistingData("banners")}
                menu={state.useExistingMenu}
                onChange={onUseExistingDataFill}
              />
            )}
            <AddBannerComponent
              // disabled={state.usingExistingData}
              hideSubmitBtn
              title="Create Banner"
              toggleModel={() => console.log("cool")}
              onCreateOrEditBanner={onCreateOrEditBanner}
              onBannerUpload={onBannerUpload}
              setBanner={setBanner}
              banner={banner}
            />
          </div>
        ) : state.selectedAdType.value === "advertorial" ? (
          <div>
            {!isEdit && content.advertorials.length > 0 && (
              <UseExisting
                title="Use Existing Advertorials"
                onOpenMenu={() => useExistingData("advertorials")}
                menu={state.useExistingMenu}
                onChange={onUseExistingDataFill}
              />
            )}
            <AddAdvertorialComponent
              hideSubmitBtn
              title={isEdit ? "Edit Advertorial" : "Create Advertorial"}
              toggleModel={() => console.log("cool")}
              onCreateOrEditAdvert={onCreateOrEditAdvert}
              setAdvertState={setAdvertState}
              onAdvertPhotoUpload={onAdvertPhotoUpload}
              state={advertState}
              advertState={advertState}
              // disabled={state.usingExistingData}
            />
          </div>
        ) : state.selectedAdType.value === "socialMediaPost" ? (
          <div>
            <SocialMediaPostComponent
              setSocialMediaState={setSocialMediaState}
              onPhotoUpload={onPostImgUpload}
              socialMediaState={socialMediaState}
            />
          </div>
        ) : null}
      </CardContent>
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomButton color="primary" onClick={onSave}>
          {loading ? (
            <PulseLoader size={5} margin={3} color={"#fff"} loading={loading} />
          ) : isEdit ? (
            "save"
          ) : (
            "Save and continue"
          )}
        </CustomButton>
      </CardActions>
    </div>
  );
};
