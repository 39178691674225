import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Backdrop from "@material-ui/core/Backdrop";
import reduxAction, { fullUrlAction } from "../../../redux/action";
import Step3 from "../../components/ads/createAd/steps/Step3";
import Loading from "../../../components/isLoading";
import Toast from "../../../components/MessageToast";
import {
  FETCH_PAYMENT_PLANS,
  OTP_VERIFICATION,
  PAYMENT_VERIFICATION,
  PAYMENT_CONFIRM,
} from "../../../redux/payment/types";
import PaymentModal from "../../components/payment/PaymentModal";
import PriceCard from "../../components/ads/paymentPlan/PriceCard";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { useWindowDimensions } from "../../../helpers/helperMethods";
import Close from "@material-ui/icons/Close";
import PriceList from "../../components/ads/paymentPlan/PriceList";
import PaymentContent from "../../components/payment/PaymentContent";

export default ({ entityData, isOpen, handleClosePayment }) => {
  const dispatch = useDispatch();

  const openToast = ({ message, type, onClose }) =>
    Toast({ message, type, duration: 4000, onClose });

  const allPaymentPlansStore = useSelector((store) => store.allPaymentPlans);
  const paymentConfirmation = useSelector((store) => store.paymentConfirmation);
  const paymentVerification = useSelector((store) => store.paymentVerification);

  const [state, setState] = useState({
    loading: false,
    openToast: false,
    openModel: false,
    selectedPlan: {},
  });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: "/promotions/plans",
        actionType: FETCH_PAYMENT_PLANS,
      })
    );
  }, []);

  const handlePayment = (data) => {
    /* 
     Method to confirm the payment
     This is called upon adding phone details
    */
    const { amount, phone, currency } = data;

    const payload = {
      clientId: entityData.clientId,
      campaignId: entityData.campaignId,
      adId: entityData.id || entityData.adId,
      adType: entityData.adType,
    };

    const paymentData = {
      type: "ads",
      amount: amount,
      phone_number: phone,
      method: "phone",
      currency,
      payload,
      id: entityData.id || entityData.adId,
    };

    dispatch(
      fullUrlAction({
        method: "POST",
        path: `${process.env.PAYMENT_API}/transactions`,
        actionType: PAYMENT_CONFIRM,
        data: paymentData,
      })
    );
  };

  const handleOTPSubmission = (otp) => {
    if (
      paymentConfirmation.payment_data.results &&
      paymentConfirmation.payment_data.results.charge.redirect
    ) {
      const otpData = {
        otp,
        redirect: paymentConfirmation.payment_data.results.charge.redirect,
      };

      dispatch(
        fullUrlAction({
          method: "POST",
          path: `${process.env.PAYMENT_API}/transactions/${paymentConfirmation.payment_data.results.transaction.tx_ref}/otp`,
          actionType: OTP_VERIFICATION,
          data: otpData,
        })
      );
    }
  };

  const checkPaymentIfSuccessful = () => {
    if (
      paymentConfirmation.payment_data.results &&
      paymentConfirmation.payment_data.results.transaction.tx_ref
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `https://us-central1-nonehoidentity.cloudfunctions.net/PaymentAPI/api/transactions/${paymentConfirmation.payment_data.results.transaction.tx_ref}`,
          actionType: PAYMENT_VERIFICATION,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setState({
      ...state,
      loading: false,
      openToast: false,
      openModel: false,
      selectedPlan: {},
    });
    handleClosePayment();
  };

  const choosePaymentPlan = async (data) =>
    setState({ ...state, selectedPlan: data });

  const plans = (allPaymentPlansStore.data.results || []).map((plan) => ({
    planType: plan.Name,
    price: plan.Amount,
    features: [plan.Name],
  }));

  const { width } = useWindowDimensions();

  const style = {
    width: width > 1000 ? "55%" : "75%",
    padding: "5vh 5%",
    height: "fit-content",
    backgroundColor: "#fff",
    borderRadius: "6px",
    outline: "none",
    "@media (max-width: 600px)": {
      width: "80%",
      height: "fit-content",
      padding: "10vh 5%",
    },
  };

  return allPaymentPlansStore.loading ? (
    <Loading />
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {!state.selectedPlan.price ? (
        <div style={style}>
          <IconButton
            aria-label="Close"
            onClick={handleCloseModal}
            style={{
              float: "right",
              margin: width > 600 ? "-3vh -3%" : "-8vh -3%",
            }}
          >
            <Close />
          </IconButton>
          <Typography variant={width > 600 ? "h4" : "h5"}>
            Choose Payment Plan
          </Typography>
          <PriceList content={plans} choosePaymentPlan={choosePaymentPlan} />
        </div>
      ) : (
        <PaymentContent
          isOpen={state.openModel}
          handleClose={handleCloseModal}
          content={entityData}
          onPaymentConfirm={handlePayment}
          paymentConfirmationStore={paymentConfirmation}
          onOtpSubmit={handleOTPSubmission}
          paymentOptions={state.selectedPlan}
        />
      )}
    </Modal>
  );
};
