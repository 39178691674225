import React, { Component } from 'react';
import AppLayout from '../../../layouts/AppLayout';
import TechnologyContainer from '../../containers/performance/TechnologyContainer';
import HeaderSelect from '../../containers/headerSelect/headerSelect';

export class Technology extends Component {
  render() {
    return (
      <AppLayout>
        <HeaderSelect />
        <TechnologyContainer />
      </AppLayout>
    );
  }
}

export default Technology;
