import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import CreateAdContainer from "../../containers/advertisement/createAd";

export class CreateAd extends Component {
  render() {
    return (
      <AppLayout>
        <CreateAdContainer />
      </AppLayout>
    );
  }
}

export default CreateAd;
