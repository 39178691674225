import React from "react";
import CustomButton from "../Buttons/customButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../../assets/styles/base";

const useStyles = makeStyles((theme) => ({
  okButton: {
    backgroundColor: primaryColor[0],
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },
}));

export default ({
  title,
  content,
  okText = "Ok",
  open,
  fullWidth,
  fullScreen,
  maxWidth,
  okColor,
  onCancel,
  onConfirm,
  disabled,
  loading,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth || "xs"}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions style={{ float: "left" }}>
        <CustomButton onClick={onCancel} disabled={disabled || loading}>
          Cancel
        </CustomButton>
        <CustomButton
          color="primary"
          variant="contained"
          onClick={onConfirm}
          disabled={disabled || loading}
          className={classes.okButton}
        >
          {!loading ? okText : "Loading..."}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
