import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

const MyEditor = ({ onContentChange, content, disabled }) => {
  const editor = useRef(null);
  // const [content, setContent] = useState('');
  const config = {
    readonly: disabled, // all options from https://xdsoft.net/jodit/doc/
    defaultMode: "1",
    toolbarSticky: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    toolbarInlineForSelection: true,
    showPlaceholder: false,

    buttons:
      "bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,font,fontsize,paragraph,brush,image",
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => onContentChange(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
      askBeforePasteHTML={false}
    />
  );
};

export default MyEditor;
