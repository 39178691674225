import React, { useState } from "react";
import { useDispatch } from "react-redux";
import RecordsComponent from "../../../components/dashboard/RecordsComponent";

import reduxAction from "../../../../redux/action";
import {
  FETCH_ALL_EVENTS,
  FETCH_PENDING_EVENTS,
} from "../../../../redux/events/types";
import { useHistory } from "react-router";
import apiService from "../../../../services/apiService";
import Toast from "../../../../components/MessageToast";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Public from "@material-ui/icons/Public";
import { Avatar } from "@material-ui/core";
import { createData, formatDate } from "../../../../helpers/helperMethods";
import DataTableComponent from "../../../components/data/DataTableComponent";
import ListItemMenu from "../../../../components/Menu/ListItemMenu";

export default ({ content, isUpdating, userInfo }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({});

  // useEffect(() => {
  //   if (!eventsStore.data.results) {
  //     dispatch(
  //       reduxAction({
  //         method: "GET",
  //         path:  ? `/events?status=pending` : `/events`,
  //         actionType:  ? FETCH_PENDING_EVENTS : FETCH_ALL_EVENTS,
  //       })
  //     );
  //   }
  // }, [state, ]);

  const handleClickCreateEvent = () => history.push("/events/new");

  const onDeleteEvent = async (eventId) => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "DELETE",
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events?status=pending`,
            actionType: FETCH_PENDING_EVENTS,
          })
        );
      },
    });
  };

  const recordsToDisplay = [
    {
      title: "All Banners",
      color: "warning",
      iconName: "ad_units",
      value: content.length || 0,
    },
  ];

  // const doNothing = () => console.log("============");
  const headCells = [
    {
      id: "name",
      rightAlign: false,
      label: "Name",
    },
    {
      id: "status",
      rightAlign: true,
      label: "Status",
    },
    {
      id: "createdAt",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Created At",
    },
    {
      id: "actions",
      rightAlign: true,
      label: "Actions",
    },
  ];

  const rows = content
    ? content.map((elt, index) =>
        createData(
          elt.id,
          {
            content1: (
              <Avatar
                alt={elt.name || "Img"}
                src={
                  elt.url || "https://via.placeholder.com/200x150.png?text=Ban"
                }
                variant="rounded"
              />
            ),
            content2: `Banner ${index + 1}`,
          },
          elt.status,
          formatDate(elt.createdAt),
          <ListItemMenu
            menu={[
              {
                icon: <Edit fontSize="small" />,
                primary: "Edit",
                link: `/media-creative/${elt.id}/edit`,
              },
              {
                icon: <Public fontSize="small" />,
                primary: "Create Ad",
                // link: `/campaigns/${elt.id}/edit`,
                onclick: () => doNothing(),
                hidden: false,
              },
              {
                icon: <Delete fontSize="small" />,
                primary: "Delete",
                onClick: () => onDeleteEvent(elt.id),
                hidden: false,
              },
            ]}
          />
        )
      )
    : [];

  return (
    <div>
      {/* <CreateButton
            text="Create new Event"
            handleOnClick={handleClickCreateEvent}
            styles={{ float: "right" }}
          /> */}
      <RecordsComponent isUpdating={isUpdating} records={recordsToDisplay} />

      <DataTableComponent
        isClickable
        // notActive={isPending}
        isUpdating={isUpdating}
        rows={rows}
        headCells={headCells}
        title="All Banners"
        generalUrl="/media-creatives"
      />
    </div>
  );
};
