import React, { useEffect, useState, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import countryList from "react-select-country-list";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import CustomInput from "../../../components/CustomInput/TextField";
import { primaryColor } from "../../../assets/styles/base";
import "../../../assets/styles/plugins/calendar.css";
import CreatableSelect from "../../../components/CustomSelect/creatableSelect";
import { Grid } from "@material-ui/core";
import VotePaymentConfig from "./VotePaymentConfig";
import ConcertTicketsConfig from "./ConcertTicketsConfig";
import DateTimePicker from "../../../components/CustomInput/DateTimePicker";

const userStyles = makeStyles((theme) => ({
	card: {
		padding: "3vh 5%",
	},
	createButton: {
		backgroundColor: primaryColor[0],
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: primaryColor[0],
		},
	},

	checkBox: {
		"&.Mui-checked": {
			color: primaryColor[0],
		},
	},
}));

export default ({
	event,
	onBasicInforChange,
	apiCall,
	onLaunchEvent,
	onActivateOrStopEvent,
}) => {
	const classes = userStyles();

	const [eventState, setEventState] = useState({
		eventInfo: event || {},
	});

	const countriesOptions = useMemo(() => countryList().getData(), []);
	const [selectedCountries, setselectedCountries] = useState([]);
	const [votePaymentConfig, setVotePaymentConfig] = useState({
		required: event.payment.required,
		methods: event.payment.methods || [],
		momoOptions: {
			...event.payment.momoOptions,
			voteOptions:
				(event.payment.momoOptions && event.payment.momoOptions.voteOptions) ||
				[],
		},
		cardOptions: {
			...event.payment.cardOptions,
			currency:
				(event.payment.cardOptions && event.payment.cardOptions.currency) || [],
			RWF: {
				...(event.payment.cardOptions && event.payment.cardOptions.RWF),
				voteOptions:
					(event.payment.cardOptions &&
						event.payment.cardOptions.RWF?.voteOptions) ||
					[],
			},
			USD: {
				...(event.payment.cardOptions && event.payment.cardOptions.USD),
				voteOptions:
					(event.payment.cardOptions &&
						event.payment.cardOptions.USD?.voteOptions) ||
					[],
			},
			EUR: {
				...(event.payment.cardOptions && event.payment.cardOptions.EUR),
				voteOptions:
					(event.payment.cardOptions &&
						event.payment.cardOptions.EUR?.voteOptions) ||
					[],
			},
		},
	});

	const [ticketsConfig, setTicketsConfig] = useState({
		required: true,
		currency: event.payment.currency || [],
		ticketsOptions: event.payment.ticketsOptions || [],
	});

	useEffect(() => {
		setEventState({
			eventInfo: event,
		});
		if (event) {
			const countries = event.countries
				? event.countries.map((country) => ({
						value: country.countryCode,
						label: country.countryName,
				  }))
				: [];

			setselectedCountries(countries);
		}
	}, [event]);

	const handleDateRangeClick = (date) => {
		setEventState((prevState) => ({
			...eventState,
			[date + "Open"]: !prevState[date + "Open"],
		}));
	};

	const handleDateSelection = (selectedDate, date) => {
		setEventState({
			eventInfo: {
				...eventState.eventInfo,
				[date]: selectedDate,
			},
			[date]: selectedDate,
			changed: true,
		});
	};

	const onSimpleSelectChange = (e) => {
		setEventState({
			eventInfo: { ...eventState.eventInfo, [e.target.name]: e.target.value },
			changed: true,
		});
	};

	const onPaymentRadioChange = (e) => {
		const { value } = e.target;
		setVotePaymentConfig({
			...votePaymentConfig,
			required: value === "required",
			changed: true,
		});
	};

	const onCategorizedRadioChange = (e) => {
		const { value } = e.target;
		setEventState({
			eventInfo: {
				...eventState.eventInfo,
				categorized: value === "required",
			},
			changed: true,
		});
	};

	const onPayMethodsChange = (e) => {
		const {
			target: { value },
		} = e;
		if (eventState.eventInfo.category === "Voting") {
			const value2 = typeof value === "string" ? value.split(",") : value;
			setVotePaymentConfig({
				...votePaymentConfig,
				methods: value2,
				changed: true,
			});
		}
	};

	const onInputTextChange = (e) => {
		setEventState({
			eventInfo: {
				...eventState.eventInfo,
				[e.target.name]: e.target.value,
			},
			changed: true,
		});
	};

	const onPayInputTextChange = (e, objPath) => {
		const { value } = e.target;
		if (eventState.eventInfo.category === "Voting") {
			setVotePaymentConfig({
				...votePaymentConfig,
				[objPath]: value,
				changed: true,
			});
		}
	};

	const handleCategoriesChange = (change) => {
		setEventState({
			eventInfo: {
				...eventState.eventInfo,
				categories: change.map((el) => el.value),
			},
			changed: true,
		});
	};

	const onMultiCountriesRadioChange = (e) => {
		const { value } = e.target;
		setEventState({
			eventInfo: {
				...eventState.eventInfo,
				multiCountries: value === "required",
			},
			changed: true,
		});
	};

	const handleChangeCountries = (event, value) => {
		setselectedCountries(value);
		setEventState({
			eventInfo: {
				...eventState.eventInfo,
				countries: value.map((el) => ({
					countryName: el.label,
					countryCode: el.value,
				})),
			},
			changed: true,
		});
	};

	const handleCountryOptionSelected = (option) => {
		return selectedCountries.some(
			(selectedOption) => selectedOption.value === option.value
		);
	};

	const onSaveChanges = () => {
		let data = {};
		if (eventState.changed) {
			data = eventState.eventInfo;
		}
		if (votePaymentConfig.changed) {
			data.payment = { ...votePaymentConfig, changed: undefined };
		}
		if (ticketsConfig.changed) {
			const totalPlaces = ticketsConfig.ticketsOptions.reduce(
				(a, b) => a.totalPlaces || a + b.totalPlaces,
				0
			);
			const remainingPlaces = ticketsConfig.remainingPlaces || totalPlaces;
			data.payment = {
				...ticketsConfig,
				changed: undefined,
				addTier: undefined,
				totalPlaces,
				remainingPlaces: remainingPlaces + (totalPlaces - remainingPlaces),
			};
		}
		onBasicInforChange(data);
	};

	const eventCategories = eventState.eventInfo.categories
		? eventState.eventInfo.categories.map((elt) => ({ value: elt, label: elt }))
		: [];

	return (
		<Card className={classes.card}>
			<Typography variant="h6" align="center">
				Basic Information
			</Typography>
			{event.status !== "published" && (
				<Grid container justify="flex-end">
					<Button
						variant="contained"
						color="primary"
						className={classes.createButton}
						onClick={onLaunchEvent}
					>
						Launch event
					</Button>
				</Grid>
			)}
			{event.status === "published" && (
				<Grid container justifyContent="flex-end">
					<Button
						variant="contained"
						color="primary"
						className={classes.createButton}
						onClick={onActivateOrStopEvent}
					>
						{event.isActive ? "Stop Event" : "Activate Event"}
					</Button>
				</Grid>
			)}
			<List>
				<ListItem>
					<CustomInput
						label="Event name"
						value={eventState.eventInfo.name || ""}
						id="eventName"
						name="name"
						disabled={event.status === "published"}
						fullWidth
						onChange={onInputTextChange}
					/>
				</ListItem>
				<ListItem>
					<FormControl variant="standard" fullWidth>
						<InputLabel id="Category">Event category</InputLabel>
						<Select
							labelId="Category"
							id="category"
							name="category"
							value={eventState.eventInfo.category || ""}
							onChange={onSimpleSelectChange}
							label="Event category"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							<MenuItem
								value="Voting"
								checked={
									eventState.eventInfo.category &&
									eventState.eventInfo.category === "Voting"
								}
							>
								Voting
							</MenuItem>
							<MenuItem
								value="Concert"
								checked={
									eventState.eventInfo.category &&
									eventState.eventInfo.category === "Concert"
								}
							>
								Concert
							</MenuItem>
							<MenuItem
								value="Sport"
								checked={
									eventState.eventInfo.category &&
									eventState.eventInfo.category === "Sport"
								}
							>
								Sport
							</MenuItem>
							<MenuItem
								value="Meeting"
								checked={
									eventState.eventInfo.category &&
									eventState.eventInfo.category === "Meeting"
								}
							>
								Meeting
							</MenuItem>
							<MenuItem
								value="Press release"
								checked={
									eventState.eventInfo.category &&
									eventState.eventInfo.category === "Press release"
								}
							>
								Press release
							</MenuItem>
							<MenuItem
								value="Exhibition"
								checked={
									eventState.eventInfo.category &&
									eventState.eventInfo.category === "Exhibition"
								}
							>
								Exhibition
							</MenuItem>
						</Select>
					</FormControl>
				</ListItem>
				{eventState.eventInfo.category === "Voting" && (
					<>
						<div
							style={{
								backgroundColor: "rgba(0,0,0,.02)",
								padding: 10,
								marginTop: 10,
								marginBottom: 10,
							}}
						>
							<ListItem>
								<ListItemText primary="Voting categories" />
							</ListItem>
							<ListItem>
								<FormControl component="fieldset">
									<RadioGroup
										aria-label="categorized"
										defaultValue="Not required"
										name="categorized"
										row
										onChange={onCategorizedRadioChange}
									>
										<FormControlLabel
											value="required"
											control={
												<Radio
													className={classes.checkBox}
													checked={eventState.eventInfo.categorized}
												/>
											}
											label="Required"
										/>
										<FormControlLabel
											value="notrequired"
											control={
												<Radio
													className={classes.checkBox}
													checked={!eventState.eventInfo.categorized}
												/>
											}
											label="Not required"
										/>
									</RadioGroup>
								</FormControl>
							</ListItem>
							{eventState.eventInfo.categorized && (
								<React.Fragment>
									<ListItem>
										<ListItemText primary="Create voting categories" />
									</ListItem>
									<ListItem style={{ display: "block", width: "100%" }}>
										<CreatableSelect
											placeholder="Create voting categories"
											defaultValues={eventCategories || []}
											options={[]}
											onChange={handleCategoriesChange}
										/>
									</ListItem>
								</React.Fragment>
							)}
						</div>
						<div
							style={{
								backgroundColor: "rgba(0,0,0,.02)",
								padding: 10,
								marginTop: 10,
								marginBottom: 10,
							}}
						>
							<ListItem>
								<ListItemText primary="Countries" />
							</ListItem>
							<ListItem>
								<FormControl component="fieldset">
									<RadioGroup
										aria-label="multi-countries"
										defaultValue="Not required"
										name="multiCountries"
										row
										onChange={onMultiCountriesRadioChange}
									>
										<FormControlLabel
											value="required"
											control={
												<Radio
													className={classes.checkBox}
													checked={eventState.eventInfo.multiCountries}
												/>
											}
											label="Required"
										/>
										<FormControlLabel
											value="notrequired"
											control={
												<Radio
													className={classes.checkBox}
													checked={!eventState.eventInfo.multiCountries}
												/>
											}
											label="Not required"
										/>
									</RadioGroup>
								</FormControl>
							</ListItem>
							{eventState.eventInfo.multiCountries && (
								<React.Fragment>
									<ListItem>
										<ListItemText primary="Create Event Countries" />
									</ListItem>
									<ListItem style={{ display: "block", width: "100%" }}>
										<div>
											<FormControl style={{ m: 1, width: "100%" }}>
												<Autocomplete
													multiple
													options={countriesOptions}
													value={selectedCountries}
													onChange={handleChangeCountries}
													getOptionLabel={(option) => option.label}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Countries"
															variant="outlined"
															InputLabelProps={{ shrink: true }}
														/>
													)}
													getOptionDisabled={(option) =>
														option.disabled ||
														handleCountryOptionSelected(option)
													}
												/>
											</FormControl>
										</div>
									</ListItem>
								</React.Fragment>
							)}
						</div>
					</>
				)}

				<ListItem>
					<CustomInput
						label="Event venue"
						value={eventState.eventInfo.venue || ""}
						id="venue"
						name="venue"
						onChange={onInputTextChange}
						fullWidth
					/>
				</ListItem>

				<ListItem>
					<CustomInput
						label="Event organizer"
						value={eventState.eventInfo.organizer || ""}
						id="organizer"
						name="organizer"
						onChange={onInputTextChange}
						fullWidth
					/>
				</ListItem>

				<ListItem>
					<CustomInput
						label="Event description"
						value={eventState.eventInfo.description || ""}
						id="description"
						name="description"
						onChange={onInputTextChange}
						fullWidth
						multiline
						rows={4}
					/>
				</ListItem>

				{eventState.eventInfo.category === "Voting" && (
					<VotePaymentConfig
						paymentConfig={votePaymentConfig}
						classes={classes}
						onPaymentRadioChange={onPaymentRadioChange}
						onPayMethodsChange={onPayMethodsChange}
						setPaymentConfig={setVotePaymentConfig}
					/>
				)}

				{eventState.eventInfo.category === "Concert" && (
					<ConcertTicketsConfig
						classes={classes}
						paymentConfig={ticketsConfig}
						setPaymentConfig={setTicketsConfig}
					/>
				)}

				<ListItem>
					<ListItemText
						primary="Start date and time"
						disableTypography
						secondary={
							<React.Fragment>
								<DateTimePicker
									defaultValue={eventState.eventInfo.startDate}
									onChange={(date) => handleDateSelection(date, "startDate")}
								/>
							</React.Fragment>
						}
					/>
				</ListItem>

				<ListItem>
					<ListItemText
						primary="End date and time"
						disableTypography
						secondary={
							<React.Fragment>
								<DateTimePicker
									defaultValue={eventState.eventInfo.endDate}
									onChange={(date) => handleDateSelection(date, "endDate")}
								/>
							</React.Fragment>
						}
					/>
				</ListItem>

				{eventState.eventInfo.category === "Concert" && (
					<ListItem>
						<ListItemText
							primary="Checkin time"
							disableTypography
							secondary={
								<React.Fragment>
									<DateTimePicker
										defaultValue={
											eventState.eventInfo.checkinTime ||
											eventState.eventInfo.startDate
										}
										onChange={(date) =>
											handleDateSelection(date, "checkinTime")
										}
									/>
								</React.Fragment>
							}
						/>
					</ListItem>
				)}

				<ListItem style={{ marginTop: 10 }}>
					<ListItemSecondaryAction>
						<Button
							variant="contained"
							color="primary"
							className={classes.createButton}
							disabled={
								(!eventState.changed &&
									!votePaymentConfig.changed &&
									!ticketsConfig.changed) ||
								apiCall.loading
							}
							onClick={onSaveChanges}
						>
							{apiCall.loading ? "Saving..." : "Save Changes"}
						</Button>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		</Card>
	);
};
