// @material-ui/icons
import React from "react";
import DashboardIcon from "@material-ui/icons/Home";
import AccountsIcon from "@material-ui/icons/AccountCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Settings from "@material-ui/icons/Settings";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import FiberSmartRecordIcon from "@material-ui/icons/FiberSmartRecord";
import PermMediaIcon from "@material-ui/icons/PermMedia";

const { claims } = JSON.parse(localStorage.getItem("au")) || {};

var dashRoutes = [
  {
    path: "/",
    name: "Home",
    icon: DashboardIcon,
    page: "home",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
  },
  {
    collapse: true,
    name: "Media Partners",
    customName: "Media Partners",
    icon: EqualizerIcon,
    state: "mediaPartnersCollapse",
    page: "mediaPartners",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
    views: [
      {
        path: "/traffic",
        name: "Traffic",
        mini: "TR",
        page: "traffic",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/audience",
        name: "Audience",
        mini: "AU",
        page: "audience",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/technology",
        name: "Technology",
        mini: "TR",
        page: "technology",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
    ],
  },
  {
    collapse: true,
    name: "Events",
    customName: "Events",
    icon: EventAvailableIcon,
    state: "eventsCollapse",
    page: "events",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
    views: [
      {
        path: "/events",
        name: "Published events",
        mini: "AE",
        page: "events",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/events/pending",
        name: "Pending events",
        mini: "PE",
        page: "pendingEvents",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/events/new",
        name: "Create event",
        mini: "CE",
        page: "createEvents",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
    ],
  },
  {
    collapse: true,
    name: "Campaigns",
    customName: "Campaigns",
    icon: RssFeedIcon,
    state: "campaignsCollapse",
    page: "campaigns",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
    views: [
      {
        path: "/campaigns",
        name: "Active Campaigns",
        mini: "AC",
        page: "campaigns",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/campaigns/pending",
        name: "Pending Campaigns",
        mini: "PC",
        page: "pendingCampaigns",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/campaigns/new",
        name: "Create Campaign",
        mini: "CC",
        page: "createCampaigns",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
    ],
  },
  {
    collapse: true,
    name: "Ads",
    customName: "Ads",
    icon: FiberSmartRecordIcon,
    state: "adsCollapse",
    page: "ads",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
    views: [
      {
        path: "/ads",
        name: "All Ads",
        mini: "Ads",
        page: "ads",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/ads/new",
        name: "Create Ad",
        mini: "CA",
        page: "createAd",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
    ],
  },

  {
    path: "/media-creatives",
    name: "Media Creatives",
    icon: PermMediaIcon,
    page: "orgs",
    roles: ["admin", "owner"],
  },

  {
    path: "/orgs",
    name: "Organizations",
    icon: AssignmentTurnedInIcon,
    page: "orgs",
    roles: ["admin", "owner"],
  },

  {
    path: `/orgs/${claims && claims.userOrg && claims.userOrg.orgId}`,
    name: "Account",
    icon: AccountsIcon,
    page: "orgs",
    roles: ["admin", "owner"],
    accessLevel: "org",
    hidden: !claims || !claims.userOrg || !claims.userOrg.orgId,
  },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: Settings,
  //   page: "settings",
  //   roles: ["admin", "owner"],
  // },
];

export default dashRoutes;
