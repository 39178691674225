import { blackColor, primaryColor } from "../base";

export default (theme) => ({
  topSection: {
    display: "flex",
    flexWrap: "nowrap",
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },
  records: {
    padding: 200,
    background: "green",
  },
  paymentDetails: {
    cursor: "pointer",
    outline: "none",
    color: primaryColor[0],
  },
  image: {
    width: 350,
    height: 350,
  },
  texts: {
    textDecoration: "none",
    color: blackColor,
    margin: "10px 0",
  },

  articleUrl: {
    textDecoration: "none",
    color: blackColor,
    cursor: "pointer",
    "&:hover": {
      color: primaryColor[0],
    },
  },

  name: {
    textDecoration: "none",
    color: primaryColor[0],
    cursor: "pointer",
  },
  hovered: {
    "&:hover": {
      cursor: "pointer",
      color: primaryColor[0],
    },
  },

  textSection: {
    padding: 20,
  },
});
