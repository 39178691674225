import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/isLoading";
import RecordsComponent from "../../components/dashboard/RecordsComponent";
import TimePicker from "../../components/date/TimePickerComponent";
import moment from "moment";
import reduxAction from "../../../redux/action";
import { FETCH_SINGLE_CAMPAIGN_INFO } from "../../../redux/campaign/types";
import CampaignComponent from "../../components/campaigns/campaign";
import NoContent from "../../../components/NoContent";
import Toast from "../../../components/MessageToast";
import apiService from "../../../services/apiService";
import BannersContainer from "./allCampaigns/BannersContainer";
import AdvertorialsContainer from "./allCampaigns/AdvertorialsContainer";
import { useWindowDimensions } from "../../../helpers/helperMethods";

export default (props) => {
  const { campaignId } = props;

  const dispatch = useDispatch();
  const singleCampaignStore = useSelector((store) => store.singleCampaign);

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};

  let startDate = moment(
    state.date && state.date.startDate ? state.date.startDate : state.date
  )
    .subtract(6, "d")
    .format("YYYY-MM-DD");
  let endDate = moment(
    state.date && state.date.endDate ? state.date.endDate : state.date
  ).format("YYYY-MM-DD");

  if (state.daily) {
    startDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
    endDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
  } else if (state.weekly) {
    startDate = moment(state.date).subtract(7, "d").format("YYYY-MM-DD");
  } else if (state.monthly) {
    startDate = moment(state.date).subtract(1, "M").format("YYYY-MM-DD");
  } else if (state.yearly) {
    startDate = moment(state.date).subtract(1, "y").format("YYYY-MM-DD");
  }

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  useEffect(() => {
    const { userOrg } = user.claims;
    if (
      state.date ||
      state.date !== moment().format("YYYY-MM-DD") ||
      state.daily ||
      state.weekly ||
      state.monthly ||
      state.yearly
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${userOrg.orgId}/campaigns/${campaignId}?startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_SINGLE_CAMPAIGN_INFO,
        })
      );
    }
  }, [state]);

  const onLaunchCampaign = async () => {
    const { userOrg } = user.claims;
    const response = await apiService({
      path: `/clients/${userOrg.orgId}/campaigns/${campaignId}`,
      method: "PATCH",
      data: { status: "active", launchedAt: new Date().toISOString() },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        dispatch(
          reduxAction({
            method: "GET",
            path: `/clients/${userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
            actionType: FETCH_SINGLE_CAMPAIGN_INFO,
          })
        );
      },
    });
  };

  const recordsToDisplay = [
    {
      title: "Impressions",
      color: "warning",
      iconName: "visibility",
      value:
        singleCampaignStore.data.results &&
        singleCampaignStore.data.results.insights
          ? singleCampaignStore.data.results.insights.totalImpressions
          : 0,
    },
    {
      title: "Reach",
      color: "warning",
      iconName: "groups",
      value:
        singleCampaignStore.data.results &&
        singleCampaignStore.data.results.insights
          ? singleCampaignStore.data.results.insights.totalReaches
          : 0,
    },
    {
      title: "Engagement",
      color: "warning",
      iconName: "add_reaction",
      value:
        singleCampaignStore.data.results &&
        singleCampaignStore.data.results.insights
          ? singleCampaignStore.data.results.insights.totalEngagements
          : 0,
    },
    {
      title: "Time On Site (min)",
      color: "warning",
      iconName: "timelapse",
      value:
        singleCampaignStore.data.results &&
        singleCampaignStore.data.results.insights
          ? Math.floor(
              singleCampaignStore.data.results.insights.totalTimeOnSide / 60
            )
          : 0,
    },
  ];

  const { width } = useWindowDimensions();

  const isPendingCampaign =
    singleCampaignStore.data.results &&
    singleCampaignStore.data.results.campaignDetails
      ? singleCampaignStore.data.results.campaignDetails.status !== "active"
      : true;

  return (
    <div>
      {singleCampaignStore.loading ? (
        <Loading />
      ) : (
        <div>
          {singleCampaignStore.data.results ? (
            <>
              <CampaignComponent
                campaignData={
                  singleCampaignStore.data.results.campaignDetails || {}
                }
                handleLaunchCampaign={onLaunchCampaign}
                campaignId={campaignId}
              />
              {!isPendingCampaign && (
                <>
                  <TimePicker
                    startDate={startDate}
                    endDate={endDate}
                    onRangeChange={onRangeChange}
                    onDateChange={onDateChange}
                    lastSyncTime={
                      moment(
                        singleCampaignStore.data.results &&
                          singleCampaignStore.data.results.lastSyncTime
                      ).format("LLL") || "00:00 AM"
                    }
                  />
                  <RecordsComponent
                    isUpdating={
                      singleCampaignStore.loading &&
                      singleCampaignStore.data.results
                    }
                    records={recordsToDisplay}
                  />
                  <BannersContainer
                    data={singleCampaignStore.data.results.insights}
                    isUpdating={
                      singleCampaignStore.loading &&
                      singleCampaignStore.data.results
                    }
                    width={width}
                  />
                  <AdvertorialsContainer
                    data={singleCampaignStore.data.results.insights}
                    isUpdating={
                      singleCampaignStore.loading &&
                      singleCampaignStore.data.results
                    }
                  />
                </>
              )}
            </>
          ) : (
            <NoContent title="Can't Find Campaign" />
          )}
        </div>
      )}
    </div>
  );
};
