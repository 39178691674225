import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",

    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  onInputChange,
  content,
  disabled,
  formError,
  paymentOptions,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {paymentOptions.price ? (
        <div className={classes.inputElt}>
          <Typography variant="h6">
            Plan Type: {paymentOptions.planType}
          </Typography>
          <Typography variant="subtitle1">
            Amount: {paymentOptions.price} Rwf
          </Typography>
        </div>
      ) : (
        <div className={classes.groupInputElt}>
          <Select
            id="currency"
            value={content.currency}
            name="currency"
            onChange={onInputChange}
            disabled={disabled}
          >
            {paymentOptions.currency.map((elt) => (
              <MenuItem key={elt} value={elt}>
                {elt}
              </MenuItem>
            ))}
          </Select>

          <FormControl fullWidth>
            <InputLabel id="amount-label">Votes</InputLabel>
            <Select
              labelId="amount-label"
              id="amount"
              value={content.amount}
              label="Amount"
              name="amount"
              onChange={onInputChange}
              disabled={disabled}
            >
              <MenuItem value={0}>
                <span className={classes.selectTitle}>Choose Votes</span>
              </MenuItem>
              {paymentOptions[content.currency].voteOptions.map((option) => (
                <MenuItem key={option.amount} value={option.amount}>
                  {option.amount} {content.currency}: {option.votes} Votes
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      <div className={classes.inputElt}>
        <TextField
          id="email"
          label="Email"
          placeholder="Enter your email"
          onChange={onInputChange}
          value={content.email}
          disabled={disabled}
        />
      </div>

      <Typography className={classes.errorMsg} variant="subtitle1">
        {formError}
      </Typography>
      <br />
    </div>
  );
};
