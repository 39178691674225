import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import reduxAction from "../../../redux/action";
import Step2 from "../../components/ads/createAd/steps/Step2";
import { FETCH_SINGLE_CAMPAIGN_INFO } from "../../../redux/campaign/types";
import Loading from "../../../components/isLoading";
import { uploadCampaignImg } from "../../../redux/upload";
import {
  ADVERTORIAL_UPLOADING,
  BANNER_UPLOADING,
  POST_IMG_UPLOADING,
} from "../../../redux/upload/types";
import { folderDate } from "../../../helpers/helperMethods";
import Toast from "../../../components/MessageToast";
import apiService from "../../../services/apiService";
import { FETCH_SINGLE_AD } from "../../../redux/ads/types";
import { useHistory } from "react-router";
import Step3 from "./createAd/Step3";
import { Card, Container } from "@material-ui/core";

export default ({ advertId, campaignId }) => {
  const user = JSON.parse(localStorage.getItem("au")) || {};
  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const singleAdvertStore = useSelector((store) => store.singleAdvert);
  const uploadCampaignImgStore = useSelector(
    (store) => store.uploadCampaignImg
  );

  const [state, setState] = useState({
    selectedCampaign: {},
    loading: false,
    openToast: false,
    openPaymentForm: false,
  });
  const [bannerUpload, setBannerUpload] = useState({
    fullUrl: null,
    loading: false,
  });
  const [advertUpload, setAdvertUpload] = useState({
    fullUrl: null,
    loading: false,
  });

  const [postImgUpload, setPostImgUpload] = useState({
    fullUrl: null,
    loading: false,
  });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/ads/${advertId}`,
        actionType: FETCH_SINGLE_AD,
      })
    );
  }, []);

  const openToast = ({ message, type, onClose }) =>
    Toast({ message, type, duration: 2000, onClose });

  const onBannerUpload = async (banner) => {
    setBannerUpload({ ...bannerUpload, loading: true });

    const destination = `public/images/${selectedCampaign.name
      .replace(/\s/g, "")
      .substring(0, 6)}/banners/${folderDate}`;

    dispatch(
      uploadCampaignImg({ action: BANNER_UPLOADING, destination, data: banner })
    );

    setState({ ...state, openToast: true });
  };

  const onAdvertPhotoUpload = async (advert) => {
    const destination = `public_html/app/webroot/img/${folderDate}/images`;
    dispatch(
      uploadCampaignImg({
        action: ADVERTORIAL_UPLOADING,
        destination,
        data: advert.file,
        outside: true,
      })
    );

    setState({ ...state, openToast: true });
  };

  const onPostImgUpload = async (postImg) => {
    const destination = `public_html/app/webroot/img/${folderDate}/images`;
    dispatch(
      uploadCampaignImg({
        action: POST_IMG_UPLOADING,
        destination,
        data: postImg.file,
        outside: true,
      })
    );

    setState({ ...state, openToast: true });
  };

  const editAdvertorialAd = async (uploadedPhoto, advertorial) => {
    const fullUrl = uploadedPhoto.advertorial
      ? uploadedPhoto.advertorial.fullUrl
      : advertorial.photoUrl;
    const path = uploadedPhoto.advertorial
      ? uploadedPhoto.advertorial.path
      : advertorial.wallPhoto;

    const data = {
      ...advertorial,
      wallPhoto: path,
      photoUrl: fullUrl,
    };

    const response = await apiService({
      path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/adverts/${singleAdvertStore.data.results.advertorialRefId}`,
      method: "PATCH",
      data,
    });
    return response;
  };

  const editBannerAd = async (uploadedPhoto, banner) => {
    const data = {
      ...banner,
      url: uploadedPhoto.banner ? uploadedPhoto.banner.fullUrl : banner.url,
    };
    const response = await apiService({
      path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/banners/${singleAdvertStore.data.results.bannerRefId}`,
      method: "PATCH",
      data,
    });

    return response;
  };

  const editSocialMediaPostAd = async (uploadedPhoto, post) => {
    const fullUrl = uploadedPhoto.advertorial
      ? uploadedPhoto.advertorial.fullUrl
      : post.photoUrl;
    const path = uploadedPhoto.advertorial
      ? uploadedPhoto.advertorial.path
      : post.wallPhoto;

    const data = {
      ...post,
      wallPhoto: path,
      photoUrl: fullUrl,
      mentions: post.mentions.map((elt) => elt.value),
      tags: post.tags.map((elt) => elt.value),
    };

    const response = await apiService({
      path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/posts/${singleAdvertStore.data.results.advertorialRefId}`,
      method: "PATCH",
      data,
    });
    return response;
  };

  const handleCreateAd = async (data) => {
    setState({ ...state, loading: true });
    let response;

    switch (singleAdvertStore.data.results.adType) {
      case "banner":
        response = await editBannerAd(bannerUpload, data);
        break;

      case "advertorial":
        response = await editAdvertorialAd(advertUpload, data);
        break;

      case "facebookPost":
        response = await editSocialMediaPostAd(postImgUpload, data);
        break;
      case "instagramPost":
        response = await editSocialMediaPostAd(postImgUpload, data);
        break;
      default:
        response = { message: "Can not specify the action", error: true };
        setState({ ...state, loading: false });
        break;
    }
    if (response.message) {
      setState({ ...state, loading: false });
    }
    openToast({
      message: response.message,
      type: response.error ? "error" : "success",
      onClose: () => {
        if (!singleAdvertStore.data.results.isPaid) {
          setState({ ...state, openPaymentForm: true, loading: false });
        } else if (!response.error) {
          history.push("/ads");
        }
      },
    });
  };

  if (state.openToast) {
    const {
      banner = {},
      advertorial = {},
      postImg = {},
    } = uploadCampaignImgStore.data;

    if (
      (banner && banner.fullUrl) ||
      (advertorial && advertorial.fullUrl) ||
      (postImg && postImg.fullUrl)
    ) {
      openToast({
        message: uploadCampaignImgStore.error
          ? "Unable to upload Image"
          : "Image Uploaded Successfully",
        type: uploadCampaignImgStore.error ? "error" : "success",
        onClose: () => {
          setState({ ...state, openToast: false });
          setBannerUpload({ ...bannerUpload, ...banner });
          setAdvertUpload({ ...advertUpload, advertorial });
          setPostImgUpload({ ...postImgUpload, postImg });
        },
      });
    }
  }

  return singleAdvertStore.loading && !singleAdvertStore.data.results ? (
    <Loading />
  ) : (
    <div>
      {state.openPaymentForm ? (
        <Step3 advertData={singleAdvertStore.data.results || {}} />
      ) : (
        <Container justify="center" maxWidth="md">
          <Card
            style={{
              padding: "5%",
            }}
          >
            <Step2
              isEdit
              handleSave={handleCreateAd}
              loading={state.loading}
              content={singleAdvertStore.data.results || {}}
              onBannerUpload={onBannerUpload}
              onAdvertPhotoUpload={onAdvertPhotoUpload}
              onPostImgUpload={onPostImgUpload}
            />
          </Card>
        </Container>
      )}
    </div>
  );
};
