import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const style = {
  artistSelectFormControl: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },

  helperText: {
    color: '#5D5D5D',
    padding: '3px 10px',
  },
};
// to lean more about creatable select you may use : https://react-select.com/home#creatable

class CreatableSingle extends Component {
  handleChange = (newValue, actionMeta) => {
    this.props.onChange(newValue);
  };

  render() {
    const { classes, options, defaultValues, title, placeholder } = this.props;
    return (
      <div className={classes.artistSelectFormControl}>
        {title && (
          <Typography variant='caption' className={classes.helperText}>
            {title || ''}
          </Typography>
        )}
        <CreatableSelect
          isClearable
          isMulti
          placeholder={placeholder || 'Search....'}
          defaultValue={defaultValues}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          options={options}
        />
      </div>
    );
  }
}

export default withStyles(style)(CreatableSingle);
