import React, { Component, useState, useEffect } from "react";
import RecordsComponent from "../../../../components/dashboard/RecordsComponent";

const makeSum = (dataArr, prop) => {
  let sum = 0;
  dataArr.forEach((el) => {
    sum += el[prop];
  });
  return sum;
};

export default ({ isUpdating, role, recordsToDisplay, eventCategory }) => {
  const [recordsData, setRecordsData] = useState({
    totalVotes: 0,
    RWFRecord: {
      revenue: 0,
      votes: 0,
    },
    USDRecord: {
      revenue: 0,
      votes: 0,
    },
    EURRecord: {
      revenue: 0,
      votes: 0,
    },
    USSDRecord: {
      revenue: 0,
      votes: 0,
    },
  });
  const [recordEventData, setRecordEventData] = useState({
    totalTickets:0,
    RWFRecord: {
      revenue: 0,
      tickets: 0,
    },
    USDRecord: {
      revenue: 0,
      tickets: 0,
    },
    EURRecord: {
      revenue: 0,
      tickets: 0,
    },
    USSDRecord: {
      revenue: 0,
      tickets: 0,
    },
  })

  useEffect(() => {
    if (recordsToDisplay && recordsToDisplay.LatestOverallVotes) {
      const newState = {
        ...recordsData,
        totalVotes: recordsToDisplay.TotalVotes,
      };
      const RWFBucket =
        recordsToDisplay.LatestOverallVotes?.BucketSummary.filter(
          (elt) => elt.Currency === "RWF"
        );
      if (RWFBucket.length > 0) {
        newState.RWFRecord = {
          revenue: makeSum(RWFBucket, "Revenue"),
          votes: makeSum(RWFBucket, "Votes"),
        };
      }
      const USDBucket =
        recordsToDisplay.LatestOverallVotes?.BucketSummary.filter(
          (elt) => elt.Currency === "USD"
        );
      if (USDBucket.length > 0) {
        newState.USDRecord = {
          revenue: makeSum(USDBucket, "Revenue"),
          votes: makeSum(USDBucket, "Votes"),
        };
      }
      const EURBucket =
        recordsToDisplay.LatestOverallVotes?.BucketSummary.filter(
          (elt) => elt.Currency === "EUR"
        );
      if (EURBucket.length > 0) {
        newState.EURRecord = {
          revenue: makeSum(EURBucket, "Revenue"),
          votes: makeSum(EURBucket, "Votes"),
        };
      }
      const USSDBucket =
        recordsToDisplay.LatestOverallVotes?.BucketSummary.filter(
          (elt) => elt.BucketName === "USSD_RWF"
        );
      if (USSDBucket.length > 0) {
        newState.USSDRecord = {
          revenue: makeSum(USSDBucket, "Revenue"),
          votes: makeSum(USSDBucket, "Votes"),
        };
      }

      setRecordsData(newState);
    }
  }, [recordsToDisplay]);
  const records = [
    {
      title: eventCategory === "Concert" ? "Total Tickets" :"Total Votes",
      color: "warning",
      iconName: "how_to_vote",
      value: eventCategory === "Concert" ? recordEventData.totalTickets : recordsData.totalVotes,
    },
    {
      title: "Revenue (RWF)",
      color: "warning",
      iconName: "payments",
      value:  eventCategory === "Concert" ? recordEventData.RWFRecord.revenue : recordsData.RWFRecord.revenue,
      votes:  eventCategory === "Concert" ? recordEventData.RWFRecord.tickets : recordsData.RWFRecord.votes,
    },
    {
      title: "Revenue (USD)",
      color: "warning",
      iconName: "attach_money",
      value:  eventCategory === "Concert" ? recordEventData.USDRecord.revenue : recordsData.USDRecord.revenue,
      votes:  eventCategory === "Concert" ? recordEventData.USDRecord.tickets : recordsData.USDRecord.votes,
    },
    {
      title: "Revenue (EUR)",
      color: "warning",
      iconName: "euro_symbol",
      value:  eventCategory === "Concert" ? recordEventData.EURRecord.revenue : recordsData.EURRecord.revenue,
      votes:  eventCategory === "Concert" ? recordEventData.EURRecord.tickets : recordsData.EURRecord.votes,
    },
    {
      title: "Revenue (USSD)",
      color: "warning",
      iconName: "dialpad",
      value:  eventCategory === "Concert" ? recordEventData.USSDRecord.revenue : recordsData.USSDRecord.revenue,
      votes:  eventCategory === "Concert" ? recordEventData.USSDRecord.tickets : recordsData.USSDRecord.votes,
    },
  ];
  return (
    <>
      <RecordsComponent eventCategory={eventCategory} role={role} records={records} isUpdating={isUpdating} />
    </>
  );
};
