import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import DashboardContainer from "../containers/dashboard/index";
import HeaderSelect from "../containers/headerSelect/headerSelect";

export class Dashboard extends Component {
  render() {
    const userString = localStorage.getItem("au");
    const user = JSON.parse(userString) || {};

    const { claims } = user;

    return (
      <AppLayout>
        {claims.userOrg.canViewStats && <HeaderSelect />}
        <DashboardContainer />
      </AppLayout>
    );
  }
}

export default Dashboard;
