import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../assets/images/placeholder.png";
import itemInfoStyles from "../../../assets/styles/components/itemInfoStyles";
import Tag from "../../../components/tag";
import SingleItemMenu from "../../../components/Menu/SingleItemMenu";
import { Edit, Launch } from "@material-ui/icons";
import { successColor } from "../../../assets/styles/base";
import Chip from "@material-ui/core/Chip";
import { formatDate } from "../../../helpers/helperMethods";

const styles = makeStyles((theme) => itemInfoStyles(theme));

export default (props) => {
  const { campaignData, campaignId, handleLaunchCampaign } = props;

  const history = useHistory();
  const classes = styles();

  const actions = [
    {
      onClick: () => history.push(`/campaigns/${campaignId}/edit`),
      text: "Edit Campaign",
      icon: <Edit fontSize="small" />,
    },
    {
      onClick: () => handleLaunchCampaign(),
      text: "Launch Campaign",
      icon: <Launch fontSize="small" />,
    },
  ];

  return (
    <div>
      <Grid container className={classes.topSection}>
        <Grid item xs={12} container justify="flex-start">
          <div>
            <ProgressiveImage
              src={
                campaignData.adBanners && campaignData?.adBanners[0]
                  ? campaignData.adBanners[0].bannerUrl ||
                    campaignData.adBanners[0].url
                  : undefined
              }
              placeholder={props.placeholder || defaultImg}
              key={
                campaignData.adBanners && campaignData?.adBanners[0]
                  ? campaignData.adBanners[0].bannerUrl ||
                    campaignData.adBanners[0].url
                  : ""
              }
            >
              {(src, loading) => (
                <img
                  style={{
                    opacity: loading ? 0.7 : 1,
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                  src={src}
                  alt="No cover"
                  className={classes.image}
                />
              )}
            </ProgressiveImage>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          spacing={2}
          className={classes.infoSection}
        >
          <Grid item className={classes.textInfo}>
            <Typography variant="h4" className={classes.texts}>
              {campaignData.name}
            </Typography>
            <Grid item className={classes.actions}>
              <Grid container spacing={2} justify="flex-end">
                <SingleItemMenu horiz background menu={actions} />
              </Grid>
            </Grid>

            {campaignData.startDate && (
              <Tooltip title="Campaign Period" placement="top-start" arrow>
                <Typography variant="h6" className={classes.name}>
                  {formatDate(campaignData.startDate)} -{" "}
                  {formatDate(campaignData.endDate)}
                </Typography>
              </Tooltip>
            )}

            <Typography variant="h6" className={classes.texts}>
              <Chip
                label={campaignData.status}
                style={{
                  backgroundColor: `${successColor[0]}`,
                  color: "#fff",
                  size: "100px",
                }}
              />
            </Typography>

            <Typography variant="body1" className={classes.texts}>
              Category: {campaignData.category || "Unset"}
            </Typography>

            <Typography variant="body1" className={classes.texts}>
              Objectives
              <br />
              <span>
                {campaignData.objectives
                  ? campaignData.objectives.map((objective, index) => (
                      <span key={index}>
                        <Tag key={objective} content={objective} />
                        {(index + 1) % 3 === 0 && <br />}
                      </span>
                    ))
                  : "Unset"}
              </span>
            </Typography>

            <Typography variant="body1" className={classes.texts}>
              Platforms:
              <br />
              <Typography variant="body1" className={classes.name}>
                {campaignData.targetPlatforms
                  ? campaignData.targetPlatforms.map((platform) => (
                      <span>{platform}, </span>
                    ))
                  : "Unset"}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
