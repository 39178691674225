import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../../assets/styles/base";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmationDialog";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AdvertorialsComponent from "./Advertorials";
import AddAdvertorial from "./AddAdvertorial";
import PromoteDialog from "../promoteDialog";

const userStyles = makeStyles((theme) => ({
  advertAvatar: {
    height: theme.spacing(14),
    width: theme.spacing(14),
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  card: {
    padding: "3vh 5%",
  },
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    float: "right",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },
  coverPhoto: {
    height: theme.spacing(30),
    width: theme.spacing(30),
  },
}));

export default ({
  onAdvertPhotoUpload,
  advertUploadState,
  onCreateOrEditAdvert,
  campaignAdverts,
  onPromote,
  onAdvertPauseOrDelete,
  campaignId,
}) => {
  const classes = userStyles();
  const advImageRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("au"));
  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };

  const [state, setState] = useState({
    promoteModel: false,
    selectedAdvert: {},
    advertModel: false,
    confirmModel: false,
    showSecondComponent: false,
    showBackForward: false,
    action: "",
  });

  const [advertState, setAdvertState] = useState({});

  const toggleModel = (model, item, action) => {
    setState({
      ...state,
      [model]: !state[model],
      selectedAdvert: item,
      action,
      showSecondComponent: false,
      showBackForward: false,
    });

    if (action === "create") {
      setAdvertState({});
      setState({
        ...state,
        showSecondComponent: true,
        showBackForward: true,
        action,
      });
    }
    if (action === "edit") {
      setAdvertState({
        ...item,
      });
      setState({
        ...state,
        showSecondComponent: true,
        showBackForward: true,
        action,
        selectedAdvert: item,
      });
    }

    if (action === "pause" || action === "delete") {
      setState({
        ...state,
        [model]: !state[model],
        selectedAdvert: item,
        action,
        confirmMessage: `Confirm ${action} will make this advertorial ${
          action === "pause"
            ? "pause display on target platform, you can resume by promoting it again"
            : "disappear completely from the campaign"
        }`,
        confirmTitle: `Confirm ${action} advertorial`,
        confirmText: action,
      });
    }
  };

  const onPromoteConfirm = (data) => {
    onPromote({ ...data, ...state.selectedAdvert }, "advert");
    toggleModel("promoteModel");
  };

  const onPromoteCancel = () => {
    toggleModel("promoteModel");
  };

  const onPauseOrDelete = () => {
    onAdvertPauseOrDelete(state.selectedAdvert, state.action);
    toggleModel("confirmModel");
  };
  const handleForwardArrow = () =>
    setState({
      ...state,
      showSecondComponent: true,
    });
  const handleBackArrow = () =>
    setState({
      ...state,
      showSecondComponent: false,
    });

  return (
    <div>
      <Grid container justify="space-between" direction="row">
        <div>
          {state.showBackForward && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleBackArrow}
                className={classes.menuButton}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleForwardArrow}
                className={classes.menuButton}
              >
                <ChevronRightIcon />
              </IconButton>
            </>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          onClick={() => toggleModel("advertModel", {}, "create")}
        >
          Create advert
        </Button>
      </Grid>

      {state.showSecondComponent ? (
        <AddAdvertorial
          toggleModel={toggleModel}
          onCreateOrEditAdvert={onCreateOrEditAdvert}
          advertUploadState={advertUploadState}
          setAdvertState={setAdvertState}
          onAdvertPhotoUpload={onAdvertPhotoUpload}
          state={state}
          advertState={advertState}
        />
      ) : (
        <AdvertorialsComponent
          campaignAdverts={campaignAdverts}
          toggleModel={toggleModel}
          campaignId={campaignId}
          userInfo={userInfo}
        />
      )}

      <Box>
        <ConfirmDialog
          open={state.confirmModel}
          title={state.confirmTitle}
          message={state.confirmMessage}
          confirmText={state.confirmText}
          onCancel={() => toggleModel("confirmModel")}
          onConfirm={onPauseOrDelete}
        />
        <PromoteDialog
          title={"Select target platforms"}
          open={state.promoteModel}
          onPromote={onPromoteConfirm}
          onPromoteCancel={onPromoteCancel}
        />
      </Box>
    </div>
  );
};
