import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import CreateCampaignContainer from "../../containers/campaigns/CreateCampaignContainer";

export class CreateCampaign extends Component {
  render() {
    return (
      <AppLayout>
        <CreateCampaignContainer />
      </AppLayout>
    );
  }
}

export default CreateCampaign;
