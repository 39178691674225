import React, { Component } from 'react';
import AppLayout from '../../../layouts/AppLayout';
import TrafficContainer from '../../containers/performance/TrafficContainer';
import HeaderSelect from '../../containers/headerSelect/headerSelect';

export class Traffic extends Component {
  render() {
    return (
      <AppLayout>
        <HeaderSelect />
        <TrafficContainer />
      </AppLayout>
    );
  }
}

export default Traffic;
