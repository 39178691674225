import React, { useState } from "react";
import Edit from "@material-ui/icons/Edit";
import PaymentIcon from "@material-ui/icons/Payment";
import { Avatar, Chip } from "@material-ui/core";
import {
  capitalizeFirstLetter,
  createData,
  formatNumber,
} from "../../../../helpers/helperMethods";
import DataTableComponent from "../../../components/data/DataTableComponent";
import ListItemMenu from "../../../../components/Menu/ListItemMenu";
import Payment from "../../payment/Payment";
import { dangerColor, successColor } from "../../../../assets/styles/base";

export default (props) => {
  const {
    isUpdating,
    data,
    notActive,
    noContentCustomElements,
    onLaunchEvent,
    onDeleteEvent,
  } = props;

  const defaultMenuItems = (elt) => [
    {
      icon: <Edit fontSize="small" />,
      primary: "Edit",
      link: `/campaign/${elt.campaignId}/ads/${elt.id}/edit`,
    },
  ];
  const [state, setState] = useState({
    openPaymentModel: false,
    entityData: {},
  });

  const headCells = [
    {
      id: "name",
      rightAlign: false,
      label: "Name",
    },
    {
      id: "adType",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Advertisement Type",
    },
    {
      id: "campaign",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Campaign",
    },
    {
      id: "paymentPlan",
      rightAlign: true,
      label: "Payment plan",
    },
    {
      id: "actions",
      rightAlign: true,
      label: "Actions",
    },
  ];
  const handlePayment = (entityData) => {
    setState({ ...state, openPaymentModel: true, entityData });
  };
  const handleClosePayment = () => {
    setState({ ...state, openPaymentModel: false, entityData: {} });
  };
  const rows = data
    ? data.map((elt) =>
        createData(
          elt.id,
          {
            content1: (
              <Avatar
                alt={elt.id || "Img"}
                src={elt.content?.photoUrl || elt.content?.url}
                variant="rounded"
              />
            ),
            content2:
              elt.adType && elt.adType.toLowerCase() === "advertorial"
                ? elt.content?.title || ""
                : elt.adType && elt.adType.toLowerCase() === "banner"
                ? "Banner"
                : "Post",
            url: `/campaign/${elt.campaignId}/ads/${elt.id}`,
          },
          capitalizeFirstLetter(elt.adType),
          elt.campaignId,
          <Chip
            label={elt.isPaid ? formatNumber(elt.plan.amount) : "Unpaid"}
            style={{
              backgroundColor: elt.isPaid
                ? `${successColor[0]}`
                : `${dangerColor[0]}`,
              color: "#fff",
              size: "100px",
            }}
          />,
          <ListItemMenu
            menu={
              elt.isPaid
                ? [...defaultMenuItems(elt)]
                : [
                    ...defaultMenuItems(elt),
                    {
                      icon: <PaymentIcon fontSize="small" />,
                      primary: "Pay for Ad",
                      onClick: () => handlePayment(elt),
                      hidden: notActive,
                    },
                  ]
            }
          />
        )
      )
    : [];

  return (
    <div>
      <Payment
        entityData={state.entityData}
        isOpen={state.openPaymentModel}
        handleClosePayment={handleClosePayment}
      />
      <DataTableComponent
        isClickableHey
        hasLongTitles
        notActive={notActive}
        isUpdating={isUpdating}
        rows={rows}
        headCells={headCells}
        title="Events"
        noContentCustomElements={noContentCustomElements}
      />
    </div>
  );
};
