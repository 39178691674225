import phpHttp from '../config/storageApiHandle';

export const uploadToStorageService = async (url, data) => {
  try {
    const response = await phpHttp.post(url, data);
    return { response: response.data };
  } catch (error) {
    return {
      error: true,
      errorMessage: error.response
        ? (error.response.data.message !== '' && error.response.data.message) ||
          'Resource not found at the moment'
        : 'Unable to connect to our storage at the moment',
    };
  }
};

export const removeFileFromStorage = async (path) => {
  try {
    const response = await phpHttp.delete('/files', {
      data: { filePath: path },
    });
    return { response: response.data };
  } catch (error) {
    return {
      error: true,
      errorMessage: error.response
        ? error.response.data
          ? error.response.data.message
          : '😢 Unable to connect to our storage at the moment'
        : '😢 Unable to connect to our storage at the moment',
    };
  }
};
