import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchBarStyle from '../assets/styles/components/searchBarStyle';

function SearchBar({ ...props }) {
  const { classes } = props;

  const onInputChange = (e) => {
    const term = e.target.value;
    props.onChange(term);
  };

  return (
    <div className={classes.searchComponent}>
      <div className={classes.flex}>
        <div component='NavBar' className={classes.searchContainer}>
          <InputBase
            className={classes.input}
            placeholder={props.placeholder?`${props.placeholder}.....`:'Search.....'}
            inputProps={{ roboto: props.placeholder?`${props.placeholder}.....`:'Search.....' }}
            onChange={onInputChange}
            onKeyPress={props.onKeyPress}
            value={props.value}
          />
          <IconButton
            type='submit'
            className={classes.iconButton}
            aria-label='search'
            onClick={props.onSubmit}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

export default withStyles(SearchBarStyle)(SearchBar);
