import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER } from "../../../redux/publishers/types";
import Loading from "../../../components/isLoading";
import RecordsComponent from "../../components/dashboard/RecordsComponent";
import TimePicker from "../../components/date/TimePickerComponent";
import CampaignsTableContainer from "../campaigns/allCampaigns/dataContainer";
import CampaignsAdvertorialsContainer from "../campaigns/allCampaigns/AdvertorialsContainer";
import EventsContainer from "./eventsDataContainer";
import {
  CreateButton,
  handleDates,
  useWindowDimensions,
} from "../../../helpers/helperMethods";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import { Button } from "@material-ui/core";
import { FETCH_ALL_CAMPAIGNS_INFO } from "../../../redux/campaign/types";
import { useHistory } from "react-router";
import { primaryColor, whiteColor } from "../../../assets/styles/base";
import ViewMore from "../../../components/ViewMore";
import { FETCH_ALL_EVENTS } from "../../../redux/events/types";

export default ({ user }) => {
  const dispatch = useDispatch();
  const selectedPublishersStore = useSelector((state) => state.selectPublisher);
  const history = useHistory();

  const allCampaignsStore = useSelector((store) => store.allCampaigns);
  const events = useSelector((store) => store.allEvents);

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
    organization: user.claims.userOrg,
  };

  const { startDate, endDate } = handleDates(state);

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/events`,
        actionType: FETCH_ALL_EVENTS,
      })
    );
  }, []);

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  useEffect(() => {
    if (
      !allCampaignsStore.data.results ||
      (state.date && state.date.startDate) ||
      state.date !== moment().format("YYYY-MM-DD") ||
      state.daily ||
      state.weekly ||
      state.monthly ||
      state.yearly
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${userInfo.organization.orgId}/campaigns?startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_ALL_CAMPAIGNS_INFO,
        })
      );
    }
  }, [state]);

  const recordsToDisplay = [
    {
      title: "Campaigns",
      color: "warning",
      icon: <RssFeedIcon />,
      value:
        allCampaignsStore.data.results &&
        allCampaignsStore.data.results.campaigns.length,
    },
    {
      title: "Events",
      color: "warning",
      icon: <EventAvailableIcon />,
      value: events.data.results && events.data.results.length,
    },
  ];

  const handleClickCreateCampaign = () => history.push("/events/new");

  return (
    <div>
      {allCampaignsStore.loading && !allCampaignsStore.data.results ? (
        <Loading />
      ) : (
        <>
          <RecordsComponent
            role={userInfo.role}
            isUpdating={
              allCampaignsStore.loading && allCampaignsStore.data.results
            }
            records={recordsToDisplay}
          />
          <div>
            <EventsContainer
              isUpdating={events.loading && events.data.results}
              data={events.data.results}
              noContentCustomElements={
                <CreateButton
                  handleOnClick={handleClickCreateCampaign}
                  text="Create New Event"
                />
              }
            />
            <ViewMore url="/events" />
          </div>
          <div>
            <CampaignsTableContainer
              isUpdating={
                allCampaignsStore.loading && allCampaignsStore.data.results
              }
              data={allCampaignsStore.data.results}
              noContentCustomElements={
                <CreateButton handleOnClick={handleClickCreateCampaign} />
              }
            />
            <ViewMore url="/campaigns" />
          </div>
          <CampaignsAdvertorialsContainer
            noTable
            data={allCampaignsStore.data.results}
            isUpdating={
              allCampaignsStore.loading && allCampaignsStore.data.results
            }
          />
        </>
      )}
    </div>
  );
};
