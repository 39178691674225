import {
  GET_ALL_CLIENTS,
  GET_CLIENT_INFO,
  GET_CLIENT_PENDIND_INVITE,
  GET_PUBLISHERS
} from './types';

const initialState = {
  loading: false,
  data: {},
};

export default class ClientsReducers {
  static getAllClients(state = initialState, action) {
    switch (action.type) {
      case `${GET_ALL_CLIENTS}_PENDING`:
        return { ...state, loading: true };
      case `${GET_ALL_CLIENTS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_ALL_CLIENTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static getClientInfo(state = initialState, action) {
    switch (action.type) {
      case `${GET_CLIENT_INFO}_PENDING`:
        return { ...state, loading: true };
      case `${GET_CLIENT_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_CLIENT_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static getClientPendingInvite(state = initialState, action) {
    switch (action.type) {
      case `${GET_CLIENT_PENDIND_INVITE}_PENDING`:
        return { ...state, loading: true };
      case `${GET_CLIENT_PENDIND_INVITE}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_CLIENT_PENDIND_INVITE}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static getPublishers(state = initialState, action) {
    switch (action.type) {
      case `${GET_PUBLISHERS}_PENDING`:
        return { ...state, loading: true };
      case `${GET_PUBLISHERS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_PUBLISHERS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
