import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Upload from "@material-ui/icons/CloudUpload";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import ListMenuComponent from "../../../../components/Menu/ListItemMenu";
import ImagePlaceholder from "../../../../assets/images/placeholder.png";
import { useHistory } from "react-router";
import apiService from "../../../../services/apiService";

const useStyles = makeStyles((theme) => ({
  bannerAvatar: {
    height: theme.spacing(14),
    width: theme.spacing(14),
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  card: {
    padding: "3vh 5%",
  },
}));

export default ({ campaignBanners, toggleModel, campaignId, userInfo }) => {
  const classes = useStyles();
  const history = useHistory();

  const onPromote = async (data) => {
    const reqData = {
      adType: "banner",
      content: { ...data },
      plan: {},
    };
    const response = await apiService({
      path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/ads`,
      method: "POST",
      data: reqData,
    });

    if (response.results) {
      history.push(`/campaign/${campaignId}/ads/${response.results.adId}/edit`);
    }
  };

  return (
    <Card className={classes.card}>
      <Grid container justify="center" direction="column">
        <Typography variant="h6" align="center">
          Ad Banners
        </Typography>

        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
        >
          {campaignBanners.map((banner, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    alt="Remy Sharp"
                    src={banner.url || ImagePlaceholder}
                    className={classes.bannerAvatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    banner.caption
                      ? "Caption: " + banner.caption
                      : "Caption: Not set"
                  }
                  secondary={
                    <React.Fragment>
                      <br />
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                      >
                        Target: {banner.dynamic ? "dynamic" : "static"}-
                        {banner.mobile ? "mobile" : "desktop"}-{banner.page}@
                        {banner.section}
                        <br />
                        Status: {banner.status || "pending"}
                      </Typography>{" "}
                      <br />
                      Link: {banner.link || "Not set"}
                      <br />
                      Created: {moment(banner.createdAt).fromNow()}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <ListMenuComponent
                    menu={[
                      {
                        primary: "Edit",
                        icon: <Edit fontSize="small" />,
                        onClick: () =>
                          toggleModel("bannerModel", banner, "edit"),
                      },
                      {
                        primary: "Promote",
                        icon: <Upload fontSize="small" />,
                        hidden: banner.status === "promoted",
                        // onClick: () => onPromote(banner),
                        // toggleModel("promoteModel", , "promote"),
                        onClick: () =>
                          toggleModel("confirmModel", banner, "promote"),
                      },
                      {
                        primary: "Pause",
                        icon: <Upload fontSize="small" />,
                        hidden: banner.status === "paused",
                        onClick: () =>
                          toggleModel("confirmModel", banner, "pause"),
                      },
                      {
                        primary: "Delete",
                        icon: <Delete fontSize="small" />,
                        hidden: banner.status === "promoted",
                        onClick: () =>
                          toggleModel("confirmModel", banner, "delete"),
                      },
                    ]}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </Card>
  );
};
