import { primaryColor } from "../base";

const titleCommon = {
  fontSize: "18px",
  textAlign: "center",
  borderRadius: "6px 6px 0 0",
  padding: "5px 0",
};

const defaultRoot = {
  margin: "10px 0",
  borderRadius: "8px",
  padding: 20,
};

const priceCardStyle = (theme) => ({
  root: {
    ...defaultRoot,
    border: "1px solid #dadce0",
    color: "#3c4043",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${primaryColor[0]}`,
      color: primaryColor[0],
    },
  },
  selectedRoot: {
    ...defaultRoot,
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
    border: `2px solid ${primaryColor[0]}`,
    color: primaryColor[0],
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  price: {
    fontSize: "32px",
    fontWeight: "500",
  },

  title: {
    ...titleCommon,
    fontSize: "24px",

    [theme.breakpoints.down("sm")]: {
      padding: "5px 0",
    },
  },
  features: {
    padding: " 0 10px",
  },
  feature: {
    fontSize: "18px",
  },
  action: {
    padding: "20px 0",
    alignItems: "center",
  },
  icon: {
    fontSize: "14px",
  },
});

export default priceCardStyle;
