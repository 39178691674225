import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import MediaCreativesContainer from "../containers/mediaCreatives/all";
import MediaCreativeContainer from "../containers/mediaCreatives/MediaCreative";

export class MediaCreatives extends Component {
  render() {
    const creativeId = this.props.match.params.creativeId;

    return creativeId ? (
      <AppLayout hasBack>
        <MediaCreativeContainer creativeId={creativeId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <MediaCreativesContainer />
      </AppLayout>
    );
  }
}

export default MediaCreatives;
