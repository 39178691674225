import reducers from "./reducers";
import { selectPublisherAction } from "./actions";

const organizationReducers = {
  selectPublisher: reducers.selectPublisher,
  allPublishers: reducers.allPublishersReducer,
  singlePublisher: reducers.singlePublisherReducer,
  publisherCountryInfo: reducers.singlePublisherCountryInfoReducer,
  demographicsInfo: reducers.singlePublisherDemographicsInfoReducer,
  technologyInfo: reducers.singlePublisherTechnologyInfoReducer,
};

export { selectPublisherAction, organizationReducers };
