import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CustomInput from "../../../../components/CustomInput/TextField";
import ImagePlaceholder from "../../../../assets/images/placeholder.png";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import { capitalizeFirstLetter } from "../../../../helpers/helperMethods";
import Button from "../../../../components/Buttons/customButton";
import JoditEditor from "../../../../components/JoditEditor";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vh 5%",
    backgroundColor: "#fff",
  },
}));

export default ({
  toggleModel,
  advertState,
  onCreateOrEditAdvert,
  state,
  setAdvertState,
  onAdvertPhotoUpload,
  hideSubmitBtn,
  title,
  disabled,
}) => {
  const classes = useStyles();
  const uploadCampaignImgStore = useSelector(
    (store) => store.uploadCampaignImg
  );

  const onInputFieldsChange = (e) => {
    setAdvertState({
      ...advertState,
      [e.target.name]: e.target.value,
    });
  };

  const onContentChange = (content) => {
    setAdvertState({
      ...advertState,
      content: content,
    });
  };

  const onUploadImage = (file) => {
    onAdvertPhotoUpload({ file });
  };

  const onAdvertConfirm = () => {
    onCreateOrEditAdvert(
      {
        title: advertState.title,
        summary: advertState.summary,
        content: advertState.content,
      },
      state.action,
      state.selectedAdvert,
      uploadCampaignImgStore.data
    );
    toggleModel("advertModel");
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Typography variant="h6">
        {title
          ? title
          : state.action === "create"
          ? "Create a Advertorial for the campaign"
          : `${capitalizeFirstLetter(
              state.action ? state.action : "action"
            )} Advertorial`}
      </Typography>
      <br />
      <CustomInput
        label="Title"
        value={advertState.title || ""}
        name="title"
        id="advertTitle"
        fullWidth
        onChange={onInputFieldsChange}
        disabled={disabled}
      />
      <br />
      <CustomInput
        label="Summary"
        value={advertState.summary || ""}
        id="advertSummary"
        name="summary"
        fullWidth
        multiline
        rows={2}
        onChange={onInputFieldsChange}
        disabled={disabled}
      />
      <br />
      <Box>
        <Typography variant="subtitle1">Cover photo</Typography>

        <ImageUpload
          isSmall
          disabled={disabled}
          defaultImage={
            advertState.photoUrl ? advertState.photoUrl : ImagePlaceholder
          }
          imageSubmit={onUploadImage}
          imageTypes={
            "image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico"
          }
          addButtonProps={{
            color: "primary",
          }}
          changeButtonProps={{
            color: "primary",
          }}
          removeButtonProps={{
            color: "danger",
          }}
          isUploading={uploadCampaignImgStore.loading}
        />
        <Typography color="textSecondary">{advertState.imageError}</Typography>
      </Box>
      <br />
      <Typography variant="subtitle1">Advert content</Typography>
      <JoditEditor
        onContentChange={onContentChange}
        content={advertState.content || ""}
        disabled={disabled}
      />
      {!hideSubmitBtn && (
        <Grid item md={5}>
          <Button
            variant="contained"
            color="primary"
            className={classes.createButton}
            onClick={onAdvertConfirm}
          >
            Submit
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
