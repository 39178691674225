import React, { Component } from "react";

// core components
import Wizard from "../../../components/Wizard/Wizard";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

class WizardView extends Component {

  handleOnSubmit=(data)=>{
      this.props.onSubmit(data);
  }
  
  render() {
  const {title,steps,isLoading,isEdit,descriptionAllowed,defaultData}=this.props;
    return (
      <GridContainer >
       <GridItem xs={12} sm={11} md={8}>
          <Wizard
            {...this.props}
            isEdit={isEdit?true:false}
            descriptionAllowed={descriptionAllowed?true:false}
            validate
            steps={steps}
            title={title}
            defaultValues={defaultData}
            finishButtonClick={this.handleOnSubmit}
            isLoading={isLoading}
            nextButtonText={isEdit?'Continue to edit':'Save and Continue'}
            finishButtonText={isEdit?'Save changes':'Finish'}
          />
       </GridItem>
      </GridContainer>
    );
  }
}

export default WizardView;
