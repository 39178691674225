import {
  SELECT_PUBLISHER,
  FETCH_PUBLISHERS,
  FETCH_PUBLISHER,
  FETCH_PUBLISHER_COUNTRY_INFO,
  FETCH_PUBLISHER_DEMOGRAPHICS_INFO,
  FETCH_PUBLISHER_TECHNOLOGY_INFO,
} from "./types";

const { claims } = JSON.parse(localStorage.getItem("au")) || {};

const initialState = {
  loading: false,
  selectedPublisher: "inyarwanda1626080588028",
  publisherName: "Inyarwanda",
  chartSelectedDimension: "facebook",
  genderChartSelectedDimension: "facebook",
};

const initialState2 = {
  loading: false,
  data: {},
};

export default class PublisherReducers {
  static selectPublisher(state = initialState, action) {
    switch (action.type) {
      case `${SELECT_PUBLISHER}`:
        return {
          ...state,
          loading: false,
          selectedPublisher: action.payload.selectPublisher
            ? action.payload.selectPublisher
            : state.selectPublisher,
          chartSelectedDimension: action.payload.chartSelectedDimension
            ? action.payload.chartSelectedDimension
            : state.chartSelectedDimension,
          genderChartSelectedDimension: action.payload
            .genderChartSelectedDimension
            ? action.payload.genderChartSelectedDimension
            : state.genderChartSelectedDimension,
        };
      default:
        return state;
    }
  }

  static allPublishersReducer(state = initialState2, action) {
    switch (action.type) {
      case `${FETCH_PUBLISHERS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PUBLISHERS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PUBLISHERS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static singlePublisherReducer(state = initialState2, action) {
    switch (action.type) {
      case `${FETCH_PUBLISHER}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PUBLISHER}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PUBLISHER}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static singlePublisherCountryInfoReducer(state = initialState2, action) {
    switch (action.type) {
      case `${FETCH_PUBLISHER_COUNTRY_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PUBLISHER_COUNTRY_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PUBLISHER_COUNTRY_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static singlePublisherDemographicsInfoReducer(state = initialState2, action) {
    switch (action.type) {
      case `${FETCH_PUBLISHER_DEMOGRAPHICS_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PUBLISHER_DEMOGRAPHICS_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PUBLISHER_DEMOGRAPHICS_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static singlePublisherTechnologyInfoReducer(state = initialState2, action) {
    switch (action.type) {
      case `${FETCH_PUBLISHER_TECHNOLOGY_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PUBLISHER_TECHNOLOGY_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PUBLISHER_TECHNOLOGY_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
