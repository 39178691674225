import React from 'react';
import Box from "@material-ui/core/Box";
import { Grid, List, Card, Typography, ListItem, ListItemText, Collapse, Button, ListSubheader,ListItemSecondaryAction, Divider } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { primaryColor } from "../../../assets/styles/base";
import IsLoading from "../../../components/isLoading";
import { fullUrlAction } from "../../../redux/action";
import {OFFLINE_BATCH_TICKETS,} from "../../../redux/events/types";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import fullUrlApiService from "../../../services/fullUrlService";
import Toast from "../../../components/MessageToast";

const userStyles = makeStyles((theme) => ({
  card: {
    padding: "3vh 7%",
  },
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },

  checkBox: {
    "&.Mui-checked": {
      color: primaryColor[0],
    },
  },
  TicketsInput: {
    display: "flex",
    width: "100%",
    "@media(max-width:1300px)": {
      flexDirection: "column",
      margin: "10px 0px 10px 0px",
      width: "100%",
    },
  },
  listItem: {
    paddingRight:"30px",
    paddingLeft:"30px",
    '&:hover':{
      backgroundColor:"transparent"
    }
  },
  childList: {
    paddingRight:"0px",
    paddingLeft:"0px",
    justifyContent:"space-between",
    '&:hover':{
      backgroundColor:"transparent"
    }
  },
  
  Itemcontainer: {
    maxWidth: "50%",
    '@media(max-width: 600px)':{
      maxWidth:"100%"
    }
  },
  batchesList:{
    width:"100%",
    maxHeight:"500px",
    overflow:"auto",
    '@media(max-width: 600px)':{
      width:"100%"
    }
  },
  actions: {
    display:"block",
    position:"absolute", top:"50%",
    '@media(max-width:968px)':{
      top:"50%"
    },
    '@media(max-width:600px)':{
      display:'none'
    }
  }
}));

export default function TicketBatches({event}) {
  const classes = userStyles();
  const dispatch = useDispatch();
  const batches = useSelector(state => state.offlineTicketBatches);
  const currentUser = useSelector((store) => store.currentUser);
    const downloadTickets = useSelector((store) => store.donwloadTIckets);


  const [batchDetail, setBatchDetail] = React.useState([]);

  React.useEffect(() => {
    const tempBatchDetails = !batches.loading && batches.data.results && batches.data.results.map(batch => {

      const batchDetail = [];

      for (const detail of batch.batchDetail) {
        const index = batchDetail.map(x => x.name).indexOf(detail.name);
        if (index !== -1) {
          batchDetail[index].quantity += detail.quantity;
        } else {
          batchDetail.push(detail);
        }
      }

      batch.batchDetail = batchDetail;

      return batch;
      
    });
    setBatchDetail(tempBatchDetails? tempBatchDetails : []);
  },[batches]);
  

     const [open, setOpen] = React.useState(false);
    React.useEffect(()=>{
      dispatch(
        fullUrlAction({
          method:"GET",
          path: `/events/${event.id}/batch_tickets`,
          actionType: OFFLINE_BATCH_TICKETS
        })
      )
    },[])

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);
     const [collapse, setCollapse] = React.useState(true);
     const [selectedIndex, setSelectedIndex] = React.useState(true);

  const handleCollapse = (index) => {
    if(index === selectedIndex){
      setSelectedIndex("")
      setCollapse(!collapse);
    }else{
      setSelectedIndex(index)
      setCollapse(!collapse);
    }
  };

   const onDownloadTickets = async (batchId) => {
    Toast({
      message:
        "We are generating your tickets, don't close the tab until you get notified...",
      type: "warning",
      duration: 10000,
    });
      const response = await fullUrlApiService({
      path: `${process.env.NONEHO_REPORTING_API}/tickets/offline?eventId=${event.id}&batchId=${batchId}&email=${currentUser.email}`,
      method: "GET",
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        if (!response.error) {
          window.open(response.results.link, "_blank");
        }
      },
    });
  };
  const sortedData = !batches.loading && batches.data.results &&batches.data.results;
  let data=[];
  if(Array.isArray(sortedData)){
    for (let i = 0; i < Math.floor(sortedData/ 2); i++) {
    // switch array elements from the end and the beginning
    
      [sortedData[i], sortedData[sortedData.length - 1 - i]] = [
        sortedData[sortedData.length - 1 - i],
        sortedData[i],
      ];
    }
    data = [...sortedData].reverse();
  }
  
  const numTickets = data.map(tickets => {
    let quantity= 0;

    for(const elt of tickets.batchDetail){
      const index = batchDetail.map(x => x.name).indexOf(elt.name);
      if(index !==-1){
        batchDetail[index].quantity += elt.quantity;
      }else{
        quantity += elt.quantity;
      }

    }
    return quantity;
  })

  return (
    <div>
      <Card>
        <Grid container alignItems='center' justifyContent='center'
        >
          <Grid item>
            <Typography variant='h6' align='start'>Tickets in Batches</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <List className={classes.batchesList}>
                {batches.loading && <IsLoading/>}
              {data.map((batch, index)=>{
                return (<><ListItem key={index} className={classes.listItem}>
                  <ListItemText style={{justifyContent:"space-between"}}
                    primary={`${batch.batchId}`}
                    secondary= {
                    <Box className={classes.Itemcontainer}>
                      <ListSubheader className={classes.listItem}>
                        <ListItem className={classes.childList}>
                          <div style={{display:"flex", alignItems:"center", cursor:"pointer"}} onClick={()=>{
                            handleCollapse(index)}}>
                            <ListItemText  primary={`${numTickets[index]} Tickets`}/>{index === selectedIndex ? <ExpandMore style={{cursor:"pointer"}} /> : <ExpandLess style={{cursor:"pointer"}}/>}
                          </div>
                          <ListItemText style={{flex:"0 0 auto"}} primary={`Date: ${new Date(batch.createdAt).toDateString()}`}/>
                        </ListItem>
                        <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                          <List dense="dense" component="div" disablePadding>
                            {batch.batchDetail.map((item, itemIndex)=>{
                              
                              return(<ListItem button key={itemIndex} className={classes.nested}>
                              <ListItemText primary={`${item.quantity} ${item.name} Tickets`} />
                            </ListItem>)})}
                            
                          </List>
                        </Collapse>
                      </ListSubheader>
                      
                    </Box>} 
                  />
                  <ListItemSecondaryAction className={classes.actions} >
                    <Button
                      variant="contained"
                      disabled={batch.downloaded}
                      color="primary"
                      className={classes.createButton}
                      onClick={()=> onDownloadTickets(batch.batchId)}
                    >
                      Download Batch
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/></>)
              }
                
              )}
            </List>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}