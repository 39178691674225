import reducers from "./reducers";

const advertsReducers = {
  allAdverts: reducers.allAdvertsReducer,
  pendingAdverts: reducers.pendingEventsReducer,
  singleAdvert: reducers.singleAdvertReducer,
  bannersSections: reducers.adsBannersSections,
};

export { advertsReducers };
