import React, { useState } from "react";
import Loading from "../../../../components/isLoading";
import NoContent from "../../../../components/NoContent";
import {
  formatChartData,
  tableRowsDataFormat,
} from "../../../../helpers/formatChartData";
import { Avatar, Typography } from "@material-ui/core";

import MultiRecordsChartComponent from "../../../components/stats/MultiRecordsChart";
import GridContainer from "../../../../components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../../assets/styles/components/chartStyle";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import DataTableComponent from "../../../components/data/DataTableComponent";
import { createData } from "../../../../helpers/helperMethods";
import moment from "moment";
import HeaderSelect from "../../../../components/CustomSelect/HeaderSelect";

const useStyles = makeStyles(styles);

export default (props) => {
  const classes = useStyles();
  const { isUpdating, data, noTable } = props;

  const dimensions = [
    { id: "instagram", name: "Instagram" },
    { id: "inyarwanda.com", name: "Inyarwanda.com" },
    { id: "facebook", name: "Facebook" },
  ];

  const [state, setState] = useState({
    selectedDimension: "inyarwanda.com",
    dataFor: "campaign-advertorials",
  });

  const headCells =
    state.selectedDimension === "inyarwanda.com"
      ? [
          {
            id: "column1",
            rightAlign: false,
            label: "Title",
          },
          {
            id: "column2",
            rightAlign: true,
            doNotShowInMobile: true,
            label: "Time On Site (min)",
          },
          {
            id: "column3",
            rightAlign: true,
            label: "Engagement",
          },
          {
            id: "column4",
            rightAlign: true,
            doNotShowInMobile: true,
            label: "Post Date",
          },
        ]
      : [
          {
            id: "column1",
            rightAlign: false,
            label: "Title",
          },
          {
            id: "column2",
            rightAlign: true,
            doNotShowInMobile: true,
            label: "Impressions",
          },
          {
            id: "column3",
            rightAlign: true,
            doNotShowInMobile: true,
            label: "Reach",
          },
          {
            id: "column4",
            rightAlign: true,
            label: "Engagement",
          },
          {
            id: "column5",
            rightAlign: true,
            doNotShowInMobile: true,
            label: "Post Date",
          },
        ];

  const rows = data ? tableRowsDataFormat(data, state.dataFor) : [];

  const handleDimensionChange = (selected) => {
    setState({
      ...state,
      selectedDimension: selected,
      dataFor:
        selected === "inyarwanda.com"
          ? "campaign-advertorials"
          : `${selected}-posts`,
    });
  };

  return isUpdating ? (
    <Loading />
  ) : (
    <>
      {data ? (
        <>
          <Card>
            <CardBody>
              <div className={classes.headContainer}>
                <Typography className={classes.title}>Advertorials</Typography>
                <span className={classes.dimensionSelector}>
                  {dimensions && (
                    <HeaderSelect
                      onChangeMenu={(selected) =>
                        handleDimensionChange(selected)
                      }
                      value={state.selectedDimension}
                      headerMenus={dimensions}
                    />
                  )}
                </span>
              </div>

              <GridContainer>
                <MultiRecordsChartComponent
                  data={formatChartData(data, state.dataFor)}
                />
              </GridContainer>
            </CardBody>
          </Card>

          {!noTable && (
            <DataTableComponent
              hasLongTitles
              hasNoPagination
              isUpdating={isUpdating}
              rows={rows}
              headCells={headCells}
            />
          )}
        </>
      ) : (
        <NoContent title={"No Content"} />
      )}
    </>
  );
};
