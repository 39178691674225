import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import CampaignsContainer from "../../containers/campaigns/allCampaigns";
import CampaignContainer from "../../containers/campaigns/CampaignContainer";

export class Campaigns extends Component {
  render() {
    const campaignId = this.props.match.params.campaignId;

    return campaignId ? (
      <AppLayout hasBack>
        <CampaignContainer campaignId={campaignId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <CampaignsContainer />
      </AppLayout>
    );
  }
}

export default Campaigns;
