export const FETCH_ALL_EVENTS = "FETCH_ALL_EVENTS";
export const FETCH_SINGLE_EVENT_INFO = "FETCH_SINGLE_EVENT_INFO";
export const FETCH_PENDING_EVENTS = "FETCH_PENDING_EVENTS";
export const FETCH_GROUPED_CONTESTANTS_SUMMARY =
  "FETCH_GROUPED_CONTESTANTS_SUMMARY";
export const FETCH_EVENT_STATISTICS = "FETCH_EVENT_STATISTICS";
export const PRINT_OFFLINE_TICKETS = "PRINT_OFFLINE_TICKETS";
export const DOWNLOAD_OFFLINE_TICKETS = "DOWNLOAD_OFFLINE_TICKETS";
export const OFFLINE_BATCH_TICKETS = "OFFLINE_BATCH_TICKETS";
export const UPLOAD_INVITATIONS = "UPLOAD_INVITATIONS";
export const GENERATE_INVITATIONS = "GENERATE_INVITATIONS";
export const EVENT_INVITATION_BATCHES = "EVENT_INVITATION_BTCHES";
