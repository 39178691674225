import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/isLoading";
import RecordsComponent from "../../../components/dashboard/RecordsComponent";
import TimePicker from "../../../components/date/TimePickerComponent";
import moment from "moment";
import reduxAction from "../../../../redux/action";
import {
  FETCH_ALL_CAMPAIGNS_INFO,
  FETCH_PENDING_CAMPAIGNS,
} from "../../../../redux/campaign/types";
import { useHistory } from "react-router";
import DataContainer from "./dataContainer";
import BannersContainer from "./BannersContainer";
import AdvertorialsContainer from "./AdvertorialsContainer";
import {
  CreateButton,
  useWindowDimensions,
} from "../../../../helpers/helperMethods";

export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isPendingCampaigns = history.location.pathname.includes("pending");

  const allCampaignsStore = useSelector((store) =>
    isPendingCampaigns ? store.pendingCampaigns : store.allCampaigns
  );

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const user = JSON.parse(localStorage.getItem("au")) || {};

  const userInfo = {
    email: user.email,
    organization: user.claims.userOrg,
  };

  let startDate = moment(
    state.date && state.date.startDate ? state.date.startDate : state.date
  )
    .subtract(6, "d")
    .format("YYYY-MM-DD");

  let endDate = moment(
    state.date && state.date.endDate ? state.date.endDate : state.date
  ).format("YYYY-MM-DD");

  if (state.daily) {
    startDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
    endDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
  } else if (state.weekly) {
    startDate = moment(state.date).subtract(7, "d").format("YYYY-MM-DD");
  } else if (state.monthly) {
    startDate = moment(state.date).subtract(1, "M").format("YYYY-MM-DD");
  } else if (state.yearly) {
    startDate = moment(state.date).subtract(1, "y").format("YYYY-MM-DD");
  }

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  useEffect(() => {
    if (
      !allCampaignsStore.data.results ||
      (state.date && state.date.startDate) ||
      state.date !== moment().format("YYYY-MM-DD") ||
      state.daily ||
      state.weekly ||
      state.monthly ||
      state.yearly ||
      isPendingCampaigns ||
      isPendingCampaigns === false
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: isPendingCampaigns
            ? `/clients/${userInfo.organization.orgId}/campaigns?status=pending`
            : `/clients/${userInfo.organization.orgId}/campaigns?startDate=${startDate}&endDate=${endDate}`,
          actionType: isPendingCampaigns
            ? FETCH_PENDING_CAMPAIGNS
            : FETCH_ALL_CAMPAIGNS_INFO,
        })
      );
    }
  }, [state, isPendingCampaigns]);

  const recordsToDisplay = [
    {
      title: "Impressions",
      color: "warning",
      iconName: "visibility",
      value:
        allCampaignsStore.data.results &&
        allCampaignsStore.data.results.totalImpressions,
    },
    {
      title: "Reach",
      color: "warning",
      iconName: "groups",
      value:
        allCampaignsStore.data.results &&
        allCampaignsStore.data.results.totalReaches,
    },
    {
      title: "Engagement",
      color: "warning",
      iconName: "add_reaction",
      value:
        allCampaignsStore.data.results &&
        allCampaignsStore.data.results.totalEngagements,
    },
    {
      title: "Time On Site (min)",
      color: "warning",
      iconName: "timelapse",
      value: allCampaignsStore.data.results
        ? Math.floor(allCampaignsStore.data.results.totalTimeOnSide / 60)
        : 0,
    },
  ];

  const { width } = useWindowDimensions();

  const handleClickCreateCampaign = () => history.push("/campaigns/new");

  return (
    <div>
      {(allCampaignsStore.loading && !allCampaignsStore.data.results) ||
      (isPendingCampaigns &&
        allCampaignsStore.loading &&
        allCampaignsStore.data.results) ? (
        <Loading />
      ) : (
        <>
          <TimePicker
            startDate={startDate}
            endDate={endDate}
            onRangeChange={onRangeChange}
            onDateChange={onDateChange}
            lastSyncTime={
              moment(
                allCampaignsStore.data.results &&
                  allCampaignsStore.data.results.lastSyncTime
              ).format("LLL") || "00:00 AM"
            }
          />
          <CreateButton
            styles={{ float: "right" }}
            handleOnClick={handleClickCreateCampaign}
          />
          {!isPendingCampaigns && (
            <RecordsComponent
              isUpdating={
                allCampaignsStore.loading && allCampaignsStore.data.results
              }
              records={recordsToDisplay}
            />
          )}
          <DataContainer
            isUpdating={
              allCampaignsStore.loading && allCampaignsStore.data.results
            }
            data={allCampaignsStore.data.results}
            notActive={isPendingCampaigns}
            noContentCustomElements={
              <CreateButton handleOnClick={handleClickCreateCampaign} />
            }
          />
          {!isPendingCampaigns && (
            <BannersContainer
              data={allCampaignsStore.data.results}
              isUpdating={
                allCampaignsStore.loading && allCampaignsStore.data.results
              }
              width={width}
            />
          )}
          {!isPendingCampaigns && (
            <AdvertorialsContainer
              data={allCampaignsStore.data.results}
              isUpdating={
                allCampaignsStore.loading && allCampaignsStore.data.results
              }
            />
          )}
        </>
      )}
    </div>
  );
};
