import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import identityService from "../services/identityService";
import { auth } from "../config/firebase";
import { loginWithCustomToken } from "../services/authService";
import Loading from "../components/isLoading";
import FixedAlert from "../components/alert";
import { setCurrentUser } from "../redux/auth/index";
import currentUserClaims from "../utils/currentUserClaims";

export class AuthProvider extends Component {
  state = {
    loading: true,
  };

  handleAuthCheck = async () => {
    const resp = await identityService({
      path: "/auth/status",
      method: "GET",
    });
    if (resp.error && resp.status === 401) {
      window.location.assign(
        `${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
      );
    } else if (resp.error) {
      this.setState({ setupComplete: true, loading: false });
    } else {
      const currentUser = JSON.parse(localStorage.getItem("au"));
      if (
        !currentUser ||
        (resp.results && currentUser.uid !== resp.results.uid)
      ) {
        this.setState({ firstTime: true });
        const user = await loginWithCustomToken(
          resp.results && resp.results.customToken
        );
        if (user.error) {
          this.setState({
            error: user.message,
            loading: false,
            setupComplete: true,
          });
        } else this.setState({ setupComplete: true });
      } else {
        this.setState({ setupComplete: true });
      }
    }
  };

  componentDidMount() {
    if (!localStorage.getItem("autoReload")) {
      this.handleAuthCheck();
    } else {
      localStorage.removeItem("autoReload");
    }
    this.onAuthChange = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          const userData = await currentUser.getIdTokenResult(true);

          const user = {
            displayName: currentUser.displayName,
            email: currentUser.email,
            photo: currentUser.photoURL,
            uid: currentUser.uid,
            phoneNumber: currentUser.phoneNumber,
            emailVerified: currentUser.emailVerified,
            claims: await currentUserClaims(userData.claims),
          };
          localStorage.setItem(
            "au",
            JSON.stringify({
              ...user,
              atkn: userData.token,
            })
          );
          this.props.setCurrentUser(user);
          if (this.state.firstTime) {
            localStorage.setItem("autoReload", "on first time");
            window.location.reload();
          } else this.setState({ loading: false });
        } catch (error) {
          console.log(error);
          this.setState({ loading: false });
        }
      } else {
        if (this.state.setupComplete) {
          window.location.assign(
            `${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
          );
        } else {
          this.setState({
            loading: !this.state.setupComplete,
            error:
              currentUser && !currentUser.emailVerified
                ? "Your account is not verified"
                : this.state.setupComplete &&
                  "Authentication failed, retry or contact administrator",
          });
        }
      }
    });

    setInterval(async () => {
      const token = await auth.currentUser.getIdToken(true);
      const currentUser = JSON.parse(localStorage.getItem("au"));
      currentUser.atkn = token;
      localStorage.setItem("au", JSON.stringify(currentUser));
    }, 56 * 60 * 1000);
  }

  componentWillUnmount() {
    this.onAuthChange();
  }
  render() {
    return (
      <div>
        {this.state.loading ? (
          <Box padding={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Loading />
              <Typography align="center">Please wait a moment...</Typography>
            </Grid>
          </Box>
        ) : this.state.error && this.state.setupComplete ? (
          <Box padding={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <FixedAlert title={this.state.error} type="warning" />
            </Grid>
          </Box>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
