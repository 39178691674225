import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Upload from "@material-ui/icons/CloudUpload";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import ListMenuComponent from "../../../../components/Menu/ListItemMenu";
import ImagePlaceholder from "../../../../assets/images/placeholder.png";
import apiService from "../../../../services/apiService";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  advertAvatar: {
    height: theme.spacing(14),
    width: theme.spacing(14),
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  card: {
    padding: "3vh 5%",
  },
}));

export default ({ campaignAdverts, toggleModel, campaignId, userInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const onPromote = async (data) => {
    const reqData = {
      adType: "advertorial",
      content: { ...data },
      plan: {},
    };

    const response = await apiService({
      path: `/clients/${userInfo.organization.orgId}/campaigns/${campaignId}/ads`,
      method: "POST",
      data: reqData,
    });

    if (response.results) {
      history.push(`/campaign/${campaignId}/ads/${response.results.adId}/edit`);
    }
  };

  return (
    <Card className={classes.card}>
      <Typography variant="h6" align="center">
        Advertorials
      </Typography>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {campaignAdverts &&
          campaignAdverts.map((adv, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    alt="Advert"
                    src={adv.photoUrl || ImagePlaceholder}
                    className={classes.advertAvatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="h6"
                      >
                        {adv.title}
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <br />
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                      >
                        {adv.summary}
                      </Typography>
                      <br />
                      Created: {moment(adv.createdAt).fromNow()}
                      <br />
                      Status: {adv.status || "pending"}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <ListMenuComponent
                    menu={[
                      {
                        primary: "Edit",
                        icon: <Edit fontSize="small" />,
                        onClick: () => toggleModel("advertModel", adv, "edit"),
                      },
                      {
                        primary: "Promote",
                        icon: <Upload fontSize="small" />,
                        hidden: adv.status === "promoted",
                        onClick: () => onPromote(adv),
                        // toggleModel("promoteModel", , "promote"),
                      },
                      {
                        primary: "Pause",
                        icon: <Upload fontSize="small" />,
                        hidden: adv.status === "paused",
                        onClick: () =>
                          toggleModel("confirmModel", adv, "pause"),
                      },
                      {
                        primary: "Delete",
                        icon: <Delete fontSize="small" />,
                        hidden: adv.status === "promoted",
                        onClick: () =>
                          toggleModel("confirmModel", adv, "delete"),
                      },
                    ]}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
      </List>
    </Card>
  );
};
