import React, { Component } from 'react';
import AppLayout from '../../../layouts/AppLayout';
import EditCampaignContainer from '../../containers/campaigns/EditCampaignContainer';

export class CreateCampaign extends Component {
  render() {
    const campaignId = this.props.match.params.campaignId;
    return (
      <AppLayout>
        <EditCampaignContainer campaignId={campaignId} />
      </AppLayout>
    );
  }
}

export default CreateCampaign;
