import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../../assets/styles/base";
import Button from "../../../../components/Buttons/customButton";
import ListItemMenu from "../../../../components/Menu/ListItemMenu";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	InputAdornment,
	OutlinedInput,
} from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

const useStyles = makeStyles((theme) => ({
	createButton: {
		backgroundColor: primaryColor[0],
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: primaryColor[0],
		},
	},
	coverPhoto: {
		height: theme.spacing(30),
		width: theme.spacing(30),
	},
	card: {
		padding: 10,
	},
}));

export default ({
	onChangeComponentClick,
	contestants,
	countries,
	onRemoveContestant,
	onEditContestant,
}) => {
	const classes = useStyles();

	const [country, setCountry] = React.useState("");
	const [sortedContestants, setSortedContestant] = useState(contestants);

	const handleSearchChange = (e) => {
		setSortedContestant(
			contestants.filter((el) =>
				el.firstName.toLowerCase().includes(e.target.value.toLowerCase())
			)
		);
	};

	const handleCountryChange = ({ target: { value } }) => {
		console.log(value);
		setSortedContestant(
			contestants.filter(
				(el) => el.countryCode?.toLowerCase() === value.toLowerCase()
			)
		);
	};

	return (
		<Card className={classes.card}>
			<Typography variant="h6" align="center">
				Event contestants
			</Typography>

			<Box style={{ textAlign: "right" }}>
				<Box>
					<FormControl
						style={{ m: 1, width: "25ch", marginTop: 10, marginRight: 10 }}
						variant="outlined"
					>
						<InputLabel htmlFor="outlined-basic">Search contestant</InputLabel>
						<OutlinedInput
							id="outlined-basic"
							type="text"
							onChange={handleSearchChange}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
									>
										<SearchTwoToneIcon />
									</IconButton>
								</InputAdornment>
							}
							label="Search Contestant"
						/>
					</FormControl>
					<FormControl
						variant="outlined"
						style={{ m: 1, minWidth: 200, marginTop: 10, marginRight: 10 }}
					>
						<InputLabel id="demo-simple-select-standard-label">
							Country
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							// value={country}
							onChange={handleCountryChange}
							label="Country"
							style={{ textAlign: "start" }}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{countries.map((country, index) => (
								<MenuItem key={index} value={country.countryCode}>
									{country.countryName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
			</Box>

			<List>
				{sortedContestants &&
					sortedContestants.map((el) => (
						<ListItem key={el.id}>
							<ListItemAvatar>
								<Avatar src={el.photo} variant="square" alt="Photo" />
							</ListItemAvatar>
							<ListItemText
								primary={`${el.firstName} ${el.lastName}`}
								secondary={`${el.category || ""} ${
									el.countryName ? " | " + el.countryName : ""
								} ${el.contestantCode ? " | " + el.contestantCode : ""}`}
							/>
							<ListItemSecondaryAction>
								<ListItemMenu
									menu={[
										{
											icon: <Edit fontSize="small" />,
											primary: "Edit",
											onClick: () => onChangeComponentClick("edit", el),
										},
										{
											icon: <BlockIcon fontSize="small" />,
											primary: el.eliminated ? "Uneliminate" : "Eliminate",
											onClick: () =>
												onEditContestant({
													id: el.id,
													eliminated: !el.eliminated,
												}),
										},
										{
											icon: <Delete fontSize="small" />,
											primary: "Delete",
											onClick: () => onRemoveContestant(el.id),
										},
									]}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					))}
			</List>

			<Grid
				container
				justify="flex-end"
				style={{
					position: "fixed",
					right: 30,
					bottom: 30,
				}}
			>
				<IconButton
					variant="contained"
					color="primary"
					className={classes.createButton}
					onClick={() => onChangeComponentClick("add")}
				>
					<Add />
				</IconButton>
			</Grid>
		</Card>
	);
};
