import React, { useEffect } from "react";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import { useDispatch, useSelector } from "react-redux";
import { settingsAction } from "../../../redux/settings/index";
import { FETCH_ORGANIZATIONS } from "../../../redux/organizations/types";
import HeaderSelecter from "../../components/Organizations/HeaderSelecter";

export default (props) => {
  const dispatch = useDispatch();
  const allOrgs = useSelector((state) => state.allOrganizations);

  useEffect(() => {
    if (!allOrgs.data.results)
      dispatch(
        settingsAction({
          method: "GET",
          path: "api/orgs",
          actionType: FETCH_ORGANIZATIONS,
        })
      );
  }, []);

  const onOrgChange = (selectedOrg) => {
    const user = JSON.parse(localStorage.getItem("au"));
    const { userOrg, userApp } = user.claims;
    user.claims.userOrg = {
      ...userOrg,
      orgId: selectedOrg.id,
      name: selectedOrg.name,
      role: userApp.role,
      canViewStats: selectedOrg.canViewStats,
      logo: selectedOrg.logoSrc,
    };
    localStorage.setItem("au", JSON.stringify(user));
    window.location.assign("/");
  };

  return (
    <HeaderSelecter
      onOrgChange={onOrgChange}
      value={"Inyarwanda"}
      organizations={allOrgs.data.results || []}
    />
  );
};
