import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

export default ({ url }) => (
  <Link
    to={url}
    style={{
      textDecoration: "none",
      color: "#000",
    }}
  >
    <Typography
      variant="body1"
      align="center"
      style={{ padding: "10px 0", fontSize: "18px" }}
    >
      View more....
    </Typography>
  </Link>
);
