import React, { Component } from 'react';
import AppLayout from '../../../layouts/AppLayout';
import EditEventContainer from '../../containers/events/EditEventContainer';

export class EventEvent extends Component {
  render() {
    const eventId = this.props.match.params.eventId;
    return (
      <AppLayout>
        <EditEventContainer eventId={eventId} />
      </AppLayout>
    );
  }
}

export default EventEvent;
