import React from "react";
import { Typography } from "@material-ui/core";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import DescriptionIcon from "@material-ui/icons/Description";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { makeStyles } from "@material-ui/styles";
import { primaryColor } from "../../../../../assets/styles/base";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: "15px",
  },
  allTypes: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    color: "#3c4043",
    cursor: "pointer",

    "@media (max-width: 600px)": {
      justifyContent: "space-evenly",
    },
  },
  typeItem: {
    textAlign: "center",
    border: `1px solid  #dadce0`,
    padding: "10px",
    borderRadius: "6px",
    width: "130px",
    margin: "5px",
    "&:hover": {
      border: `1px solid ${primaryColor[0]}`,
      color: primaryColor[0],
    },
    "@media (max-width: 980px)": {
      width: "100px",
      margin: "5px",
    },
  },
  selectedItem: {
    textAlign: "center",
    border: `2px solid  ${primaryColor[0]}`,
    color: primaryColor[0],
    padding: "10px",
    borderRadius: "6px",
    width: "130px",
    margin: "5px",
    "@media (max-width: 980px)": {
      width: "100px",
      margin: "5px",
    },
  },
}));

export default ({ selectedAdType, onChange }) => {
  const classes = useStyles();

  const adTypes = [
    {
      name: "Facebook Post",
      value: "socialMediaPost",
      platform: "facebook",
      icon: <FacebookIcon style={{ fontSize: 50, color: "#395085" }} />,
    },
    {
      name: "Instagram Post",
      value: "socialMediaPost",
      platform: "instagram",
      icon: <InstagramIcon style={{ fontSize: 50, color: "#ff3570" }} />,
    },
    {
      name: "Banner",
      value: "banner",
      icon: (
        <PhotoSizeSelectActualIcon style={{ fontSize: 50, color: "#2997d0" }} />
      ),
    },
    {
      name: "Advertorial",
      value: "advertorial",
      icon: <DescriptionIcon style={{ fontSize: 50, color: "#2BC16E" }} />,
    },
  ];
  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {selectedAdType.name
          ? `Chosen: ${selectedAdType.name}`
          : "Choose Ad Type"}
      </Typography>

      <div className={classes.allTypes}>
        {adTypes.map((adType) => (
          <div
            key={adType.name}
            className={
              selectedAdType.platform &&
              selectedAdType.platform === adType.platform
                ? classes.selectedItem
                : selectedAdType.value === adType.value &&
                  !selectedAdType.platform
                ? classes.selectedItem
                : classes.typeItem
            }
            onClick={() => onChange(adType)}
          >
            <div>{adType.icon}</div>
            <Typography>{adType.name}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
