import { Avatar, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { createData, formatNumber } from "../../../../../helpers/helperMethods";
import DataTableComponent from "../../../../components/data/DataTableComponent";

const makeSum = (dataArr, prop) => {
  let sum = 0;
  dataArr.forEach((el) => {
    sum += el[prop];
  });
  return sum;
};
export class ArticlesTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { contestantsData, isUpdating, role, noSearch } = this.props;

    const headCells = [
      { id: "name", rightAlign: false, label: "Name" },
      { id: "votes", rightAlign: true, label: "Votes" },
      { id: "revenue", rightAlign: true, label: "Revenues" },
    ];
    const rows = contestantsData.map((elt) => {
      const usdAmount =
        elt.votingBucket.filter((elt) => elt.Currency === "USD").length > 0
          ? makeSum(
              elt.votingBucket.filter((elt) => elt.Currency === "USD"),
              "Revenue"
            )
          : 0;
      const rwfAmount =
        elt.votingBucket.filter((elt) => elt.Currency === "RWF").length > 0
          ? makeSum(
              elt.votingBucket.filter((elt) => elt.Currency === "RWF"),
              "Revenue"
            )
          : 0;

      return createData(
        elt.id,
        {
          content1: (
            <Avatar alt={elt.name || "Img"} src={elt.photo} variant="rounded" />
          ),
          content2: elt.name,
        },
        formatNumber(elt.votes),
        <Typography variant="body2">
          {`  RWF: ${formatNumber(rwfAmount)} | USD: ${formatNumber(
            usdAmount
          )}`}
        </Typography>
      );
    });

    return (
      <DataTableComponent
        noSearch={noSearch}
        role={role}
        isUpdating={isUpdating}
        rows={rows}
        headCells={headCells}
      />
    );
  }
}

export default ArticlesTableContainer;
