import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";

export default ({
  fullWidth,
  label,
  id,
  type,
  StartAdornment,
  EndAdornment,
  multiline,
  onChange,
  value,
  disabled,
  name,
  rows,
  helperText,
  placeholder,
}) => {
  return (
    <Box style={{ width: "100%" }}>
      <FormControl fullWidth={fullWidth}>
        <InputLabel htmlFor={id || "custom-input"}>{label}</InputLabel>
        <Input
          id={id || "custom-input"}
          name={name}
          type={type || "text"}
          multiline={multiline || false}
          onChange={onChange}
          value={value}
          disabled={disabled}
          fullWidth={fullWidth}
          rows={rows || 1}
          placeholder={placeholder}
          startAdornment={
            StartAdornment && (
              <InputAdornment position="start">
                <StartAdornment />
              </InputAdornment>
            )
          }
          endAdornment={
            EndAdornment && (
              <InputAdornment position="end">
                <EndAdornment />
              </InputAdornment>
            )
          }
        />
        {helperText && (
          <FormHelperText id="standard-weight-helper-text">
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
