import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText } from "@material-ui/core";

export default ({ label, value, menuArr = [], onChange, id, error }) => {
  return (
    <FormControl variant="standard" error={error !== ""} fullWidth>
      <InputLabel
        id="Category"
        style={{ color: error !== "" ? "red" : "#000" }}
      >
        {label || "Select"}
      </InputLabel>
      <Select
        labelId={id || label}
        id={id || label}
        name={id || label}
        value={value}
        onChange={onChange}
        label={label}
        style={{
          "&:.MuiSelect-selectMenu": { display: "flex", alignItems: "center" },
        }}
      >
        {menuArr.length > 0 ? (
          menuArr.map((elt) => (
            <MenuItem
              key={elt.value}
              value={elt.value}
              checked={value === elt.value}
            >
              {elt.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No Content</MenuItem>
        )}
      </Select>
      <FormHelperText>{error !== "" ? error : null}</FormHelperText>
    </FormControl>
  );
};
