import { Avatar, Typography } from "@material-ui/core";
import React, { Component} from "react";
import { createData, formatNumber } from "../../../../../helpers/helperMethods";
import DataTableConcertComponent from "../../../../components/data/DataTableConcertComponent";

const makeSum = (dataArr, prop) => {
  let sum = 0;
  dataArr.forEach((el) => {
    sum += el[prop];
  });
  return sum;
};
export class ArticlesTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { ConcertData, concertTiers, isUpdating, role, noSearch } = this.props;

    const headCells = [
      { id: "name", rightAlign: false, label: "Vender Name" },
      { id: "revenue", rightAlign: true, label: "Revenues" },
    ];
    Array.prototype.insert = function ( index, ...items ) {
    this.splice( index, 0, ...items );
};
    const data = concertTiers?.map(tickets => tickets);
    concertTiers?.map((tier) => headCells.insert(1, {id:tier.name.replaceAll(" ", "_"), rightAlign:true, label: `${tier.name} sold Tickets`} ));

    const rows = ConcertData?.map((elt) => {
      const dataValue = elt.ticketTiers.reduce((a, b) => ({...a, [b.name.replaceAll(" ", "_")]:b}), {})
    
       const usdAmount =
        elt.ticketTiers.map(item => makeSum(item.currencyBuckets.filter(el => el.currency === "USD"),"totalTicketSales")).reduce((acc, value) =>{return acc + value},0)
      const rwfAmount =
       elt.ticketTiers.map(item => makeSum(item.currencyBuckets.filter(el => el.currency === "RWF"),"totalTicketSales")).reduce((acc, value) =>{return acc + value},0)
      const eurAmount =
       elt.ticketTiers.map(item => makeSum(item.currencyBuckets.filter(el => el.currency === "EUR"),"totalTicketSales")).reduce((acc, value) =>{return acc + value},0)

      const params =headCells.map((cell)=> {
        if(cell.id === "name") return {
          content1: (
            <Avatar alt={elt.name || "Img"} src={elt.photo} variant="rounded" />
          ),
          content2: elt.name,
        };
        if(cell.id === "revenue") return <Typography variant="body2">
          {`  RWF:  ${formatNumber(rwfAmount)} | USD: ${formatNumber(usdAmount)} | EUR: ${formatNumber(eurAmount)}`}
        </Typography>;
        const values = dataValue[cell.id]
        return values ? `${formatNumber(values?.totalTicketsSold)} / ${formatNumber(values?.totalSeats)} ` : "-";
      })
      return createData(
        elt.id,
       ...params
      );
    });
    return (
      <DataTableConcertComponent
        noSearch={noSearch}
        role={role}
        isUpdating={isUpdating}
        rows={rows}
        headCells={headCells}
      />
    );
  }
}

export default ArticlesTableContainer;
