import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Toast from "../../../components/MessageToast";
// core-components
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CustomInput from "../../../components/CustomInput/TextField";
import CustomButton from "../../../components/Buttons/customButton";
import apiService from "../../../services/apiService";

export default (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    name: null,
  });

  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("au"));
    const { userOrg, userApp } = user.claims;
    setCurrentUser({
      uid: user.uid,
      email: user.email,
      userApp,
      userOrg,
    });
  }, []);

  const handleChange = (e) => {
    setState({
      name: e.target.value,
    });
  };

  const handleSave = async () => {
    setState({
      ...state,
      loading: true,
    });
    const response = await apiService({
      path: `/clients/${currentUser.userOrg.orgId}/campaigns`,
      method: "POST",
      data: { name: state.name },
    });
    if (response.error) {
      Toast({
        message: response.message,
        type: "error",
        duration: 4000,
        onClose: () => {
          setState({
            ...state,
            loading: false,
          });
        },
      });
    } else {
      setState({
        ...state,
        loading: false,
      });
      history.push(`/campaigns/${response.results.campaignId}/edit`);
    }
  };

  return (
    <div>
      <Container justify="center" maxWidth="sm">
        <Card>
          <CardHeader title="Create Campaign"></CardHeader>
          <CardContent>
            <CustomInput
              label="Campaign Name"
              fullWidth
              onChange={handleChange}
            />
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton color="primary" onClick={handleSave}>
              {state.loading ? "Saving..." : "Save and continue"}
            </CustomButton>
          </CardActions>
        </Card>
      </Container>
    </div>
  );
};
