import { Avatar } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { primaryColor } from "../assets/styles/base";
import { createData, formatNumber } from "./helperMethods";

export const formatChartData = (data, dataFor) => {
  let labels = [],
    datasets = [];

  const config_a = {
    backgroundColor: primaryColor[0],
    borderWidth: 1,
  };

  const config_b = {
    backgroundColor: "#D07318",
    borderWidth: 1,
  };

  const config2 = {
    backgroundColor: "#1876D1",
    borderWidth: 1,
  };

  const config3 = {
    backgroundColor: "#641d48",
    borderWidth: 1,
  };

  switch (dataFor) {
    case "page-impression": {
      const sortedData = data.facebook
        ? data.facebook.datesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");
      datasets = [
        {
          label: `${"Page Impressions"}`,
          data:
            data.facebook &&
            data.facebook.datesSummary.map((item) => item.page_impressions),
          ...config_a,
        },
        {
          label: `${"Total Views"}`,
          data:
            data.facebook &&
            data.facebook.datesSummary.map(
              (item) => parseInt(item.page_views_total) * 100
            ),
          ...config_b,
        },
      ];
      break;
    }

    case "posts-likes-comments": {
      const sortedData = data.instagram
        ? data.instagram.datesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");
      datasets = [
        {
          label: `${"Posts Likes"}`,
          data:
            data.instagram &&
            data.instagram.datesSummary.map((item) => item.posts_likes),
          ...config2,
        },
        {
          label: `${"Posts Comments"}`,
          data:
            data.instagram &&
            data.instagram.datesSummary.map(
              (item) => item.posts_comments_count
            ),
          ...config_b,
        },
      ];
      break;
    }

    case "posts-impressions-reaches": {
      const sortedData = data.instagram
        ? data.instagram.datesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");
      datasets = [
        {
          label: `${"Posts Impressions"}`,
          data:
            data.instagram &&
            data.instagram.datesSummary.map((item) => item.impressions),
          ...config_b,
        },
        {
          label: `${"Reaches"}`,
          data:
            data.instagram &&
            data.instagram.datesSummary.map((item) => item.reach),
          ...config2,
        },
      ];
      break;
    }

    case "page-views-articles": {
      const sortedData = data.website
        ? data.website.datesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");
      datasets = [
        {
          label: `${"Page Views"}`,
          data:
            data.website &&
            data.website.datesSummary.map((item) => item.TotalViews),
          ...config_a,
        },
        {
          label: `${"Articles"}`,
          data:
            data.website &&
            data.website.datesSummary.map((item) => item.totalArticles),
          ...config2,
        },
      ];
      break;
    }

    case "time-on-site": {
      const sortedData = data.website
        ? data.website.datesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");
      datasets = [
        {
          label: `${"Total Time On Site (min)"}`,
          data:
            data.website &&
            data.website.datesSummary.map((item) =>
              Math.floor(item.totalTimeOnSite / 60)
            ),
          ...config3,
        },
      ];
      break;
    }

    case "page-impression-age-range": {
      labels = data.ageSummary.map((item) => item.ageRange || "0-10");
      datasets = [
        {
          label: `${"Impressions"}`,
          data: data.ageSummary.map((item) => item.impressions),
          ...config_a,
        },
      ];
      break;
    }

    case "visits-age-range": {
      labels = data.ageSummary.map((item) => item.ageRange || "0-10");
      datasets = [
        {
          label: `${"Visits"}`,
          data: data.ageSummary.map((item) => item.visits),
          ...config_a,
        },
      ];
      break;
    }

    case "web-technology-devices": {
      const brandSummary = data.mobileDeviceBrandSummary
        ? data.mobileDeviceBrandSummary.filter(
            (elt) => elt.totalVisits > 1000 && elt.views > 1000
          )
        : [];

      labels = brandSummary.map((item) => item.name || "phone");

      datasets = [
        {
          label: `${"Total Visits"}`,
          data: brandSummary.map((item) => item.totalVisits),
          ...config_a,
        },
        {
          label: `${"Views"}`,
          data: brandSummary.map((item) => item.views),
          ...config2,
        },
      ];
      break;
    }

    case "campaign-demographics": {
      labels =
        data.ageSummary &&
        data.ageSummary.map((item) => item.ageRange || "0-10");
      datasets = [
        {
          label: `${"Impressions"}`,
          data:
            data.ageSummary && data.ageSummary.map((item) => item.impressions),
          ...config_a,
        },
      ];
      break;
    }

    case "top-banners": {
      const sortedData = data.bannersDatesSummary
        ? data.bannersDatesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");

      datasets = [
        {
          label: `${"Impressions"}`,
          data: sortedData.map((item) => item.impressions),
          ...config_a,
        },
        {
          label: `${"Clicks"}`,
          data: sortedData.map((item) => item.clicks || 0),
          ...config2,
        },
      ];
      break;
    }

    case "campaign-advertorials": {
      const sortedData = data.datesSummary
        ? data.datesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");

      datasets = [
        {
          label: `${"Reads"}`,
          data: sortedData.map((item) => item.views),
          ...config_a,
        },
        {
          label: `${"Reading Time (min)"}`,
          data: sortedData.map((item) => Math.floor(item.timeOnSite / 60) || 0),
          ...config2,
        },
      ];
      break;
    }

    case "instagram-posts": {
      const sortedData = data.IGPostsDatesSummary
        ? data.IGPostsDatesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");

      datasets = [
        {
          label: `${"Impressions"}`,
          data: sortedData.map((item) => item.impressions),
          ...config_a,
        },
        {
          label: `${"Reach"}`,
          data: sortedData.map((item) => item.reaches),
          ...config2,
        },
        {
          label: `${"Engagement"}`,
          data: sortedData.map((item) => item.engagements),
          ...config3,
        },
      ];
      break;
    }

    case "facebook-posts": {
      const sortedData = data.FBPostsDatesSummary
        ? data.FBPostsDatesSummary.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
        : [];

      labels = sortedData.map((item) => item.date || "2021-01-01");

      datasets = [
        {
          label: `${"Impressions"}`,
          data: sortedData.map((item) => item.impressions),
          ...config_a,
        },
        {
          label: `${"Reach"}`,
          data: sortedData.map((item) => item.reaches),
          ...config2,
        },
        {
          label: `${"Engagement"}`,
          data: sortedData.map((item) => item.engagements),
          ...config3,
        },
      ];
      break;
    }

    default: {
      labels =
        data.facebook &&
        data.facebook.datesSummary.map((item) => item.date || "2021-01-01");
      datasets = [
        {
          label: `${"Page Impressions"}`,
          data:
            data.facebook &&
            data.facebook.datesSummary.map((item) => item.page_impressions),
          ...config_a,
        },
        {
          label: `${"Total Views"}`,
          data:
            data.facebook &&
            data.facebook.datesSummary.map(
              (item) => parseInt(item.page_views_total) * 100
            ),
          ...config2,
        },
      ];
      break;
    }
  }

  return {
    labels,
    datasets,
  };
};

export const smallTableDataFormat = (data, dataFor, lengthOfArr) => {
  const countryImgGenerator = (countryName, countryCode) => {
    return (
      // to get the countries flag: https://countryflagsapi.com/png
      <img
        src={`https://countryflagsapi.com/png/${countryCode || "rw"}`}
        alt={countryName}
        width={45}
        height={25}
      />
    );
  };
  let tableData = [];
  let topMenu = [];

  switch (dataFor) {
    case "page-impression": {
      topMenu = ["Flag", "Country", "Impressions"];
      tableData =
        lengthOfArr || lengthOfArr > 0
          ? data.results &&
            data.results.countriesSummary
              .sort((a, b) => b.impressions - a.impressions)
              .slice(0, lengthOfArr || 5)
              .map((country) => [
                countryImgGenerator(country.name, country.code),
                country.name,
                country.impressions || 0,
              ])
          : data.results &&
            data.results.countriesSummary.map((country) => {
              return {
                code: country.code || country.countryCode,
                value: country.impressions || 0,
              };
            });

      break;
    }
    case "page-views-articles": {
      topMenu = ["Flag", "Country", "Page Views"];
      tableData =
        lengthOfArr || lengthOfArr > 0
          ? data.results &&
            data.results.countriesSummary
              .sort((a, b) => b.pageViews - a.pageViews)
              .slice(0, lengthOfArr || 5)
              .map((country) => [
                countryImgGenerator(country.name, country.code),
                country.name,
                country.pageViews || 0,
              ])
          : data.results &&
            data.results.countriesSummary.map((country) => ({
              code: country.code || country.countryCode,
              value: country.pageViews || 0,
            }));
      break;
    }
    case "time-on-site": {
      topMenu = ["Flag", "Country", "Time On Site"];
      tableData =
        lengthOfArr || lengthOfArr > 0
          ? data.results &&
            data.results.countriesSummary
              .sort((a, b) => b.timeOnSite - a.timeOnSite)
              .slice(0, lengthOfArr || 5)
              .map((country) => [
                countryImgGenerator(country.name, country.code),
                country.name,
                Math.floor(country.timeOnSite / 60) || 0,
              ])
          : data.results &&
            data.results.countriesSummary.map((country) => ({
              code: country.code || country.countryCode,
              value: Math.floor(country.timeOnSite / 60) || 0,
            }));
      break;
    }

    case "posts-impressions": {
      topMenu = ["Flag", "Country", "Time On Site"];
      tableData =
        lengthOfArr || lengthOfArr > 0
          ? data.results &&
            data.results.countriesSummary
              .sort((a, b) => b.timeOnSite - a.timeOnSite)
              .slice(0, lengthOfArr || 5)
              .map((country) => [
                countryImgGenerator(country.name, country.code),
                country.name,
                country.timeOnSite || 0,
              ])
          : data.results &&
            data.results.countriesSummary.map((country) => ({
              code: country.code || country.countryCode,
              value: country.timeOnSite || 0,
            }));
      break;
    }
    case "top-banners": {
      topMenu = ["Banner", "Location", "Impressions"];

      tableData =
        data.bannersSummary &&
        data.bannersSummary
          .sort((a, b) => b.impressions - a.impressions)
          .map((banner, index) => [
            <img src={banner.bannerUrl} alt="Banner" width="150" />,
            banner.location || `Banner-${index + 1}`,
            banner.impressions || 0,
          ]);

      break;
    }

    default: {
      topMenu = ["Flag", "Country", "Impressions"];
      tableData =
        lengthOfArr || lengthOfArr > 0
          ? data.results &&
            data.results.countriesSummary
              .sort((a, b) => b.impressions - a.impressions)
              .slice(0, lengthOfArr || 5)
              .map((country) => [
                countryImgGenerator(country.name, country.code),
                country.name,
                country.impressions || 0,
              ])
          : data.results &&
            data.results.countriesSummary.map((country) => ({
              code: country.code || country.countryCode,
              value: country.impressions || 0,
            }));
      break;
    }
  }

  return lengthOfArr || lengthOfArr > 0 ? [topMenu, ...tableData] : tableData;
};

export const tableRowsDataFormat = (data, dataFor) => {
  let tableData = [];

  switch (dataFor) {
    case "campaign-advertorials": {
      tableData = data.advertsSummary
        ? data.advertsSummary.map((elt) =>
            createData(
              elt.id, //id
              {
                content1: (
                  <Avatar
                    alt={elt.title || "Img"}
                    src={elt.wallPhoto}
                    variant="rounded"
                  />
                ),
                content2: elt.title,
              },
              elt.timeOnSite ? Math.floor(elt.timeOnSite / 60) : 0, //timeOnSite
              // elt.totalReaches || 0, //reach
              elt.views, //views
              moment(elt.postDate).format("YYYY-MM-DD")
            )
          )
        : [];
      break;
    }

    case "instagram-posts": {
      tableData = data.IGPostsSummary
        ? data.IGPostsSummary.map((elt) =>
            createData(
              elt.id, //id
              {
                content1: (
                  <Avatar
                    alt={elt.caption || "Img"}
                    src={
                      "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
                    }
                    variant="rounded"
                  />
                ),
                content2: elt.caption,
              },
              elt.impressions, //impressions
              elt.reaches || elt.totalReaches || 0, //reach
              elt.engagements || elt.totalEngagements, //Engagements
              elt.postDate ? moment(elt.postDate).format("YYYY-MM-DD") : "Unset"
            )
          )
        : [];
      break;
    }

    case "facebook-posts": {
      tableData = data.FBPostsSummary
        ? data.FBPostsSummary.map((elt) =>
            createData(
              elt.id, //id
              {
                content1: (
                  <Avatar
                    alt={elt.message || "Img"}
                    src={
                      "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook2_colored_svg-1024.png"
                    }
                    variant="rounded"
                  />
                ),
                content2: elt.message,
              },
              elt.impressions, //impressions
              elt.reaches || 0, //reach
              elt.engagements, //views
              elt.postDate ? moment(elt.postDate).format("YYYY-MM-DD") : "Unset"
            )
          )
        : [];
      break;
    }

    default: {
      tableData = data.IGPostsSummary
        ? data.IGPostsSummary.map((elt) =>
            createData(
              elt.id, //id
              {
                content1: (
                  <Avatar
                    alt={elt.caption || "Img"}
                    src={
                      "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
                    }
                    variant="rounded"
                  />
                ),
                content2: elt.caption,
              },
              elt.engagements, //views
              elt.timeOnSite ? Math.floor(elt.timeOnSite / 60) : 0, //timeOnSite
              elt.reaches, //reaches
              moment(elt.postDate).format("YYYY-MM-DD")
            )
          )
        : [];
      break;
    }
  }

  return tableData;
};

const makeSum = (dataArr, prop) => {
  let sum = 0;
  dataArr.forEach((el) => {
    sum += el[prop];
  });
  return sum;
};

export const formatConcertChartData = (data, dataFor, activeCurrency) => {
  let labels = [],
    datasets = [];

  const config = {
    backgroundColor: "#22CECE",
    borderWidth: 1,
  };

  const config2 = {
    backgroundColor: "#FF3D67",
    borderWidth: 1,
  };

  const config3 = {
    backgroundColor: "#36A2EB",
    borderWidth: 1,
  };
  const buckets =[];
  for(let currency of data.TierSummary){
    if(currency.currencyBuckets){
      for(let revenue of currency.currencyBuckets){
        if(revenue.name === "RWF"){
          buckets.push(revenue.name)
        }
        
      }
    }
  }

  switch (dataFor) {
    case "tier name": {
      labels = data.TierSummary
        ? data.TierSummary.map(
            (item) => `${item.name}`
          )
        : [];
      datasets = [
        {
          label: `${"tickets"}`,
          data: data.TierSummary
            ? data.TierSummary.map((item) => item.totalTicketsSold)
            : [],
          ...config,
        },
      ];
      break;
    }

    case "tier nameRevenue": {
      labels = data.TierSummary
        ? data.TierSummary.map(item => item.name)
        : [];
      datasets = [
        {
          label: `${"Revenues RWF"}`,
          data: data.TierSummary
            ? data.TierSummary.map((item) =>{
              const rev = makeSum(
                item.currencyBuckets?.filter(el => el.currency === "RWF"), "totalTicketSales"
              );
              return rev
            } )
            : [],
          ...config3,
        },
        {
          label: `${"Revenues USD"}`,
          data: data.TierSummary
            ? data.TierSummary.map((item) => {
                const rev = makeSum(
                  item.currencyBuckets?.filter((el) => el.currency === "USD"),
                  "totalTicketSales"
                );
                return rev;
              })
            : [],
          ...config2,
        },
        {
          label: `${"Revenues EUR"}`,
          data: data.TierSummary
            ? data.TierSummary.map((item) => {
                const rev = makeSum(
                  item.currencyBuckets?.filter((el) => el.currency === "EUR"),
                  "totalTicketSales"
                );
                return rev;
              })
            : [],
          ...config,
        },
      ];

      break;
    }
    case "status":{
      labels = data.statusSummary ? data.statusSummary.map(item => `${item.name}`): [];
      datasets = [
        {
          label: `${"tickets"}`,
          data: data.statusSummary
            ? data.statusSummary.map((item) => item.totalTicketsSold)
            : [],
          ...config,
        },
      ];
      break;
    }
    case "sources":{
       labels = data.sourceSummary
        ? data.sourceSummary.map((item) => `${item.name}`)
        : [];
      datasets = [
        {
          label: `${"Tickets"}`,
          data: data.sourceSummary
            ? data.sourceSummary.map((item) => item.totalTicketsSold)
            : [],
          ...config,
        },
      ];
      break;
    }

    case "vendors": {
      labels = data.vendorSummary
        ? data.vendorSummary.map((item) => item.name)
        : [];
      datasets = [
        {
          label: `${"Tickets"}`,
          data: data.vendorSummary
            ? data.vendorSummary.map((item) => item.totalTicketsSold)
            : [],
          ...config,
        },
      ];
      break;
    }

    case "vendorsRevenue": {
      labels = data.vendorSummary
        ? data.vendorSummary.map((item) => item.name)
        : [];
      datasets = [
        {
          label: `${"Revenues RWF"}`,
          data: data.vendorSummary
            ? data.vendorSummary.map((item) =>{
              const rev = makeSum(
                item.currencyBuckets?.filter(el => el.currency === "RWF"), "totalTicketSales"
              );
              return rev
            } )
            : [],
          ...config3,
        },
        {
          label: `${"Revenues USD"}`,
          data: data.vendorSummary
            ? data.vendorSummary.map((item) =>{
              const rev = makeSum(
                item.currencyBuckets?.filter(el => el.currency === "USD"), "totalTicketSales"
              );
              return rev
            } )
            : [],
          ...config2,
        },
        {
          label: `${"Revenues EUR"}`,
          data: data.vendorSummary
            ? data.vendorSummary.map((item) =>{
              const rev = makeSum(
                item.currencyBuckets?.filter(el => el.currency === "EUR"), "totalTicketSales"
              );
              return rev
            } )
            : [],
          ...config,
        },
      ];

      break;
    }

    case "dates": {
      labels = data.dailySummary
        ? data.dailySummary.map((item) => item.name || "2021-01-01")
        : [];
      datasets = data.dailySummary
        ? [
            {
              label: `${"tickets"}`,
              data: data.dailySummary.map((item) => item.totalTicketsSold),
              ...config,
            },
          ]
        : [];
      break;
    }

    case "datesRevenue": {
      labels = data.dailySummary
        ? data.dailySummary.map((item) => item.name || "2021-01-01")
        : [];

      const rwfAmount = data.dailySummary
        ? data.dailySummary.map((item) =>{
          const rev = makeSum(
            item.currencyBuckets?.filter((elt) => elt.currency === "RWF"),
            "totalTicketSales"
            )
            return rev;
          }
            )
        : [];
      const usdAmount = data.dailySummary
        ? data.dailySummary.map((item) =>
            makeSum(
              item.currencyBuckets.filter((elt) => elt.currency === "USD"),
              "totalTicketSales"
            )
          )
        : [];
      const euroAmount = data.dailySummary
        ? data.dailySummary.map((item) =>
            makeSum(
              item.currencyBuckets.filter((elt) => elt.currency === "EUR"),
              "totalTicketSales"
            )
          )
        : [];

      datasets = [
        {
          label: `${"Revenues RWF"}`,
          data: rwfAmount,
          ...config3,
        },
        {
          label: `${"Revenues USD"}`,
          data: usdAmount,
          ...config2,
        },
        {
          label: `${"Revenues EUR"}`,
          data: euroAmount,
          ...config,
        },
      ];

      break;
    }

    case "buckets": {
      labels = data.bucketSummary
        ? data.bucketSummary.map((item) => item.name)
        : [];
      datasets = [
        {
          label: `Bucket Tickets`,
          data: data.bucketSummary
            ? data.bucketSummary.map((item) => item.totalTicketsSold)
            : [],
          ...config,
        },
      ];
      break;
    }

    case "bucketsRevenue": {
      labels = data.bucketSummary
        ? data.bucketSummary.map((item) => item.name)
        : [];
      datasets = [
        {
          label: `${"Revenues RWF"}`,
          data: data.bucketSummary
            ? data.bucketSummary.map((item) =>
                item.currency === "RWF" ? item.totalTicketSales : 0
              )
            : [],
          ...config3,
        },
        {
          label: `${"Revenues USD"}`,
          data: data.bucketSummary
            ? data.bucketSummary.map((item) =>
                item.currency === "USD" ? item.totalTicketSales : 0
              )
            : [],
          ...config2,
        },
        {
          label: `${"Revenues EUR"}`,
          data: data.bucketSummary
            ? data.bucketSummary.map((item) =>
                item.currency === "EUR" ? item.totalTicketSales : 0
              )
            : [],
          ...config,
        },
      ];

      break;
    }

    case "order size": {
      labels = data.orderSizeSummary
        ? data.orderSizeSummary.map((item) => `${item.name} orders`)
        : [];
      datasets = [
        {
          label: `Occurence`,
          data: [],
          data: data.orderSizeSummary
            ? data.orderSizeSummary.map((item) => item.occurrence)
            : [],
          ...config,
        },
      ];
      break;
    }

    case "order sizeRevenue": {
      labels = data.orderSizeSummary
        ? data.orderSizeSummary.map((item) => `${item.name} orders`)
        : [];
      datasets = [
        {
          label: `Revenues RWF`,
          data: [],
          data:data.orderSizeSummary
        ? data.orderSizeSummary.map((item) =>
            makeSum(
              item.currencyBuckets.filter((elt) => elt.currency === "RWF"),
              "totalTicketSales"
            )
          )
        : [],
          ...config,
        },
        {
          label: `Revenues USD`,
          data: [],
          data:data.orderSizeSummary
        ? data.orderSizeSummary.map((item) =>
            makeSum(
              item.currencyBuckets.filter((elt) => elt.currency === "USD"),
              "totalTicketSales"
            )
          )
        : [],
          ...config,
        },
        {
          label: `Revenues EUR`,
          data: [],
          data:data.orderSizeSummary
        ? data.orderSizeSummary.map((item) =>
            makeSum(
              item.currencyBuckets.filter((elt) => elt.currency === "EUR"),
              "totalTicketSales"
            )
          )
        : [],
          ...config,
        },
      ];
      break;
    }

    default: {
      labels = data.candidatesSummary
        ? data.candidatesSummary.map(
            (item) => `${item.FirstName} ${item.LastName}`
          )
        : [];
      datasets = [
        {
          label: `${"Votes"}`,
          data: data.candidatesSummary
            ? data.candidatesSummary.map((item) => item.Votes)
            : [],
          ...config,
        },
      ];
      break;
    }
  }

  return {
    labels,
    datasets,
  };
};
