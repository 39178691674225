import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import reduxAction, { fullUrlAction } from "../../../../redux/action";
import Step3 from "../../../components/ads/createAd/steps/Step3";
import Loading from "../../../../components/isLoading";
import Toast from "../../../../components/MessageToast";
import apiService from "../../../../services/apiService";
import {
  FETCH_PAYMENT_PLANS,
  OTP_VERIFICATION,
  PAYMENT_VERIFICATION,
  PAYMENT_CONFIRM,
} from "../../../../redux/payment/types";
import PaymentModal from "../../../components/payment/PaymentModal";

export default ({ advertData = {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const allPaymentPlansStore = useSelector((store) => store.allPaymentPlans);
  const paymentConfirmation = useSelector((store) => store.paymentConfirmation);
  const paymentVerification = useSelector((store) => store.paymentVerification);

  const [state, setState] = useState({
    selectedCampaign: {},
    loading: false,
    openToast: false,
    openModel: false,
    selectedPlan: {},
  });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: "/promotions/plans",
        actionType: FETCH_PAYMENT_PLANS,
      })
    );
  }, []);

  const handlePayment = (data) => {
    /* 
     Method to confirm the payment
     This is called upon adding phone details
    */
    const { amount, phone, currency } = data;
    const payload = {
      clientId: advertData.clientId,
      campaignId: advertData.campaignId,
      adId: advertData.adId || advertData.id,
      adType: advertData.adType,
    };
    const paymentData = {
      type: "ads",
      amount: amount,
      phone_number: phone,
      currency,
      id: advertData.id || advertData.adId,
      payload,
    };

    dispatch(
      fullUrlAction({
        method: "POST",
        path: `${process.env.PAYMENT_API}/transactions`,
        actionType: PAYMENT_CONFIRM,
        data: paymentData,
      })
    );
  };

  const handleOTPSubmission = (otp) => {
    if (
      paymentConfirmation.payment_data.results &&
      paymentConfirmation.payment_data.results.charge.redirect
    ) {
      const otpData = {
        otp,
        redirect: paymentConfirmation.payment_data.results.charge.redirect,
      };

      dispatch(
        fullUrlAction({
          method: "POST",
          path: `${process.env.PAYMENT_API}/transactions/${paymentConfirmation.payment_data.results.transaction.tx_ref}/otp`,
          actionType: OTP_VERIFICATION,
          data: otpData,
        })
      );
    }
  };

  const checkPaymentIfSuccessful = () => {
    if (
      paymentConfirmation.payment_data.results &&
      paymentConfirmation.payment_data.results.transaction.tx_ref
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `https://us-central1-nonehoidentity.cloudfunctions.net/PaymentAPI/api/transactions/${paymentConfirmation.payment_data.results.transaction.tx_ref}`,
          actionType: PAYMENT_VERIFICATION,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setState({ ...state, openModel: false });
  };

  const openToast = ({ message, type, onClose }) =>
    Toast({ message, type, duration: 4000, onClose });

  const choosePaymentPlan = async (data) => {
    setState({ ...state, selectedPlan: data, openModel: true });
  };

  return allPaymentPlansStore.loading ? (
    <Loading />
  ) : (
    <div>
      <PaymentModal
        isOpen={state.openModel}
        handleClose={handleCloseModal}
        content={advertData}
        onPaymentConfirm={handlePayment}
        paymentConfirmationStore={paymentConfirmation}
        onOtpSubmit={handleOTPSubmission}
        paymentVerification={paymentVerification}
        checkPaymentIfSuccessful={checkPaymentIfSuccessful}
        paymentOptions={state.selectedPlan}
      />
      <Step3
        onPaymentPlanChoosing={choosePaymentPlan}
        loading={state.loading}
        paymentPlans={allPaymentPlansStore.data.results || []}
      />
    </div>
  );
};
