import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import reduxAction from "../../../../redux/action";
import Step2 from "../../../components/ads/createAd/steps/Step2";
import { FETCH_SINGLE_CAMPAIGN_INFO } from "../../../../redux/campaign/types";
import Loading from "../../../../components/isLoading";
import { uploadCampaignImg } from "../../../../redux/upload";
import {
  ADVERTORIAL_UPLOADING,
  BANNER_UPLOADING,
  POST_IMG_UPLOADING,
} from "../../../../redux/upload/types";
import {
  compareAdvertorials,
  compareBanners,
  folderDate,
} from "../../../../helpers/helperMethods";
import Toast from "../../../../components/MessageToast";
import apiService from "../../../../services/apiService";

export default ({ selectedCampaign, userInfo, goToNextStep }) => {
  const dispatch = useDispatch();
  const singleCampaignStore = useSelector((store) => store.singleCampaign);
  const uploadCampaignImgStore = useSelector(
    (store) => store.uploadCampaignImg
  );
  const [state, setState] = useState({
    selectedCampaign: {},
    loading: false,
    openToast: false,
  });
  const [bannerUpload, setBannerUpload] = useState({
    fullUrl: null,
    loading: false,
  });
  const [advertUpload, setAdvertUpload] = useState({
    fullUrl: null,
    loading: false,
  });
  const [postImgUpload, setPostImgUpload] = useState({
    fullUrl: null,
    loading: false,
  });

  useEffect(() => {
    if (
      !singleCampaignStore.data.results ||
      (singleCampaignStore.data.results &&
        singleCampaignStore.data.id !== selectedCampaign.id)
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${userInfo.organization.orgId}/campaigns/${selectedCampaign.id}?flag=edit`,
          actionType: FETCH_SINGLE_CAMPAIGN_INFO,
        })
      );
    }
  }, [selectedCampaign]);

  const openToast = ({ message, type, onClose }) =>
    Toast({ message, type, duration: 2000, onClose });

  const onBannerUpload = async (banner) => {
    setBannerUpload({ ...bannerUpload, loading: true });

    const destination = `public/images/${selectedCampaign.name
      .replace(/\s/g, "")
      .substring(0, 6)}/banners/${folderDate}`;

    dispatch(
      uploadCampaignImg({ action: BANNER_UPLOADING, destination, data: banner })
    );

    setState({ ...state, openToast: true });
  };

  const onAdvertPhotoUpload = async (advert) => {
    const destination = `public_html/app/webroot/img/${folderDate}/images`;
    dispatch(
      uploadCampaignImg({
        action: ADVERTORIAL_UPLOADING,
        destination,
        data: advert.file,
        outside: true,
      })
    );

    setState({ ...state, openToast: true });
  };

  const onPostImgUpload = async (postImg) => {
    const destination = `public_html/app/webroot/img/${folderDate}/images`;
    dispatch(
      uploadCampaignImg({
        action: POST_IMG_UPLOADING,
        destination,
        data: postImg.file,
        outside: true,
      })
    );

    setState({ ...state, openToast: true });
  };

  const handleCreateAd = async (data) => {
    const reqData = {
      adType: data.category || data.adType,
      content: { ...data },
      plan: {},
    };

    const response = await apiService({
      path: `/clients/${userInfo.organization.orgId}/campaigns/${selectedCampaign.id}/ads`,
      method: "POST",
      data: reqData,
    });
    openToast({
      message: response.message,
      type: response.error ? "error" : "success",
      onClose: () => {
        setState({ ...state, loading: false });
        if (!response.error) {
          goToNextStep(response.results);
        }
      },
    });
  };

  const handleSave = async (data, usingExistingData) => {
    if (usingExistingData) {
      const findUsedBanner = singleCampaignStore.data.results.adBanners.find(
        (elt) => elt.id === data.id
      );
      const findUsedAdvert = singleCampaignStore.data.results.advertorials.find(
        (elt) => elt.id === data.id
      );

      let finalizedData;

      if (findUsedBanner) {
        finalizedData =
          compareBanners(data, findUsedBanner) && !bannerUpload.fullUrl
            ? { category: data.category, refId: data.id }
            : { ...data, url: bannerUpload.fullUrl || data.url };
        delete finalizedData.path;
        delete finalizedData.file;
      } else if (findUsedAdvert) {
        finalizedData =
          compareAdvertorials(data, findUsedAdvert) && !advertUpload.advertorial
            ? { category: data.category, refId: data.id }
            : {
                ...data,
                wallPhoto:
                  advertUpload.advertorial?.path || data.wallPhoto || "",
                photoUrl:
                  advertUpload.advertorial?.fullUrl || data.photoUrl || "",
              };
      }
      delete finalizedData.action;

      setState({ ...state, advertContent: finalizedData });
      return handleCreateAd(finalizedData);
    }

    setState({ ...state, advertContent: data, loading: true });
    let nextStepData = {};
    switch (data.action) {
      case "createBanner":
        if (!bannerUpload.fullUrl) {
          return openToast({
            message: "Please upload banner Image first!",
            type: "error",
            onClose: setState({ ...state, loading: false }),
          });
        }
        nextStepData = {
          category: data.category,
          url: bannerUpload.fullUrl ? bannerUpload.fullUrl : data.url,
          caption: data.caption,
          link: data.link,
        };
        break;

      case "createAdvertorial":
        if (!advertUpload.advertorial) {
          openToast({
            message: "Please upload advert Image first!",
            type: "error",
          });
        }
        const fullUrl = advertUpload.advertorial
          ? advertUpload.advertorial.fullUrl
          : data.photoUrl;
        const path = advertUpload.advertorial
          ? advertUpload.advertorial.path
          : data.path;

        nextStepData = { ...data, wallPhoto: path, photoUrl: fullUrl };
        break;

      case "createSocialMediaPost":
        if (!postImgUpload.postImg) {
          openToast({
            message: "Please upload post Image first!",
            type: "error",
          });
        }
        const postFullUrl = postImgUpload.postImg
          ? postImgUpload.postImg.fullUrl
          : data.photoUrl;
        const postPath = postImgUpload.postImg
          ? postImgUpload.postImg.path
          : data.path;

        nextStepData = {
          ...data,
          adType: "socialMediaPost",
          platform: data.platform,
          tags: data.tags.map((elt) => elt.value),
          wallPhoto: postPath,
          photoUrl: postFullUrl,
        };
        delete nextStepData.category;

        break;
      default:
        setState({ ...state, loading: false });
        break;
    }
    delete nextStepData.action;

    handleCreateAd(nextStepData);
  };

  if (state.openToast) {
    const {
      banner = {},
      advertorial = {},
      postImg = {},
    } = uploadCampaignImgStore.data;

    if (
      (banner && banner.fullUrl) ||
      (advertorial && advertorial.fullUrl) ||
      (postImg && postImg.fullUrl)
    ) {
      openToast({
        message: uploadCampaignImgStore.error
          ? "Unable to upload Image"
          : "Image Uploaded Successfully",
        type: uploadCampaignImgStore.error ? "error" : "success",
        onClose: () => {
          setState({ ...state, openToast: false });
          setBannerUpload({ ...bannerUpload, ...banner });
          setAdvertUpload({ ...advertUpload, advertorial });
          setPostImgUpload({ ...postImgUpload, postImg });
        },
      });
    }
  }

  return singleCampaignStore.loading ? (
    <Loading />
  ) : (
    <div>
      <Step2
        handleSave={handleSave}
        loading={state.loading}
        content={singleCampaignStore.data.results || {}}
        onBannerUpload={onBannerUpload}
        onAdvertPhotoUpload={onAdvertPhotoUpload}
        onPostImgUpload={onPostImgUpload}
      />
    </div>
  );
};
