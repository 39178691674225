import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditCampaignComponent from "../../components/campaigns/editCampaignComponent";
import reduxAction from "../../../redux/action";
import { FETCH_SINGLE_CAMPAIGN_INFO } from "../../../redux/campaign/types";
import {
  ADVERTORIAL_UPLOADING,
  BANNER_UPLOADING,
} from "../../../redux/upload/types";
import apiService from "../../../services/apiService";
import Toastify from "../../../components/MessageToast";
import Loading from "../../../components/isLoading";
import { folderDate } from "../../../helpers/helperMethods";
import { uploadCampaignImg } from "../../../redux/upload";
import NoContent from "../../../components/NoContent";
import { FETCH_ADSBANNERS_SECTIONS } from "../../../redux/ads/types";

export default ({ campaignId }) => {
  const dispatch = useDispatch();
  const campaign = useSelector((store) => store.singleCampaign);
  const bannersSections = useSelector((store) => store.bannersSections);
  const uploadCampaignImgStore = useSelector(
    (store) => store.uploadCampaignImg
  );

  const [campaignState, setCampaign] = useState(campaign);

  const [bannerUpload, setBannerUpload] = useState({
    fullUrl: null,
    loading: false,
    openToast: false,
  });
  const [advertUpload, setAdvertUpload] = useState({
    fullUrl: null,
    loading: false,
    openToast: false,
  });

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("au"));
    const { userOrg, userApp } = user.claims;
    setCurrentUser({
      uid: user.uid,
      email: user.email,
      userApp,
      userOrg,
    });
    // if (!campaign.data.results || campaignId !== campaign.data.results.id) {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/clients/${userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
        actionType: FETCH_SINGLE_CAMPAIGN_INFO,
      })
    );
    // }
    dispatch(
      reduxAction({
        method: "GET",
        path: `/promotions/sections`,
        actionType: FETCH_ADSBANNERS_SECTIONS,
      })
    );
  }, []);

  useEffect(() => {
    setCampaign({ ...campaignState, ...campaign.data });
  }, [campaign.data]);

  const onBasicInforChange = async (campaignInfo) => {
    const response = await apiService({
      path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}`,
      method: "PATCH",
      data: {
        ...campaignInfo,
        adBanners: undefined,
        socialMediaPosts: undefined,
        advertorials: undefined,
      },
    });
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        dispatch(
          reduxAction({
            method: "GET",
            path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
            actionType: FETCH_SINGLE_CAMPAIGN_INFO,
          })
        );
      },
    });
  };

  const onBannerUpload = async (banner) => {
    setBannerUpload({
      ...bannerUpload,
      loading: true,
    });

    const destination = `public/images/${campaign.data.results.name
      .replace(/\s/g, "")
      .substring(0, 6)}/banners/${folderDate}`;

    dispatch(
      uploadCampaignImg({ action: BANNER_UPLOADING, destination, data: banner })
    );

    setBannerUpload({
      ...bannerUpload,
      openToast: true,
    });
  };

  if (bannerUpload.openToast || advertUpload.openToast) {
    if (
      (uploadCampaignImgStore.data.banner &&
        uploadCampaignImgStore.data.banner.fullUrl) ||
      (uploadCampaignImgStore.data.advertorial &&
        uploadCampaignImgStore.data.advertorial.fullUrl)
    ) {
      Toastify({
        message: "Uploaded Successfully",
        type: uploadCampaignImgStore.error ? "error" : "success",
        duration: 3000,
        onClose: () => {
          setAdvertUpload({ ...advertUpload, openToast: false });
          setBannerUpload({
            ...bannerUpload,
            openToast: false,
          });
        },
      });
    }
  }

  const onAdvertPhotoUpload = async (advert) => {
    setAdvertUpload({
      ...advertUpload,
      loading: true,
    });

    const destination = `public_html/app/webroot/img/${folderDate}/images`;

    dispatch(
      uploadCampaignImg({
        action: ADVERTORIAL_UPLOADING,
        destination,
        data: advert.file,
        outside: true,
      })
    );

    setAdvertUpload({
      ...advertUpload,
      openToast: true,
    });
  };

  const onCreateOrEditBanner = async (action, banner, uploadedPhoto) => {
    if (!uploadedPhoto.banner && !banner.url) {
      Toastify({
        message: "Please upload image first!",
        type: "warning",
        duration: 4000,
      });
    } else {
      setBannerUpload({
        ...bannerUpload,
        loading: true,
      });
      if (action === "edit") {
        const data = {
          ...banner,
          url: uploadedPhoto.banner ? uploadedPhoto.banner.fullUrl : banner.url,
          file: undefined,
          path: undefined,
          action: undefined,
        };
        var response = await apiService({
          path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/banners/${banner.id}`,
          method: "PATCH",
          data,
        });
      } else {
        const data = {
          ...banner,
          url: uploadedPhoto.banner ? uploadedPhoto.banner.fullUrl : banner.url,
          file: undefined,
          path: undefined,
          action: undefined,
        };
        var response = await apiService({
          path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/banners`,
          method: "POST",
          data,
        });
      }
      if (!response.error)
        dispatch(
          reduxAction({
            method: "GET",
            path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
            actionType: FETCH_SINGLE_CAMPAIGN_INFO,
          })
        );
      Toastify({
        message: response.message,
        type: response.error ? "error" : "success",
        duration: 4000,
        onClose: () => {
          setBannerUpload({
            ...bannerUpload,
            loading: false,
          });
        },
      });
    }
  };

  const onCreateOrEditAdvert = async (advert, action, prev, uploadedPhoto) => {
    const fullUrl = uploadedPhoto.advertorial
      ? uploadedPhoto.advertorial.fullUrl
      : advert.photoUrl;
    const path = uploadedPhoto.advertorial
      ? uploadedPhoto.advertorial.path
      : advert.path;

    setAdvertUpload({
      loading: true,
    });
    if (action === "edit") {
      const data = {
        ...prev,
        ...advert,
        id: undefined,
        wallPhoto: path || prev.path,
        photoUrl: fullUrl || prev.photoUrl,
      };
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/adverts/${prev.id}`,
        method: "PATCH",
        data,
      });
    } else {
      const data = {
        ...advert,
        wallPhoto: path,
        photoUrl: fullUrl,
      };
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/adverts`,
        method: "POST",
        data,
      });
    }
    if (!response.error)
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
          actionType: FETCH_SINGLE_CAMPAIGN_INFO,
        })
      );
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setAdvertUpload({
          loading: true,
        });
      },
    });
  };

  const onAdvertPauseOrDelete = async (advert, action) => {
    if (action === "delete") {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/adverts/${advert.id}`,
        method: "DELETE",
      });
    } else {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/adverts/${advert.id}`,
        method: "PATCH",
        data: {
          status: "paused",
        },
      });
    }
    if (!response.error)
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
          actionType: FETCH_SINGLE_CAMPAIGN_INFO,
        })
      );
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setAdvertUpload({
          loading: true,
        });
      },
    });
  };

  const onConfirmBannerAction = async (banner, action) => {
    if (action === "delete") {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/banners/${banner.id}`,
        method: "DELETE",
      });
    }
    if (action === "promote") {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/banners/${banner.id}`,
        method: "PATCH",
        data: {
          status: "promoted",
        },
      });
    }
    if (action === "pause") {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/banners/${banner.id}`,
        method: "PATCH",
        data: {
          status: "paused",
        },
      });
    }
    if (!response.error)
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
          actionType: FETCH_SINGLE_CAMPAIGN_INFO,
        })
      );

    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setBannerUpload({
          loading: true,
        });
      },
    });
  };

  const onPromote = async (data, isFor) => {
    if (isFor === "banner") {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/banners/${data.id}`,
        method: "PATCH",
        data: {
          promotions: {
            ...data.promotions,
            pending: {
              platforms: data.platforms,
              status: "promoted",
            },
          },
          updatedAt: new Date(),
          status: "promoting",
        },
      });
    }
    if (isFor === "advert") {
      var response = await apiService({
        path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}/adverts/${data.id}`,
        method: "PATCH",
        data: {
          promotions: {
            ...data.promotions,
            pending: {
              platforms: data.platforms,
              status: "promoted",
            },
          },
          updatedAt: new Date(),
          status: "promoting",
        },
      });
    }
    if (!response.error)
      dispatch(
        reduxAction({
          method: "GET",
          path: `/clients/${currentUser.userOrg.orgId}/campaigns/${campaignId}?flag=edit`,
          actionType: FETCH_SINGLE_CAMPAIGN_INFO,
        })
      );
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setAdvertUpload({
          loading: true,
        });
      },
    });
  };

  return campaign.loading ? (
    <Loading />
  ) : (
    <div>
      {campaignState.results ? (
        <EditCampaignComponent
          campaign={campaignState.results || {}}
          campaignId={campaignId}
          onBasicInforChange={onBasicInforChange}
          onBannerUpload={onBannerUpload}
          bannerUploadState={bannerUpload}
          onCreateOrEditBanner={onCreateOrEditBanner}
          onAdvertPhotoUpload={onAdvertPhotoUpload}
          advertUploadState={advertUpload}
          onCreateOrEditAdvert={onCreateOrEditAdvert}
          onAdvertPauseOrDelete={onAdvertPauseOrDelete}
          onConfirmBannerAction={onConfirmBannerAction}
          onPromote={onPromote}
          bannersSections={bannersSections.data.results}
        />
      ) : (
        <NoContent
          title={
            campaign.data.error && campaign.data.message.includes("connection")
              ? campaign.data.message
              : "Can Not find Campaign"
          }
        />
      )}
    </div>
  );
};
