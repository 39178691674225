import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../assets/styles/base';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ImagePlaceholder from '../../../assets/images/placeholder.png';
import ImageUpload from '../../../components/CustomUpload/ImageUpload';

const userStyles = makeStyles((theme) => ({
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: 'capitalize',
    float: 'right',
    margin: '10px 0',
    '&:hover': {
      backgroundColor: primaryColor[0],
    },
  },
  bannerImage: {
    minWidth: 300,
    minHeight: 300,
    maxHeight: 600,
    maxWidth: 600,
    objectFit: 'cover',
    objectPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: 250,
      minHeight: 250,
      maxHeight: 250,
      maxWidth: 250,
    },
  },
}));

export default ({
  eventCover,
  onBannerUpload,
  bannerUploadState,
}) => {
  const classes = userStyles();

  const [state, setState] = useState({
    showSecondComponent: false,
    showBackForward: false,
  });

  const handleForwardArrow = () =>
    setState({
      ...state,
      showSecondComponent: true,
    });
  const handleBackArrow = () =>
    setState({
      ...state,
      showSecondComponent: false,
    });

  return (
    <div>
      <Grid container justify='space-between' direction='row'>
        <div>
          {state.showBackForward && (
            <>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleBackArrow}
                className={classes.menuButton}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleForwardArrow}
                className={classes.menuButton}
              >
                <ChevronRightIcon />
              </IconButton>
            </>
          )}
        </div>
      </Grid>
      <Grid container direction='column' className={classes.root}>
        <Typography variant='h6'>Event cover photo</Typography>
        <br />
        <Box>
          <ImageUpload
            hasMoreInfo
            defaultImage={eventCover || ImagePlaceholder}
            imageSubmit={onBannerUpload}
            imageTypes={
              'image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico'
            }
            addButtonProps={{
              color: 'primary',
            }}
            changeButtonProps={{
              color: 'primary',
            }}
            removeButtonProps={{
              color: 'danger',
            }}
            isUploading={bannerUploadState.loading}
          />
        </Box>

        <Typography color='textSecondary'>
          {bannerUploadState.error ? bannerUploadState.error : ''}
        </Typography>
      </Grid>
    </div>
  );
};
